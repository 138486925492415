/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Buffer } from 'buffer/index.js';
import * as runtime from '../runtime';
import type {
  AddToTopicCommand,
  CreateTopicCommand,
  DeleteTopicCommand,
  RemoveFromTopicCommand,
  TopicViewModel,
  UpdateEntityTopicsCommand,
  UpdateTopicCommand,
} from '../models';
import {
  AddToTopicCommandFromJSON,
  AddToTopicCommandToJSON,
  CreateTopicCommandFromJSON,
  CreateTopicCommandToJSON,
  DeleteTopicCommandFromJSON,
  DeleteTopicCommandToJSON,
  RemoveFromTopicCommandFromJSON,
  RemoveFromTopicCommandToJSON,
  TopicViewModelFromJSON,
  TopicViewModelToJSON,
  UpdateEntityTopicsCommandFromJSON,
  UpdateEntityTopicsCommandToJSON,
  UpdateTopicCommandFromJSON,
  UpdateTopicCommandToJSON,
} from '../models';

export interface AddToTopicRequest {
  organizationId: string;
  addToTopicCommand: AddToTopicCommand;
}

export interface CreateTopicRequest {
  organizationId: string;
  createTopicCommand: CreateTopicCommand;
}

export interface DeleteTopicRequest {
  organizationId: string;
  deleteTopicCommand: DeleteTopicCommand;
}

export interface FetchTopicRequest {
  topicId: string;
  organizationId: string;
}

export interface ListTopicsRequest {
  organizationId: string;
}

export interface RemoveFromTopicRequest {
  organizationId: string;
  removeFromTopicCommand: RemoveFromTopicCommand;
}

export interface UpdateEntityTopicsRequest {
  organizationId: string;
  updateEntityTopicsCommand: UpdateEntityTopicsCommand;
}

export interface UpdateTopicRequest {
  organizationId: string;
  updateTopicCommand: UpdateTopicCommand;
}

/**
 *
 */
export class TopicsApi extends runtime.BaseAPI {
  /**
   */
  async addToTopicRaw(
    requestParameters: AddToTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling addToTopic.',
      );
    }

    if (requestParameters.addToTopicCommand === null || requestParameters.addToTopicCommand === undefined) {
      throw new runtime.RequiredError(
        'addToTopicCommand',
        'Required parameter requestParameters.addToTopicCommand was null or undefined when calling addToTopic.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/topics/:add-to-topic`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AddToTopicCommandToJSON(requestParameters.addToTopicCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async addToTopic(
    requestParameters: AddToTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.addToTopicRaw(requestParameters, initOverrides);
  }

  /**
   */
  async createTopicRaw(
    requestParameters: CreateTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createTopic.',
      );
    }

    if (requestParameters.createTopicCommand === null || requestParameters.createTopicCommand === undefined) {
      throw new runtime.RequiredError(
        'createTopicCommand',
        'Required parameter requestParameters.createTopicCommand was null or undefined when calling createTopic.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/topics/:create-topic`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateTopicCommandToJSON(requestParameters.createTopicCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async createTopic(
    requestParameters: CreateTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createTopicRaw(requestParameters, initOverrides);
  }

  /**
   */
  async deleteTopicRaw(
    requestParameters: DeleteTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteTopic.',
      );
    }

    if (requestParameters.deleteTopicCommand === null || requestParameters.deleteTopicCommand === undefined) {
      throw new runtime.RequiredError(
        'deleteTopicCommand',
        'Required parameter requestParameters.deleteTopicCommand was null or undefined when calling deleteTopic.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/topics/:delete-topic`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DeleteTopicCommandToJSON(requestParameters.deleteTopicCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async deleteTopic(
    requestParameters: DeleteTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteTopicRaw(requestParameters, initOverrides);
  }

  /**
   * Get the topic with the specified topic id.
   * Gets a topic.
   */
  async fetchTopicRaw(
    requestParameters: FetchTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TopicViewModel>> {
    if (requestParameters.topicId === null || requestParameters.topicId === undefined) {
      throw new runtime.RequiredError(
        'topicId',
        'Required parameter requestParameters.topicId was null or undefined when calling fetchTopic.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchTopic.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/topics/{topicId}`
          .replace(`{${'topicId'}}`, encodeURIComponent(String(requestParameters.topicId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TopicViewModelFromJSON(jsonValue));
  }

  /**
   * Get the topic with the specified topic id.
   * Gets a topic.
   */
  async fetchTopic(
    requestParameters: FetchTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TopicViewModel> {
    const response = await this.fetchTopicRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async listTopicsRaw(
    requestParameters: ListTopicsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<TopicViewModel>>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listTopics.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/topics`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TopicViewModelFromJSON));
  }

  /**
   */
  async listTopics(
    requestParameters: ListTopicsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<TopicViewModel>> {
    const response = await this.listTopicsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async removeFromTopicRaw(
    requestParameters: RemoveFromTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling removeFromTopic.',
      );
    }

    if (requestParameters.removeFromTopicCommand === null || requestParameters.removeFromTopicCommand === undefined) {
      throw new runtime.RequiredError(
        'removeFromTopicCommand',
        'Required parameter requestParameters.removeFromTopicCommand was null or undefined when calling removeFromTopic.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/topics/:remove-from-topic`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RemoveFromTopicCommandToJSON(requestParameters.removeFromTopicCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async removeFromTopic(
    requestParameters: RemoveFromTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.removeFromTopicRaw(requestParameters, initOverrides);
  }

  /**
   */
  async updateEntityTopicsRaw(
    requestParameters: UpdateEntityTopicsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateEntityTopics.',
      );
    }

    if (
      requestParameters.updateEntityTopicsCommand === null ||
      requestParameters.updateEntityTopicsCommand === undefined
    ) {
      throw new runtime.RequiredError(
        'updateEntityTopicsCommand',
        'Required parameter requestParameters.updateEntityTopicsCommand was null or undefined when calling updateEntityTopics.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/topics/:update-entity-topics`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateEntityTopicsCommandToJSON(requestParameters.updateEntityTopicsCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async updateEntityTopics(
    requestParameters: UpdateEntityTopicsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateEntityTopicsRaw(requestParameters, initOverrides);
  }

  /**
   */
  async updateTopicRaw(
    requestParameters: UpdateTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateTopic.',
      );
    }

    if (requestParameters.updateTopicCommand === null || requestParameters.updateTopicCommand === undefined) {
      throw new runtime.RequiredError(
        'updateTopicCommand',
        'Required parameter requestParameters.updateTopicCommand was null or undefined when calling updateTopic.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/topics/:update-topic`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateTopicCommandToJSON(requestParameters.updateTopicCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async updateTopic(
    requestParameters: UpdateTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateTopicRaw(requestParameters, initOverrides);
  }
}
