import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { vfs as pdfLocalFonts } from './vfs-fonts.js';
import { Content, ContentColumns, ContentSvg, TDocumentDefinitions } from 'pdfmake/interfaces';

(<any>pdfMake).addVirtualFileSystem(pdfFonts);
(<any>pdfMake).addVirtualFileSystem(pdfLocalFonts);

(<any>pdfMake).addFonts({
  Gotham: {
    normal: 'Gotham-Book.ttf',
    bold: 'Gotham-Bold.ttf',
    italics: 'Gotham-BookItalic.ttf',
    bolditalics: 'Gotham-BoldItalic.ttf',
  },
  GothamMedium: {
    normal: 'Gotham-Medium.ttf',
    bold: 'Gotham-Medium.ttf',
    italics: 'Gotham-MediumItalic.ttf',
    bolditalics: 'Gotham-MediumItalic.ttf',
  },
  Chronicle: {
    normal: 'ChronicleTextG1-Roman-Pro.ttf',
    bold: 'ChronicleTextG1-Bold-Pro.ttf',
    italics: 'ChronicleTextG1-Italic-Pro.ttf',
    bolditalics: 'ChronicleTextG1-BoldIta-Pro.ttf',
  },
});

function listHeader(text: string) {
  return {
    text: text.toUpperCase(),
    fontSize: 9,
    bold: true,
    characterSpacing: 0.8,
    font: 'Gotham',
    marginLeft: mmToPoints(3),
    marginTop: mmToPoints(2),
  };
}

const MILLIMETERS_IN_INCH = 25.4;
const POINTS_IN_INCH = 72;

function mmToPoints(mm): number {
  const inches = mm / MILLIMETERS_IN_INCH;
  return inches * POINTS_IN_INCH;
}

function addNamedItems(content: any[], items: CollectionPdfNamedItem[], heading: string) {
  if (items.length > 0) {
    content.push(listHeader(heading));
    content.push({
      ul: items.map((e) => e.name),
    });
  }
}

function createPageHeader(entityView: CollectionPdf): ContentColumns {
  return {
    margin: [mmToPoints(30), mmToPoints(7), mmToPoints(15), 24],
    columns: [entityView.organizationName, { text: 'TrinnVis', alignment: 'right' }],
    fontSize: 9,
    font: 'Gotham',
  };
}

export interface CollectionPdfNamedItem {
  name: string;
}

export interface CollectionPdf {
  organizationName: string;
  name: string;
  description: string;
  filterPeriodDisplayText: string;
  filterPeopleDisplayText: string;
  events: { checkedClass: string; name: string; assignedTo: string | undefined; doneText: string; notes: string }[];

  reports: CollectionPdfNamedItem[];
  tasks: CollectionPdfNamedItem[];
  guidelines: CollectionPdfNamedItem[];
  documents: CollectionPdfNamedItem[];
  constitutionalDocuments: CollectionPdfNamedItem[];
  contracts: CollectionPdfNamedItem[];
  functions: CollectionPdfNamedItem[];
  assets: CollectionPdfNamedItem[];
  substances: CollectionPdfNamedItem[];
  riskAssessments: CollectionPdfNamedItem[];
  issues: CollectionPdfNamedItem[];
  employees: CollectionPdfNamedItem[];
  partners: CollectionPdfNamedItem[];
  contacts: CollectionPdfNamedItem[];
}
const hr = (marginLeft = 0, width = 0.05, lineHeight = 0.75): Content => ({
  table: {
    widths: ['*'],
    body: [[' '], [' ']],
  },
  lineHeight,
  layout: {
    hLineWidth: (i, node) => (i === 0 || i === node.table.body.length ? 0 : width),
    vLineWidth: () => 0,
    hLineColor: '#c0c0c0',
  },
  marginLeft: marginLeft,
});

function eventOccurrencesListIcon(): ContentSvg {
  return {
    width: mmToPoints(7.5),
    svg: `<svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 62 62"><defs><linearGradient id="c" x1="31" y1="6" x2="31" y2="56" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#118fbd"/><stop offset="1" stop-color="#15ade5"/></linearGradient></defs><g id="a"><rect y="0" width="62" height="62" style="fill:none;"/></g><g id="b"><path d="M53.7,20.52l-21.68,25.09L14.67,28.77l7.96-9.21,11.99,11.75,14.99-16.99c-4.58-5.1-11.22-8.31-18.61-8.31-13.81,0-25,11.19-25,25s11.19,25,25,25,25-11.19,25-25c0-3.74-.83-7.29-2.3-10.48Z" style="fill:url(#c);"/></g></svg>`,
  };
}

function checkMiniBlueIcon(): ContentSvg {
  return {
    width: mmToPoints(6.5),
    svg: `<svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62"><g id="a"><rect y="0" width="62" height="62" style="fill:none;"/></g><g id="b"><polygon points="45.67 15.52 31.71 31.3 19.16 18.96 10.66 28.67 29.08 46.48 51.34 20.78 45.67 15.52" style="fill:#15ade5;"/></g></svg>`,
  };
}

function topicIcon(): ContentSvg {
  return {
    width: mmToPoints(15),
    svg: `<svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 62 62"><defs><linearGradient id="c" x1="31" y1="6" x2="31" y2="56" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#118fbd"/><stop offset="1" stop-color="#15ade5"/></linearGradient></defs><g id="a"><rect y="0" width="62" height="62" style="fill:none;"/></g><g id="b"><path d="M31,6c-13.81,0-25,11.19-25,25s11.19,25,25,25,25-11.19,25-25S44.81,6,31,6ZM23.66,44.68c-2.34,0-4.24-1.9-4.24-4.24s1.9-4.24,4.24-4.24,4.24,1.9,4.24,4.24-1.9,4.24-4.24,4.24ZM27.21,32.78c-4.99,0-9.04-4.05-9.04-9.04s4.05-9.04,9.04-9.04,9.04,4.05,9.04,9.04-4.05,9.04-9.04,9.04ZM37.64,46.64c-3.42,0-6.2-2.77-6.2-6.2s2.77-6.2,6.2-6.2,6.2,2.77,6.2,6.2-2.77,6.2-6.2,6.2ZM42.24,31c-1.52,0-2.75-1.23-2.75-2.75s1.23-2.75,2.75-2.75,2.75,1.23,2.75,2.75-1.23,2.75-2.75,2.75Z" style="fill:url(#c);"/></g></svg>`,
  };
}

export function createTopicPdf(entityView: CollectionPdf) {
  const content: Content[] = [
    {
      columns: [
        topicIcon(),
        {
          width: '*',
          margin: [0, mmToPoints(4), 0, 0],
          text: entityView.name,
          fontSize: 22,
          font: 'Gotham',
          bold: true,
        },
      ],
    },
  ];
  if (entityView.description) {
    content.push(listHeader('Beskrivelse'));
    content.push(entityView.description);
  }
  content.push(hr());
  const h: Content[] = [];
  if (entityView.filterPeriodDisplayText) {
    h.push({
      text: [
        { text: 'Periode ', bold: true, font: 'GothamMedium', fontSize: 10 },
        { text: entityView.filterPeriodDisplayText, font: 'Chronicle', fontSize: 11 },
      ],
    });
  }
  if (entityView.filterPeopleDisplayText) {
    h.push({
      text: [
        { text: 'Deltakere ', bold: true, font: 'GothamMedium', fontSize: 10 },
        { text: entityView.filterPeopleDisplayText, font: 'Chronicle', fontSize: 11 },
      ],
    });
  }
  content.push({ columns: h });
  content.push(hr());

  if (entityView.events.length > 0) {
    content.push({ columns: [eventOccurrencesListIcon(), listHeader('Oppgaver og møter')] });
    entityView.events.forEach((event) => {
      const marginLeftMillimeters = 10;
      content.push(hr(mmToPoints(marginLeftMillimeters)));

      const icon = event.checkedClass === 'checked' ? [checkMiniBlueIcon()] : [];
      content.push({
        columns: [
          ...icon,
          {
            width: '*',
            text: event.name,
            marginLeft: event.checkedClass === 'checked' ? mmToPoints(3.5) : mmToPoints(marginLeftMillimeters),
            font: 'Gotham',
            fontSize: 11,
            lineHeight: 1.2,
          },
        ],
      });
      if (event.assignedTo) {
        content.push({
          text: [
            { text: 'Tildelt ', bold: true, font: 'GothamMedium', fontSize: 9 },
            { text: event.assignedTo, font: 'Chronicle', fontSize: 10 },
          ],
          marginLeft: mmToPoints(marginLeftMillimeters),
          marginTop: mmToPoints(2),
          lineHeight: 1.2,
        });
      }
      if (event.doneText) {
        content.push({
          text: [
            { text: 'Utført ', bold: true, font: 'GothamMedium', fontSize: 9 },
            { text: event.doneText, font: 'Chronicle', fontSize: 10 },
          ],
          marginLeft: mmToPoints(marginLeftMillimeters),
          marginTop: mmToPoints(2),
          lineHeight: 1.2,
        });
      }
      if (event.notes) {
        content.push({
          text: [
            { text: 'Notater ', bold: true, font: 'GothamMedium', fontSize: 9 },
            { text: event.notes, font: 'Chronicle', fontSize: 10 },
          ],
          marginLeft: mmToPoints(marginLeftMillimeters),
          marginTop: mmToPoints(2),
          lineHeight: 1.2,
        });
      }
    });
  }

  addNamedItems(content, entityView.reports, 'Referater og rapporter');
  addNamedItems(content, entityView.tasks, 'Rutiner');
  addNamedItems(content, entityView.guidelines, 'Retningslinjer');
  addNamedItems(content, entityView.documents, 'Måldokumenter');
  addNamedItems(content, entityView.constitutionalDocuments, 'Stiftelsesdokumenter');
  addNamedItems(content, entityView.contracts, 'Avtaler');
  addNamedItems(content, entityView.functions, 'Ansvarsområder');
  addNamedItems(content, entityView.assets, 'Utstyrsenheter');
  addNamedItems(content, entityView.substances, 'Stoffer');
  addNamedItems(content, entityView.riskAssessments, 'Risikovurderinger');
  addNamedItems(content, entityView.issues, 'Avvik');
  addNamedItems(content, entityView.employees, 'Personale');
  addNamedItems(content, entityView.partners, 'Samarbeidspartnere');
  addNamedItems(content, entityView.contacts, 'Kontaktpersoner');

  const docDefinition: TDocumentDefinitions = {
    header: createPageHeader(entityView),
    footer: (currentPage, pageCount): ContentColumns => {
      return {
        margin: 24,
        columns: [
          { text: 'Samling: ' + entityView.name, font: 'Gotham', fontSize: 9 },
          {
            text: 'Side ' + currentPage.toString() + ' av ' + pageCount,
            alignment: 'right',
            font: 'Gotham',
            fontSize: 9,
          },
        ],
      };
    },

    pageSize: 'A4',
    pageMargins: [mmToPoints(30), mmToPoints(30), mmToPoints(15), mmToPoints(20)],
    content: content,
    styles: {
      header: {
        fontSize: 22,
        bold: true,
        font: 'Gotham',
      },
      anotherStyle: {
        italics: true,
        alignment: 'right',
      },
    },
    defaultStyle: {
      fontSize: 9,
      bold: false,
      font: 'Gotham',
    },
  };
  return pdfMake.createPdf(docDefinition);
}
