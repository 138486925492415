/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface EmailTokenLoginResponse
 */
export interface EmailTokenLoginResponse {
  /**
   *
   * @type {string}
   * @memberof EmailTokenLoginResponse
   */
  accessToken: string;
  /**
   *
   * @type {string}
   * @memberof EmailTokenLoginResponse
   */
  refreshToken: string;
  /**
   *
   * @type {string}
   * @memberof EmailTokenLoginResponse
   */
  username: string;
}

/**
 * Check if a given object implements the EmailTokenLoginResponse interface.
 */
export function instanceOfEmailTokenLoginResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'accessToken' in value;
  isInstance = isInstance && 'refreshToken' in value;
  isInstance = isInstance && 'username' in value;

  return isInstance;
}

export function EmailTokenLoginResponseFromJSON(json: any): EmailTokenLoginResponse {
  return EmailTokenLoginResponseFromJSONTyped(json, false);
}

export function EmailTokenLoginResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailTokenLoginResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    accessToken: json['accessToken'],
    refreshToken: json['refreshToken'],
    username: json['username'],
  };
}

export function EmailTokenLoginResponseToJSON(value?: EmailTokenLoginResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    accessToken: value.accessToken,
    refreshToken: value.refreshToken,
    username: value.username,
  };
}
