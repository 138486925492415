import type { Revision } from 'src/content/entity-content.js';
import type { AttachmentItem } from 'src/library/lists/d-list-section-attachment.js';
import type { AttachmentViewModel, SaveDraftCommand } from 'src/store/api';
import { ApiClient, attachment, draft, revisions } from 'src/store/backend.js';
import { getOrganizationId, getPageGroups, getPageStatus } from 'src/store/selectors';
import type { State } from 'src/store/types.js';
import { getStore } from 'src/store';
import { sortBy } from 'lodash';
import { displayName } from 'src/utilities/text';

export function toAttachmentItems(
  api: ApiClient,
  entityType: string,
  entityUuid,
  attachments: AttachmentViewModel[] | undefined,
  parentId?: string,
): AttachmentItem[] {
  const list = (attachments ?? []).map((a) => ({
    name: displayName(a.name),
    uuid: a.uuid,
    fullPath: a.fullPath ?? '',
    previewHref: a.urlForInline ?? '',
    downloadHref: a.url ?? '',
    asDocumentItem: async () => {
      const organizationId = getOrganizationId(getStore().getState());
      const a2 = await attachment(api, entityType, entityUuid, organizationId, a.uuid, parentId);
      return {
        name: displayName(a2.name),
        url: a2.urlForInline ?? '',
        urlForDownload: a2.url ?? '',
        entityType: entityType,
        attachmentId: a.uuid,
        uuid: entityUuid,
      };
    },
  }));
  return sortBy(list, [(x) => x.name]);
}

export async function fetchRevisions(
  api: ApiClient,
  entityType: string,
  entityId: string,
  organizationId: number,
  templateDeleted: boolean,
  parentEntityId?: string,
): Promise<Revision[]> {
  const r = await revisions(api, entityType, entityId, organizationId, parentEntityId);

  const suggested = r.find((i) => i.status === 'SUGGESTED');
  const revisionsNotSuggested = r.filter(function (e) {
    return e.status !== 'SUGGESTED';
  });
  const isSuggestedInUse =
    suggested !== undefined &&
    revisionsNotSuggested.filter(function (e) {
      return e.name === suggested.name && e.content === suggested.content + 'W';
    }).length > 0;
  const excludeSuggested = !templateDeleted && isSuggestedInUse;

  const filtered = excludeSuggested ? revisionsNotSuggested : r;

  return filtered.map((v) => ({
    changedBy: v.changedBy ?? '',
    dateTime: v.dateTime ?? '',
    status: v.status ?? '',
    name: v.name ?? '',
    content: v.content ?? '',
    differences: {
      name: v.nameDifference ?? '',
      content: v.contentDifference ?? '',
    },
  }));
}

export function pagesAsSelectTagOptions(state: State) {
  const pageGroups = getPageGroups(state);
  const themePages = pageGroups.find((group) => {
    return group.code === 'themePages';
  });
  if (themePages) {
    return themePages.pages
      .filter((page) => {
        return getPageStatus(state, page.pageId);
      })
      .map((page) => {
        return { value: '' + page.pageId, text: page.name };
      });
  }
  return [];
}

export async function fetchDraft(
  api: ApiClient,
  entityType: string,
  entityId: string,
  organizationId: number,
): Promise<SaveDraftCommand> {
  return draft(api, entityType, entityId, organizationId);
}
