import { css, html, LitElement, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import {
  type DeviceMap,
  DeviceType,
  type HMSMediaSettings,
  HMSPeer,
  HMSReactiveStore,
  selectDevices,
  selectIsConnectedToRoom,
  selectIsLocalAudioEnabled,
  selectIsLocalVideoEnabled,
  selectLocalMediaSettings,
  selectPeers,
  selectVideoTrackByID,
} from '@100mslive/hms-video-store';
import { HMSNotificationTypes } from '@100mslive/hms-video-store';
import { createRef, ref, Ref } from 'lit/directives/ref.js';

import './meet/d-meet-footer';
import '@shoelace-style/shoelace/dist/themes/light.css';
import '@shoelace-style/shoelace/dist/components/button-group/button-group.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/visually-hidden/visually-hidden.js';
import '@shoelace-style/shoelace/dist/components/menu/menu.js';
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item.js';

import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path.js';

setBasePath('/shoelace');

@customElement('d-meet-viewer')
export class DMeetViewer extends LitElement {
  static readonly styles = css`
    :host {
      min-height: 100vh;
      display: grid;
      grid-template-rows: 1fr auto;
      grid-template-columns: 100%;
      position: relative;

      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
        'Helvetica Neue', sans-serif;
      background-color: #263238;
      color: white;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-weight: normal;
    }

    #header {
      padding: 10px;
      display: flex;
      align-items: end;
      justify-content: space-between;
    }

    .btn-danger {
      border: 1px solid transparent;
      border-radius: 4px;
      padding: 6px 14px;
      background-color: #f44336;
      color: white;
      font-family: inherit;
      font-size: 14px;
    }

    .hide {
      display: none !important;
    }

    form {
      max-width: 450px;
      margin: 30px auto;
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
      border-radius: 8px;
      padding: 20px;
      width: 100%;
    }

    input {
      display: block;
      width: 100%;
      border-radius: 8px;
      border: 2px solid transparent;
      height: 34px;
      padding: 5px;
      background: #37474f;
      color: inherit;
      font-family: inherit;
    }

    input::placeholder {
      color: #aaa;
    }

    .input-container {
      margin-bottom: 20px;
    }

    .btn-primary {
      border: 1px solid transparent;
      border-radius: 4px;
      padding: 6px 14px;
      background-color: #1565c0;
      color: white;
      font-family: inherit;
      font-size: 14px;
    }

    form h2,
    .conference-section h2 {
      margin-bottom: 20px;
    }

    .conference-section {
      padding: 20px 30px;
      max-width: 960px;
      margin: 0 auto;
    }

    .conference-section h2 {
      text-align: center;
      font-size: 32px;
      padding-bottom: 10px;
      border-bottom: 1px solid #546e7a;
    }

    .grid-container {
      display: grid;
      gap: 10px;
      width: 600px; /* Fixed width for the grid */
      padding: 10px;
    }

    .grid-container div {
      background-color: #4caf50;
      color: white;
      position: relative;
      padding-top: 100%; /* Maintain 1:1 aspect ratio */
      border-radius: 10px;
    }

    .grid-container div span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.5rem;
    }

    /* Single column for up to 3 items */
    .grid-container {
      grid-template-columns: repeat(1, 1fr);
    }

    /* Two columns for more than 3 items */
    @media (min-width: 0px) {
      #peers-container > div:nth-child(n + 4) ~ div {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    #peers-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      place-items: center;
      grid-gap: 10px;
      gap: 10px;
      padding: 10px;
    }

    .peer-video {
      width: 100%;
      aspect-ratio: 1 / 1;
      object-fit: cover;
      border-radius: 20%;
      margin-bottom: 10px;
    }

    .local.peer-video {
      transform: scaleX(-1);
    }

    .peer-name {
      font-size: 14px;
      text-align: center;
    }

    .peer-tile {
      padding: 10px;
    }

    .control-bar {
      display: flex;
      position: fixed;
      bottom: 0;

      padding: 15px;
      justify-content: center;
      z-index: 10;
    }

    .control-bar > *:not(:first-child) {
      margin-left: 8px;
    }

    .btn-control {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      border: 2px solid #37474f;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      text-align: center;
      background-color: #607d8b;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
      color: white;
    }

    .video-grid {
      display: grid;
      gap: 10px;
      padding: 10px;
    }

    /* General styling for video items */
    .video-item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    /* Adjust grid layout based on the number of videos */
    .video-grid {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }

    /* Ensure each video is a square */
    .video-item video {
      width: 100%;
      height: 0;
      padding-bottom: 100%; /* Maintain aspect ratio for square videos */
      object-fit: cover;
    }

    .caption {
      text-align: center;
      margin-top: 5px;
      font-size: 14px;
    }
  `;
  @property({ type: String })
  userName!: string;
  @state()
  deviceMap?: DeviceMap;
  @state()
  localMediaSettings?: HMSMediaSettings;
  @property({ type: String })
  roomCode = '';
  // Initialize HMS Store
  private hmsManager = new HMSReactiveStore();
  private hmsStore = this.hmsManager.getStore();
  private hmsActions = this.hmsManager.getActions();
  private hmsNotifications = this.hmsManager.getNotifications();
  @state()
  private connected = false;
  @state()
  private peers: HMSPeer[] = [];
  private videoRefs: Map<string, Ref<Element>> = new Map();
  @state()
  private audioEnabled = true;
  @state()
  private videoEnabled = true;

  connectedCallback() {
    super.connectedCallback();
    this.hmsManager.triggerOnSubscribe();
    this.hmsStore.subscribe((b?: boolean) => this.onConnection(b), selectIsConnectedToRoom);
    this.hmsStore.subscribe(() => this.renderPeers(), selectPeers);
    this.hmsStore.subscribe(() => this.onUpdateDeviceMap(), selectDevices);
    this.hmsStore.subscribe((settings) => this.onUpdateLocalMediaSettings(settings), selectLocalMediaSettings);

    this.hmsNotifications.onNotification((notification) => {
      console.log('notification type', notification.type);
      console.log('data', notification.data);

      // you can use the following to show appropriate toast notifications for example
      switch (notification.type) {
        case HMSNotificationTypes.PEER_LIST:
          console.log(`${notification.data} are the peers in the room`); // received right after join
          break;
        case HMSNotificationTypes.PEER_JOINED:
          console.log(`${notification.data.name} joined`);
          break;
        case HMSNotificationTypes.PEER_LEFT:
          console.log(`${notification.data.name} left`);
          break;
        case HMSNotificationTypes.NEW_MESSAGE:
          console.log(`${notification.data.message} received from ${notification.data.senderName}`);
          break;
        case HMSNotificationTypes.ERROR:
          console.log('[Error]', notification.data);
          console.log('[Error Code]', notification.data.code);
          break;
        case HMSNotificationTypes.RECONNECTING:
          console.log('[Reconnecting]', notification.data);
          break;
        case HMSNotificationTypes.RECONNECTED:
          console.log('[Reconnected]');
          break;
        case HMSNotificationTypes.NAME_UPDATED:
        case HMSNotificationTypes.METADATA_UPDATED:
        case HMSNotificationTypes.ROLE_UPDATED:
          console.log(`peer updated(${notification.type}), new peer=`, notification.data);
          break;
        case HMSNotificationTypes.TRACK_DEGRADED:
          console.log(`track - ${notification.data} degraded due to poor network`);
          break;
        case HMSNotificationTypes.TRACK_RESTORED:
          console.log(`track - ${notification.data} recovered`);
          break;
        case HMSNotificationTypes.ROOM_ENDED:
          console.log(`room ended, reason - ${notification.data.reason}`);
          break;
        case HMSNotificationTypes.REMOVED_FROM_ROOM:
          console.log(`removed from room, reason - ${notification.data.reason}`);
          break;
        case HMSNotificationTypes.DEVICE_CHANGE_UPDATE:
          console.log(`device changed - ${notification.data}`);
          break;
        default:
          break;
      }
    });
    this.onJoinRoom().then(() => console.log('automatically connected'));
  }

  async onJoinRoom() {
    const authToken = await this.hmsActions.getAuthTokenByRoomCode({ roomCode: this.roomCode });

    await this.hmsActions.join({
      userName: this.userName,
      authToken,
    });
    this.connected = true;
  }

  async onLeaveRoom() {
    await this.hmsActions.leave();
    this.dispatchEvent(new CustomEvent('hide-meet', { bubbles: true, composed: true }));
  }

  async onMuteAudio() {
    this.audioEnabled = !this.hmsStore.getState(selectIsLocalAudioEnabled);
    await this.hmsActions.setLocalAudioEnabled(this.audioEnabled);
  }

  async onMuteVideo() {
    this.videoEnabled = !this.hmsStore.getState(selectIsLocalVideoEnabled);
    await this.hmsActions.setLocalVideoEnabled(this.videoEnabled);
  }

  onUpdateDeviceMap() {
    console.log('update device map');
    this.deviceMap = this.hmsStore.getState(selectDevices);
  }

  onUpdateLocalMediaSettings(settings: HMSMediaSettings) {
    console.log('update LocalMediaSettings');
    this.localMediaSettings = settings;
  }

  onConnection(isConnected?: boolean) {
    this.connected = isConnected === true;
  }

  renderPeers() {
    console.log('renderPeers');
    this.peers = this.hmsStore.getState(selectPeers);
  }

  protected render() {
    const selected = this.localMediaSettings;
    const deviceMap1 = this.deviceMap;
    console.log(deviceMap1);
    console.log(selected);

    return html`
      <form id="join" class="${this.connected ? 'hide' : ''}">
        <h2>Blir med nå</h2>
        <p>Kobler til ...</p>
      </form>

      <div id="conference" class="conference-section ${this.connected ? '' : 'hide'}">
        <a
          href="https://meet.trinnvis.no/meeting/${this.roomCode}/?name=${this.userName}"
          target="video"
          class="btn-primary"
          @click=${this.onLeaveRoom}
          >Bli med i eget vindu</a
        >
        <div id="peers-container">
          ${this.peers.filter((peer) => peer.videoTrack !== undefined).map((peer) => this.renderSinglePeer(peer))}
        </div>
      </div>

      ${deviceMap1 && selected
        ? html`<d-meet-footer class="${this.connected ? '' : 'hide'}">
            <sl-button-group label="Example Button Group" slot="left">
              <sl-button variant="primary" @click=${this.onMuteAudio}>
                <sl-icon name=${this.audioEnabled ? 'mic' : 'mic-mute'} label="Settings"></sl-icon>
              </sl-button>

              <sl-dropdown placement="bottom-end">
                <sl-button slot="trigger" variant="primary" caret>
                  <sl-visually-hidden>More options</sl-visually-hidden>
                </sl-button>
                <sl-menu>
                  ${deviceMap1[DeviceType.audioInput].map((c) => {
                    return html` <sl-menu-item
                      type="checkbox"
                      ?checked=${c.deviceId === selected.audioInputDeviceId}
                      @click=${async () => await this.onUpdateAudioDevice(c.deviceId)}
                      >${c.label}
                    </sl-menu-item>`;
                  })}
                </sl-menu>
              </sl-dropdown>
            </sl-button-group>
            <sl-button-group label="Example Button Group" slot="left">
              <sl-button variant="primary" @click=${this.onMuteVideo}>
                <sl-icon name=${this.videoEnabled ? 'camera-video' : 'camera-video-off'} label="Settings"></sl-icon>
              </sl-button>
              <sl-dropdown placement="bottom-end">
                <sl-button slot="trigger" variant="primary" caret>
                  <sl-visually-hidden>More options</sl-visually-hidden>
                </sl-button>
                <sl-menu>
                  ${deviceMap1[DeviceType.videoInput].map((c) => {
                    return html` <sl-menu-item
                      type="checkbox"
                      ?checked=${c.deviceId === selected.videoInputDeviceId}
                      @click=${async () => await this.onUpdateVideoDevice(c.deviceId)}
                      >${c.label}
                    </sl-menu-item>`;
                  })}
                </sl-menu>
              </sl-dropdown>
            </sl-button-group>

            <button style="height: 40px" id="leave-btn" class="btn-danger" slot="right" @click=${this.onLeaveRoom}>
              Forlat møte
            </button>
          </d-meet-footer>`
        : nothing}
    `;
  }

  private async onUpdateVideoDevice(deviceId: string) {
    await this.hmsActions.setVideoSettings({ deviceId: deviceId });
  }

  private async onUpdateAudioDevice(deviceId: string) {
    await this.hmsActions.setAudioSettings({ deviceId: deviceId });
  }

  private renderSinglePeer(peer: HMSPeer) {
    return html`<div class="peer-tile">
      <video ${ref((v?: Element) => this.createRef(peer, v))} class="peer-video" autoplay muted playsinline></video>

      <div class="peer-name">${peer.name}</div>
    </div>`;
  }

  private createRef(peer: HMSPeer, v?: Element): Ref<Element> | undefined {
    if (!this.videoRefs.has(peer.id)) {
      this.videoRefs.set(peer.id, createRef());

      this.hmsStore.subscribe(async (track) => {
        console.log('track');
        console.log(peer);
        console.log(track);
        if (!track) {
          return;
        }
        const videoElement = v as HTMLVideoElement;
        console.log(videoElement);

        if (videoElement) {
          if (track.enabled) {
            await this.hmsActions.attachVideo(track.id, videoElement);
          } else {
            await this.hmsActions.detachVideo(track.id, videoElement);
          }
        }
      }, selectVideoTrackByID(peer.videoTrack));
    }

    return this.videoRefs.get(peer.id);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-meet-viewer': DMeetViewer;
  }
}
