import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import {
  ShareStaffingDialog,
  ShareStaffingInput,
  ShareVacationResult,
} from 'src/pages/staffing-page/share-staffing-dialog.js';
import '../../library/editors/elements/d-edit-text.js';
import '../../library/editors/elements/d-edit-textarea.js';
import '../../library/editors/elements/d-select-tag.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import '../../library/elements/d-action.js';
import '../../library/elements/d-label.js';

import '../../library/elements/d-section.js';
import '../../library/elements/d-wrap.js';
import '../../library/fields/d-view-text.js';
import { LocalDate } from 'src/utilities/local-date';

/**
 * Viser en melding om hvem feiren er delt med, eller alternativer for å dele ferieplanen
 *
 * Inkluderer også dialog for å redigere / sende ferieplanen
 *
 *
 */
@customElement('d-share-staffing')
export class DShareStaffing extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    d-wrap > * {
      padding: 0;
    }
  `;
  @property({ type: Boolean })
  hideShareVacation = false;
  @property({ type: Array })
  employeesOptions: SelectTagOption[] = [];
  @property({ type: Array })
  selectedEmployees: string[] = [];
  @property({ type: String })
  notes = '';
  @property({ type: String })
  email = '';
  /**
   * Epostadressene som planen er delt med. For tiden kan dette kun være 1 eller ingen
   */
  @property({ type: Array })
  shares: { email: string; time: string }[] = [];
  @property({ type: Boolean })
  currentUserHasAccess = false;
  @property({ type: Number })
  organizationId = 0;
  @property({ type: String })
  token = '';

  private get summary() {
    const shares = this.shares;
    if (shares.length === 1) {
      return 'Delt med ' + shares[0].email;
    }
    if (shares.length > 1) {
      return 'Delt med flere';
    }
    if (shares.length === 0) {
      return 'Ingen deling';
    }
    return '';
  }

  render() {
    return html`
      ${this.hideShareVacation
        ? nothing
        : html`
            <d-wrap split-reverse baseline>
              ${!this.currentUserHasAccess
                ? html`<div></div>`
                : html` <d-action @click=${this.onOpenDialog}>Rediger</d-action> `}
              <d-view-text wrap-label label="Delt ferieplan" value="${this.summary}"></d-view-text>
            </d-wrap>
          `}
    `;
  }

  private async onOpenDialog() {
    if (this.currentUserHasAccess) {
      const input: ShareStaffingInput = {
        currentYear: LocalDate.now().year(),
        employeesOptions: this.employeesOptions,
        selectedEmployees: this.selectedEmployees,
        notes: this.notes,
        email: this.shares.length > 0 ? this.shares[0].email : '',
        time: this.shares.length > 0 ? this.shares[0].time : '',
      };
      const result: ShareVacationResult = await ShareStaffingDialog.open(input);
      console.error(result);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-share-staffing': DShareStaffing;
  }
}
