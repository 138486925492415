import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import 'src/library//elements/d-section';
import 'src/library//elements/d-tooltip';
import 'src/library/elements/d-label';
import 'src/library/editors/elements/d-radio-cell';
import 'src/library/editors/elements/d-checkbox';
import 'src/library/editors/elements/d-select-dropdown';
import { LabeledElement } from 'src/library/abstracts/labeled-element';
import { Feature, specialTermsOptions } from 'src/store/selectors/features';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';

/**
 *
 * STATUS OK
 */
@customElement('d-upgrade')
export class DUpgrade extends LabeledElement {
  static readonly styles = [
    ...LabeledElement.styles,
    css`
      .options {
        display: block;
        margin-bottom: -4px;
        line-height: 120%;
      }
      .option {
        display: flex;
        align-items: flex-start;
        box-sizing: border-box;
        min-height: var(--inputElementHeight);
        margin-bottom: 6px;
        padding: 6px 14px 0 0;
        cursor: pointer;
        color: hsla(1, 0%, 0%, 0.8);
        line-height: 120%;
      }
      .option:last-child {
        padding-right: 0;
      }
      d-radio-cell {
        margin-top: -3px;
        margin-right: 6px;
        margin-left: -1px;
      }
      d-checkbox {
        width: 30px;
        margin-top: -6px;
      }
      .option[selected] .radioIcon:before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: var(--themeColor);
      }
      .option-label {
        margin-bottom: 6px;
        font-weight: 200;
      }
      .option-label span:first-child {
        font-weight: 500;
        margin-right: 0.3em;
      }
      .comment {
        font-size: 13px;
        font-weight: 200;
      }
      .button {
        flex: none;
        margin-left: 6px;
        border-radius: 4px;
        background: var(--themeColor);
        padding: 8px 12px;
        font-size: 16px;
        font-weight: 500;
        color: white;
        cursor: pointer;
      }
      @media (hover: hover) {
        .button:hover {
          background: var(--themeColorDarkerTwo);
        }
      }
    `,
  ];

  @property({ type: Boolean })
  dialog = false;
  @property({ type: Number })
  employeesCount = 1;
  @property({ type: String })
  specialTerms: string | undefined = undefined;
  @property({ type: Array })
  features: Feature[] = [];
  @property({ type: Array })
  bundles: string[] = [];
  @property({ type: Array })
  modules: string[] = [];
  @property({ type: Array })
  addons: string[] = [];
  @property({ type: String })
  selectedFeature = 'BASIC';
  @property({ type: Array })
  selectedAddons: string[] = [];
  @property({ type: Array })
  healthcareSignatoryOptions: SelectDropdownOption[] = [];
  @property({ type: String })
  healthcareSignatory: string | undefined = undefined;

  private bundleText = `<p><strong>Med TrinnVis Komplett får du </strong>
•&nbsp;Ferdige rutiner og retningslinjer •&nbsp;HMS og personalhåndbok 
•&nbsp;Personvern og GDPR •&nbsp;Avvik, risikovurdering og internkontroll •&nbsp;Felles kalender og gjøreliste 
•&nbsp;Møter og medarbeidersamtaler •&nbsp;Ansvarsfordeling •&nbsp;Bemanningsoversikt •&nbsp;Utstyrsoversikt 
•&nbsp;Stoffkartotek&nbsp;&nbsp;<a href="https://trinnvis.no" target="_blank">Les&nbsp;mer&nbsp;om&nbsp;TrinnVis</a></p>`;

  private get moduleOptions() {
    return this.options(this.modules);
  }

  private get bundleOptions() {
    return this.options(this.bundles);
  }

  private get addonOptions() {
    return this.options(this.addons);
  }

  onCancel() {
    this.dispatchEvent(
      new CustomEvent('cancel', {
        bubbles: true,
        composed: true,
        detail: 'cancel',
      }),
    );
  }

  onUpgrade() {
    const features: string[] = [];
    if (this.selectedFeature) {
      features.push(this.selectedFeature);
    }
    if (this.selectedAddons.length) {
      this.selectedAddons.forEach((a) => {
        features.push(a);
      });
    }
    this.dispatchEvent(
      new CustomEvent<{ features: string[]; healthcareSignatory: string | undefined }>('add-features', {
        composed: true,
        bubbles: true,
        detail: { features, healthcareSignatory: this.healthcareSignatory },
      }),
    );
    if (this.specialTerms) {
      this.dispatchEvent(
        new CustomEvent<{ specialTerms: string }>('update-special-terms', {
          composed: true,
          bubbles: true,
          detail: { specialTerms: this.specialTerms },
        }),
      );
    }
  }

  renderFeatureOptions(options) {
    return html`
      ${options.map(
        (item) =>
          html` <div
            class="option"
            @click=${() => this.toggleSelect(item.value)}
            ?selected=${item.value === this.selectedFeature}
          >
            <d-radio-cell ?checked=${item.value === this.selectedFeature}></d-radio-cell>
            <div>
              <div class="option-label"><span>${item.text}</span><span>${item.secondaryText}</span></div>
              <div class="comment">${item.description}</div>
            </div>
          </div>`,
      )}
    `;
  }

  renderModuleOptions() {
    return html`<d-section> <div class="options">${this.renderFeatureOptions(this.moduleOptions)}</div> </d-section>`;
  }

  renderAddons() {
    return html`
      ${this.addonOptions.map(
        (item) =>
          html` <div class="option" @click=${() => this.toggleAddon(item.value)}>
            <d-checkbox
              ?theme-page="${this.themePage}"
              ?checked=${this.selectedAddons.includes(item.value)}
            ></d-checkbox>
            <div>
              <div class="option-label"><span>${item.text}</span><span>${item.secondaryText}</span></div>
              <div class="comment">${item.description}</div>
            </div>
          </div>`,
      )}
    `;
  }

  renderBundleOptions() {
    return html`<d-section>
      <div class="options">
        ${this.renderFeatureOptions(this.bundleOptions)}
        ${this.addons.length ? html` ${this.renderAddons()} ` : nothing}
      </div></d-section
    >`;
  }

  renderContent() {
    return html`
      ${this.moduleOptions.length ? html` ${this.renderModuleOptions()} ` : nothing}
      ${this.bundleOptions.length
        ? html`
            <d-section>
              <d-view-info .content=${this.bundleText}> </d-view-info>
            </d-section>
            ${this.renderBundleOptions()}
          `
        : nothing}
      <d-section style="margin-bottom: 6px">
        <d-wrap split style="align-items: end">
          ${this.bundleOptions.length && this.specialTerms
            ? html`
                <d-select-dropdown
                  ?theme-page="${this.themePage}"
                  system-content
                  label="Medlemsrabatt"
                  .options=${specialTermsOptions}
                  .value=${this.specialTerms ?? 'NONE'}
                  @value-changed=${(e: CustomEvent<{ value: string }>) => {
                    this.specialTerms = e.detail.value;
                  }}
                ></d-select-dropdown>
              `
            : nothing}
          ${this.selectedFeature === 'HEALTHCARE'
            ? html`
                <d-select-dropdown
                  ?theme-page="${this.themePage}"
                  system-content
                  label="Avtale signeres av"
                  .options=${this.healthcareSignatoryOptions}
                  .value=${this.healthcareSignatory ?? ''}
                  @value-changed=${(e: CustomEvent<{ value: string }>) => {
                    this.healthcareSignatory = e.detail.value;
                  }}
                ></d-select-dropdown>
              `
            : nothing}
          <div style="flex: 1; align-self: flex-end; display: flex; align-items: baseline; justify-content: flex-end">
            ${this.dialog ? html` <d-action plain @click=${() => this.onCancel()}>Avbryt</d-action> ` : nothing}
            <div class="button" @click=${() => this.onUpgrade()}>Oppgrader</div>
          </div>
        </d-wrap>
      </d-section>
    `;
  }

  private priceText(feature: Feature): string {
    if (feature.price) {
      if (feature.organizationPrice) {
        return feature.price + ',-/mnd';
      }
      return feature.price + ',-/mnd/bruker';
    } else if (feature.prices) {
      let priceGroup = 'd';
      let planSizeText = '16 til 30';
      if (this.employeesCount < 16) {
        priceGroup = 'c';
        planSizeText = '9 til 13';
      }
      if (this.employeesCount < 9) {
        priceGroup = 'b';
        planSizeText = 'fire til åtte';
      }
      if (this.employeesCount < 4) {
        priceGroup = 'a';
        planSizeText = 'én til tre';
      }
      let priceType = 'none';
      if (this.specialTerms !== '' && this.specialTerms !== 'NONE') {
        priceType = 'member';
      }
      return feature.prices[priceGroup][priceType] + ',-/mnd for ' + planSizeText + ' personer';
    }
    return '';
  }

  private options(items: string[]) {
    return items.map((i) => {
      let text = '';
      let secondaryText = '';
      let description = '';
      const feature: Feature | undefined = this.features.find((f) => {
        return f.id === i;
      });
      if (feature) {
        text = feature.displayName;
        secondaryText = this.priceText(feature);
        description = feature.description;
      }
      return {
        value: i,
        text,
        secondaryText,
        description,
      };
    });
  }

  private toggleSelect(value: string) {
    if (this.selectedFeature !== value) {
      this.selectedFeature = value;
    }
  }

  private toggleAddon(value: string) {
    if (this.selectedAddons.includes(value)) {
      this.selectedAddons = this.selectedAddons.filter((a) => {
        return a !== value;
      });
    } else {
      const selectedAddons: string[] = [...this.selectedAddons];
      selectedAddons.push(value);
      this.selectedAddons = selectedAddons;
    }
    console.log('toggleAddon', this.selectedAddons);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-upgrade': DUpgrade;
  }
}
