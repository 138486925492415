import { css, html, nothing, PropertyValues, TemplateResult } from 'lit';
import { AbstractEntityView, EntityContent, ListDefinition } from 'src/content/entity-content.js';
import 'src/library/elements/d-section';
import 'src/library/editors/elements/d-edit-text';
import 'src/library/editors/elements/d-edit-textarea';
import 'src/library/elements/d-wrap';
import 'src/library/elements/d-action';
import 'src/library/lists/d-list-section';
import 'src/library/editors/elements/d-select-dropdown';
import 'src/library/editors/elements/d-select-date';
import '../../library/editors/components/d-edit-timespan';
import 'src/library/elements/d-smooth-resize';
import 'src/library/fields/d-closer';

import { customElement, property, query, state } from 'lit/decorators.js';
import { isEmptyOrInvalidString, uuid } from 'src/utilities/text';
import { UpdateSectionItem } from 'src/content/d-update-section';

import { ListSectionItemInput } from 'src/library/lists/utilities';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import {
  CollectionEntity,
  CollectionFilter,
  CollectionItem,
  CollectionSuggestionClickData,
  getFilterParams,
  getItemsAsCollectionEntities,
} from 'src/store/selectors/collections';
import { LocalDate } from 'src/utilities/local-date';
import {
  RecurringEventInCollectionDialog,
  RecurringEventInCollectionDialogResult,
} from 'src/content/collections/recurring-event-in-collection-dialog';
import { MeetingViewModel, TaskViewModel } from 'src/store/api';
import { CreateEntityInput, CreateEventOccurrenceInput } from 'src/layout/parts/d-new-documents-list';
import { allEqual, employeeInterviewTemplateId } from 'src/store';
import _ from 'lodash';
import { SectionField } from 'src/library/components/d-field-section';
import { ActionInput } from 'src/library/elements/d-action';

import { CollectionPdf, createTopicPdf } from 'src/pdf/make-pdf';

export interface CollectionViewEditItem {
  name: string;
  description: string;
  pages: string[];
  items: CollectionItem[];
}

export interface PartnerWithContacts {
  uuid: string;
  name: string;
  contacts: { uuid: string; name: string }[];
}

export interface OptionWithPartner extends SelectDropdownOption {
  partnerUuid?: string;
  partnerName?: string;
}

export interface CollectionView extends AbstractEntityView<CollectionViewEditItem> {
  organizationName: string;
  currentUserUuid: string;
  currentUserHasAccess: boolean;
  pages: string[];
  type: 'collections';
  description: string;
  collectibles: CollectionEntity[];
  availableMeetings: MeetingViewModel[];
  availableTasks: TaskViewModel[];
  items: CollectionItem[];
  events: ListSectionItemInput[];
  reports: ListSectionItemInput[];
  tasks: ListSectionItemInput[];
  guidelines: ListSectionItemInput[];
  documents: ListSectionItemInput[];
  constitutionalDocuments: ListSectionItemInput[];
  contracts: ListSectionItemInput[];
  functions: ListSectionItemInput[];
  assets: ListSectionItemInput[];
  substances: ListSectionItemInput[];
  riskAssessments: ListSectionItemInput[];
  issues: ListSectionItemInput[];
  employees: ListSectionItemInput[];
  partners: ListSectionItemInput[];
  contacts: ListSectionItemInput[];
  suggestionsAsListItems: ListSectionItemInput[];
  filterByAssignedOptions: OptionWithPartner[];
  partnersWithContacts: PartnerWithContacts[];
  allCollectionsFilter: CollectionFilter[];
  filterStartDate: string;
  filterEndDate: string;
  filterPeople: string[];
  filterYear: string;
  filterPeriodDisplayText: string;
  filterPeopleDisplayText: string;
  editRestricted: boolean;
}

/**
 *
 */
@customElement('d-collection-view')
export class DCollectionView extends EntityContent<CollectionView, CollectionViewEditItem> {
  static readonly styles = EntityContent.styles.concat(css`
    d-list-section[bordered] {
      margin-bottom: 0;
    }

    .subitems {
      padding-left: var(--listPaddingLeft);
    }

    .edit-list {
      background-color: var(--backgroundLightGray);
      box-shadow: var(--inputElementShadow);
      padding: 0 16px;
    }

    .documents > div {
      display: block;
      background-size: 24px;
      background-position: 0 4px;
      background-repeat: no-repeat;
      padding: 6px 0 6px 30px;
      color: var(--linkColorGray);
      cursor: pointer;
    }

    .suggestions-section {
      position: relative;
      margin-top: 16px;
      border: 1px solid var(--borderColor);
      border-radius: 20px;
      padding: 0 20px;
      background: hsl(1, 0%, 96%);
      transition: all 0.3s;
    }

    .suggestions-section[open] {
      border-radius: 12px;
      padding: 8px 20px 0 16px;
    }

    .suggestions-header {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      position: sticky;
      margin-left: -20px;
      margin-bottom: 2px;
      border-radius: 20px;
      padding: 8px 8px 6px 32px;
      background: hsl(1, 0%, 96%) url(/images/suggestion-gray.svg) -2px 0 no-repeat;
      background-size: 38px auto;
      color: var(--linkColorGray);
      z-index: 1;
      cursor: pointer;
      transition:
        all 0.3s,
        color 0s;
    }

    @media (hover: hover) {
      .suggestions-header:hover {
        color: black;
      }
    }

    .suggestions-section[open] .suggestions-header {
      margin-left: -6px;
      border-radius: 0;
      background-size: 50px;
      background-position-x: -5px;
      background-position-y: -7px;
      padding-left: 40px;
      font-size: 16px;
      font-weight: 500;
      color: var(--text-color);
    }

    .search-section {
      position: relative;
      margin-top: 12px;
      border-top: 1px solid var(--borderColor);
    }

    .search-header {
      position: sticky;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      padding: 12px 0 8px;
      background: white;
    }

    d-list-section-item {
      position: relative;
    }

    #tooltip {
      width: 0;
      height: 0;
      left: 0;
      top: 0;
      margin: -24px 0 0 -2px;
      inset: unset;
      overflow: visible;
      border: none;
    }

    #tooltip > div {
      position: absolute;
      box-sizing: border-box;
      bottom: 0;
      width: 240px;
      margin-left: -120px;
      font-size: 15px;
      color: white;
      text-align: center;
    }

    #tooltip > div > div {
      display: inline-block;
      border-radius: 6px;
      padding: 8px 12px;
    }

    #tooltip.green > div > div {
      background: #03c703;
    }

    #tooltip.blue > div > div {
      background: var(--themeColor);
    }

    #tooltip > div:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -10px;
      border-width: 10px 8px 0 8px;
      border-style: solid;
      border-color: #03c703 transparent transparent transparent;
    }

    #tooltip.green > div:after {
      border-top-color: #03c703;
    }

    #tooltip.blue > div:after {
      border-top-color: var(--themeColor);
    }

    @media (hover: hover) {
      .documents > div:hover {
        color: black;
      }
    }
  `);
  @property({ type: String })
  entityType = 'collections';
  @property({ type: String })
  filterYearSelected = '';
  @property({ type: String })
  filterAssignee = '';
  @state()
  searchString = '';
  @state()
  docTypeFilter = 'all';
  @state()
  showSuggestions = false;
  @state()
  cursorX = 0;
  @state()
  cursorY = 0;
  @query('#tooltip')
  tooltip!: HTMLElement;
  @state()
  tooltipClass = 'green';
  @state()
  tooltipContent = '';

  protected get preventDelete(): boolean {
    return this.entityView.editRestricted;
  }

  private get filterAssignees(): string[] {
    if (!this.filterAssignee) {
      return [];
    }
    const partner = this.entityView.partnersWithContacts.find((p) => {
      return p.uuid === this.filterAssignee;
    });
    if (partner) {
      return partner.contacts.map((c) => {
        return c.uuid;
      });
    }
    return [this.filterAssignee];
  }

  private get filterAssigneeSelectValue(): string {
    if (this.entityView.filterPeople.length === 0) {
      return '';
    }
    const selected = this.entityView.filterByAssignedOptions.filter((o) => {
      return this.entityView.filterPeople?.includes(o.value);
    });
    if (selected.length) {
      if (
        selected.length > 1 &&
        selected[0].partnerUuid &&
        allEqual(
          selected.map((o) => {
            return o.partnerUuid;
          }),
        )
      ) {
        return selected[0].partnerUuid;
      }
      return selected[0].value;
    }
    return '';
  }

  private get collectedIds(): string[] {
    return this.entityView.items
      .filter((item) => {
        return item.uuid !== '';
      })
      .map((item) => {
        return item.uuid;
      });
  }

  private get docTypeOptions(): SelectDropdownOption[] {
    return [
      { value: 'all', text: 'Alle elementtyper' },
      { value: 'events', text: 'Oppgaver' },
      { value: 'meetings', text: 'Møter' },
      { value: 'reports', text: 'Rapporter og referater' },
      { value: 'tasks', text: 'Rutiner' },
      { value: 'guidelines', text: 'Retningslinjer' },
      { value: 'documents', text: 'Måldokumenter' },
      { value: 'constitutionalDocuments', text: 'Stiftelsesdokumenter' },
      { value: 'contracts', text: 'Avtaler' },
      { value: 'employees', text: 'Personale' },
      { value: 'partners', text: 'Samarbeidspartnere' },
      { value: 'contacts', text: 'Kontaktpersoner' },
      { value: 'functions', text: 'Ansvarsområder' },
      { value: 'assets', text: 'Utstyrsenheter' },
      { value: 'substances', text: 'Stoffer' },
      { value: 'riskAssessments', text: 'Risikovurderinger' },
      { value: 'issues', text: 'Avvik' },
    ];
  }

  private get filteredItemsCount() {
    return (
      this.entityView.events.length +
      this.entityView.reports.length +
      this.entityView.tasks.length +
      this.entityView.guidelines.length +
      this.entityView.documents.length +
      this.entityView.constitutionalDocuments.length +
      this.entityView.contracts.length +
      this.entityView.functions.length +
      this.entityView.assets.length +
      this.entityView.substances.length +
      this.entityView.riskAssessments.length +
      this.entityView.issues.length +
      this.entityView.employees.length +
      this.entityView.partners.length +
      this.entityView.contacts.length
    );
  }

  private get uncollected() {
    return this.entityView.collectibles.filter((item) => {
      let uuid = item.uuid;
      if (item.interviewEmployee) {
        uuid = item.uuid + '?employee=' + item.interviewEmployee;
      }
      return !this.collectedIds.includes(uuid);
    });
  }

  private get searchResults(): CollectionEntity[] {
    if (this.docTypeFilter === 'all') {
      if (this.searchString === '') {
        return [];
      }
      return this.uncollected.filter((item) => {
        return item.name.toUpperCase().includes(this.searchString.toUpperCase());
      });
    }
    if (this.searchString === '') {
      return this.uncollected.filter((item) => {
        return item.type === this.docTypeFilter;
      });
    }
    return this.uncollected.filter((item) => {
      return item.type === this.docTypeFilter && item.name.toUpperCase().includes(this.searchString.toUpperCase());
    });
  }

  private get suggestionsCount(): number {
    let count = this.entityView.suggestionsAsListItems.length;
    this.entityView.suggestionsAsListItems.forEach((item) => {
      if (item.items) {
        count += item.items.length;
      }
    });
    return count;
  }

  hideEdit() {
    return this.entityView.editRestricted;
  }

  renderHelpSection() {
    if (this._normalMode(this.mode)) {
      return super.renderHelpSection();
    }
    return html``;
  }

  async initializeEditItem() {
    this.editItem = {
      name: this.entityView.name,
      description: this.entityView.description,
      pages: this.entityView.pages,
      items: this.entityView.items,
    };
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  addItems(entities: CollectionEntity[]) {
    const items: CollectionEntity[] = getItemsAsCollectionEntities(this.entityView.items, this.entityView.collectibles);
    entities.forEach((e) => {
      items.push(e);
    });
    const itemsToSave: CollectionItem[] = items.map((i) => {
      let interviewEmployee: string | undefined = undefined;
      if (i.templateId === employeeInterviewTemplateId) {
        interviewEmployee = i.assigneeUuids[0];
      }
      return { uuid: i.uuid, type: i.type, interviewEmployee, createEvent: i.createEvent };
    });
    this.saveItems(itemsToSave);
  }

  saveItems(items: CollectionItem[]) {
    this.dispatchEvent(
      new CustomEvent('done', {
        bubbles: true,
        composed: true,
        detail: {
          editItem: {
            name: this.entityView.name,
            helpContent: this.entityView.helpContent,
            description: this.entityView.description,
            pages: this.entityView.pages,
            items: items,
            editRestricted: this.entityView.editRestricted,
          },
        },
      }),
    );
  }

  searchResultDocTypeStyle(docType: string) {
    return 'background-image: url(/images/' + docType.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase()) + '-gray.svg)';
  }

  renderViewFilter() {
    return html` <d-edit-timespan
        class="minWidth300"
        .start=${this.entityView.filterStartDate}
        .end=${this.entityView.filterEndDate}
        .text=${this.entityView.filterPeriodDisplayText}
        @value-changed=${(e) => this.filterTimespanChanged(e)}
      ></d-edit-timespan>
      <d-select-dropdown
        controller
        class="minWidth300"
        label="Deltakere"
        inline-label
        .options=${this.entityView.filterByAssignedOptions}
        .value=${this.filterAssigneeSelectValue}
        @value-changed=${(e) => this.filterAssigneeChanged(e)}
      ></d-select-dropdown>`;
  }

  renderNoItemsText() {
    return html` <d-section>
      <div class="fullWidth" style="border-bottom-width: 0"><i>Ingen elementer</i></div>
    </d-section>`;
  }

  renderSuggestions() {
    if (this.entityView.suggestionsAsListItems.length) {
      const items: { listItem: ListSectionItemInput; subitems: ListSectionItemInput[] }[] =
        this.entityView.suggestionsAsListItems.map((item) => {
          item.clickHandler = () => this.onSuggestionClick(item);
          let subitems: ListSectionItemInput[] = [];
          if (item.items) {
            subitems = item.items.map((subitem) => {
              subitem.clickHandler = () => this.onSuggestionEventClick(subitem);
              return subitem;
            });
          }
          return { listItem: { ...item, items: undefined }, subitems };
        });
      return html`
        <div class="suggestions-section" ?open="${this.showSuggestions}" @mousemove=${(e) => this.getCursorPosition(e)}>
          <div
            class="suggestions-header"
            style="top: ${this.contentStickyTop - 2}px"
            @click=${() => (this.showSuggestions = !this.showSuggestions)}
          >
            ${this.suggestionsCount ? html` ${this.suggestionsCount} forslag til innhold ` : nothing}
            ${this.showSuggestions ? html` <d-closer style="top: 0; right: -10px"></d-closer> ` : nothing}
          </div>
          <d-smooth-resize>
            ${this.showSuggestions
              ? html` ${items.map((item) => {
                  return html`
                    <d-list-section-item
                      style="position: relative"
                      bordered
                      .input=${item.listItem}
                      @mouseenter=${() => this.setSuggestionTooltip(item.listItem, true)}
                      @mouseleave=${() => this.unsetSuggestionTooltip()}
                    ></d-list-section-item>
                    ${item.subitems.length
                      ? html` <div class="subitems">
                          ${item.subitems.map((subitem) => {
                            return html` <d-list-section-item
                              bordered
                              .input=${subitem}
                              @mouseenter=${() => this.setSuggestionTooltip(item.listItem, false)}
                              @mouseleave=${() => this.unsetSuggestionTooltip()}
                            ></d-list-section-item>`;
                          })}
                        </div>`
                      : nothing}
                  `;
                })}`
              : nothing}
          </d-smooth-resize>
          <div id="tooltip" class="${this.tooltipClass}" popover>
            <div>
              <div>${this.tooltipContent}</div>
            </div>
          </div>
        </div>
      `;
    }
    return html``;
  }

  renderSearch() {
    return html`
      <div class="search-section">
        <div class="search-header" style="top: ${this.contentStickyTop - 2}px">
          <d-edit-text
            type="search"
            system-content
            theme-page
            placeholder="Søk etter TrinnVis-elementer å legge til"
            style="flex:2; min-width: 300px"
            .value=${this.searchString}
            @value-changed=${(e) => {
              e.stopPropagation();
              this.searchString = e.detail.value;
            }}
          ></d-edit-text>
          <d-select-dropdown
            style="flex:1; min-width: 200px"
            system-content
            theme-page
            .options=${this.docTypeOptions}
            .value=${this.docTypeFilter}
            @value-changed=${(e) => {
              e.stopPropagation();
              this.docTypeFilter = e.detail.value;
            }}
          ></d-select-dropdown>
        </div>
        <d-smooth-resize>
          <div class="documents">
            ${this.searchResults.map((item) => {
              return html` <div
                class="${item.classes}"
                style="${this.searchResultDocTypeStyle(item.type)}"
                @click=${() => this.addToCollection(item.uuid)}
              >
                ${item.dateTimeDisplay ? item.dateTimeDisplay + ' ' + item.name : item.name}
              </div>`;
            })}
          </div>
        </d-smooth-resize>
      </div>
    `;
  }

  renderViewMode() {
    return html` ${this.renderFieldSection('collections', this.viewFields(), this.entityView)} `;
  }

  renderEditItem(item: CollectionViewEditItem): TemplateResult<1> {
    return html`${this.renderFieldSection('collections', this.editFields(), item)} `;
  }

  renderRevisionItems() {
    return html``;
  }

  hidePdf(): boolean {
    return true;
  }

  mainHeaderMoreActions(): ActionInput[] {
    return [
      ...super.mainHeaderMoreActions(),
      ...(this.mode === 'normal'
        ? [
            {
              name: 'Lag en pdf av dokumentet',
              action: 'new_pdf',
            },
          ]
        : []),
    ];
  }

  protected renderEditSelectPages(item: any): TemplateResult<1> {
    if (this.entityView.editRestricted) {
      return html``;
    }
    return super.renderEditSelectPages(item);
  }

  protected handleAction(action: string) {
    if (action === 'new_pdf') {
      const view: CollectionPdf = {
        ...this.entityView,
        events: this.entityView.events.map((item) => {
          return {
            checkedClass: item.secondaryLabel !== '' ? 'checked' : '',
            name: item.label ?? '',
            assignedTo: item.rightLabel,
            doneText: item.secondaryLabel ?? '',
            notes: item.notes ?? '',
          };
        }),
        reports: this.entityView.reports.map((x) => ({ name: x.label ?? '' })),
        tasks: this.entityView.reports.map((x) => ({ name: x.label ?? '' })),
        guidelines: this.entityView.reports.map((x) => ({ name: x.label ?? '' })),
        documents: this.entityView.reports.map((x) => ({ name: x.label ?? '' })),
        constitutionalDocuments: this.entityView.reports.map((x) => ({ name: x.label ?? '' })),
        contracts: this.entityView.reports.map((x) => ({ name: x.label ?? '' })),
        functions: this.entityView.reports.map((x) => ({ name: x.label ?? '' })),
        assets: this.entityView.reports.map((x) => ({ name: x.label ?? '' })),
        substances: this.entityView.reports.map((x) => ({ name: x.label ?? '' })),
        riskAssessments: this.entityView.reports.map((x) => ({ name: x.label ?? '' })),
        issues: this.entityView.reports.map((x) => ({ name: x.label ?? '' })),
        employees: this.entityView.reports.map((x) => ({ name: x.label ?? '' })),
        partners: this.entityView.reports.map((x) => ({ name: x.label ?? '' })),
        contacts: this.entityView.reports.map((x) => ({ name: x.label ?? '' })),
      };

      createTopicPdf(view).download();
    }
  }

  protected onRemoveListItem(detail: ListSectionItemInput) {
    if (detail.href && detail.label) {
      const hrefParts = detail.href.split('/');
      const uuid = hrefParts[hrefParts.length - 1];
      const entity = this.entityView.collectibles.find((c) => {
        return c.uuid === uuid;
      });
      if (entity) {
        if (entity.type === 'events' || entity.type === 'meetings') {
          const sameNameEvents = this.entityView.collectibles.filter((item) => {
            return (
              item.type === entity.type &&
              item.name === entity.name &&
              item.interviewEmployee === entity.interviewEmployee &&
              this.collectedIds.includes(item.uuid)
            );
          });
          if (sameNameEvents.length > 1) {
            return this.recurringEventsDialog(entity, sameNameEvents, true);
          } else {
            this.removeFromCollection([entity]);
          }
        } else {
          this.removeFromCollection([entity]);
        }
      }
    }
  }

  protected viewFields(): SectionField[] {
    return [
      {
        field: 'description',
        type: 'view-text',
        class: 'fullWidth',
        condition: !isEmptyOrInvalidString(this.entityView.description),
      },
      {
        field: 'filterPeriodDisplayText',
        type: 'view-text',
        class: 'minWidth200',
        condition: false,
      },
      {
        field: 'filterPeopleDisplayText',
        type: 'view-text',
        class: 'minWidth200',
        condition: false,
      },
      {
        type: 'custom',
        render: () => this.renderViewFilter(),
      },
    ];
  }

  protected lists(): ListDefinition[] {
    return [
      {
        field: 'collections_events',
        icon: 'events',
        items: 'events',
        deleteButton: true,
        condition: this.entityView.events.length > 0,
      },
      {
        field: 'organization_reports',
        icon: 'reports',
        items: 'reports',
        deleteButton: true,
        condition: this.entityView.reports.length > 0,
      },
      {
        field: 'organization_tasks',
        icon: 'tasks',
        items: 'tasks',
        deleteButton: true,
        condition: this.entityView.tasks.length > 0,
      },
      {
        field: 'organization_guidelines',
        icon: 'guidelines',
        items: 'guidelines',
        deleteButton: true,
        condition: this.entityView.guidelines.length > 0,
      },
      {
        field: 'organization_documents',
        icon: 'documents',
        items: 'documents',
        deleteButton: true,
        condition: this.entityView.documents.length > 0,
      },
      {
        field: 'organization_constitutionalDocuments',
        icon: 'constitutionalDocuments',
        items: 'constitutionalDocuments',
        condition: this.entityView.constitutionalDocuments.length > 0,
      },
      {
        field: 'organization_contracts',
        icon: 'contracts',
        items: 'contracts',
        deleteButton: true,
        condition: this.entityView.contracts.length > 0,
      },
      {
        field: 'organization_functions',
        icon: 'functions',
        items: 'functions',
        deleteButton: true,
        condition: this.entityView.functions.length > 0,
      },
      {
        field: 'organization_assets',
        icon: 'assets',
        items: 'assets',
        deleteButton: true,
        condition: this.entityView.assets.length > 0,
      },
      {
        field: 'organization_substances',
        icon: 'substances',
        items: 'substances',
        deleteButton: true,
        condition: this.entityView.substances.length > 0,
      },
      {
        field: 'organization_riskAssessments',
        icon: 'riskAssessments',
        items: 'riskAssessments',
        deleteButton: true,
        condition: this.entityView.riskAssessments.length > 0,
      },
      {
        field: 'organization_issues',
        icon: 'issues',
        items: 'issues',
        deleteButton: true,
        condition: this.entityView.issues.length > 0,
      },
      {
        field: 'organization_employees',
        icon: 'employees',
        items: 'employees',
        deleteButton: true,
        condition: this.entityView.employees.length > 0,
      },
      {
        field: 'organization_partners',
        icon: 'partners',
        items: 'partners',
        deleteButton: true,
        condition: this.entityView.partners.length > 0,
      },
      {
        field: 'organization_contacts',
        icon: 'contacts',
        items: 'contacts',
        deleteButton: true,
        condition: this.entityView.contacts.length > 0,
      },
      {
        type: 'custom',
        render: () => this.renderNoItemsText(),
        condition: this.filteredItemsCount === 0,
      },
      {
        type: 'custom',
        render: () => this.renderSuggestions(),
        condition: this.entityView.suggestionsAsListItems.length > 0,
      },
      {
        type: 'custom',
        render: () => this.renderSearch(),
        condition: !this.entityView.editRestricted,
      },
    ];
  }

  protected renderAttachments() {
    return html``;
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return undefined;
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
  }

  protected update(changedProperties: PropertyValues) {
    super.update(changedProperties);
    this.saveCollectionsFilter();
  }

  private async recurringEventsDialog(entity: CollectionEntity, sameNameEvents: CollectionEntity[], removing: boolean) {
    const result: RecurringEventInCollectionDialogResult = await RecurringEventInCollectionDialog.open({
      collectionName: this.entityView.name,
      eventsCount: sameNameEvents.length,
      isMeeting: entity.type === 'meetings',
      removing,
    });
    if (result.action === 'cancel') {
      return;
    }
    if (result.action === 'all') {
      if (removing) {
        this.removeFromCollection(sameNameEvents);
      } else {
        this.addItems(sameNameEvents);
      }
    } else if (result.action === 'single') {
      if (removing) {
        this.removeFromCollection([entity]);
      } else {
        this.addItems([entity]);
      }
    }
  }

  private addToCollection(uuid: string) {
    const entity = this.entityView.collectibles.find((c) => {
      return c.uuid === uuid;
    });
    if (entity) {
      if (entity.type === 'events' || entity.type === 'meetings') {
        const sameNameEvents = this.entityView.collectibles.filter((item) => {
          return (
            item.type === entity.type &&
            item.name === entity.name &&
            item.interviewEmployee === entity.interviewEmployee &&
            !this.collectedIds.includes(item.uuid)
          );
        });
        if (sameNameEvents.length > 1) {
          return this.recurringEventsDialog(entity, sameNameEvents, false);
        } else {
          this.addItems([entity]);
        }
      } else {
        this.addItems([entity]);
      }
    }
  }

  private removeFromCollection(entities: CollectionEntity[]) {
    const uuids = entities.map((e) => e.uuid);
    const itemsToSave: CollectionItem[] = this.entityView.items.filter((i) => {
      return !uuids.includes(i.uuid);
    });
    this.saveItems(itemsToSave);
  }

  private setFilter(startDate: string | undefined, endDate: string | undefined, assignees: string[] | undefined) {
    this.dispatchEvent(
      new CustomEvent('navigate', {
        composed: true,
        bubbles: true,
        detail: { href: this.entityView.href + getFilterParams(startDate, endDate, assignees) },
      }),
    );
  }

  private filterTimespanChanged(e) {
    e.stopPropagation();
    this.setFilter(e.detail.start, e.detail.end, this.filterAssignees);
  }

  private filterAssigneeChanged(e) {
    e.stopPropagation();
    this.filterAssignee = e.detail.value;
    this.setFilter(this.entityView.filterStartDate, this.entityView.filterEndDate, this.filterAssignees);
  }

  private createTaskEvent(taskUuid: string) {
    const id = uuid();
    const input: CreateEventOccurrenceInput = {
      entityType: 'eventOccurrences',
      entityUuid: id,
      targetUrl: this.entityView.href + '/eventOccurrences/' + id + '?edit',
      taskUuid,
    };
    this.dispatchEvent(
      new CustomEvent<CreateEntityInput>('create-entity', {
        bubbles: true,
        composed: true,
        detail: input,
      }),
    );
  }

  private createMeetingEvent(clickData) {
    if (this.entityView.currentUserHasWriteAccess) {
      const id = uuid();
      const input: CreateEntityInput = {
        entityType: 'meetingOccurrences',
        entityUuid: id,
        meetingUuid: clickData.uuid,
        employeeUuid: clickData.interviewEmployee,
        templateMeetingOccurrenceUuid: undefined,
        instance: LocalDate.now().toString(),
        targetUrl: this.entityView.href + '/meetingOccurrences/' + id + '?edit',
      };
      this.dispatchEvent(
        new CustomEvent<CreateEntityInput>('create-entity', {
          bubbles: true,
          composed: true,
          detail: input,
        }),
      );
    }
  }

  private onSuggestionClick(item: ListSectionItemInput) {
    if (item.clickData) {
      const clickData = JSON.parse(item.clickData);
      if (clickData.createEvent) {
        if (clickData.type === 'meetings') {
          this.createMeetingEvent(clickData);
        } else {
          this.createTaskEvent(clickData.uuid);
        }
      }
    }
  }

  private onSuggestionEventClick(item: ListSectionItemInput) {
    if (item.clickData) {
      const clickData = JSON.parse(item.clickData);
      return this.addToCollection(clickData.uuid);
    }
  }

  private suggestionTooltipTextCreate(clickData: CollectionSuggestionClickData) {
    let text = '';
    if (clickData.type === 'meetings') {
      if (clickData.interviewEmployee) {
        text += 'Lag ny samtale';
      } else {
        text += 'Lag nytt møte';
      }
    } else {
      text += 'Lag ny oppgave';
    }
    return text;
  }

  private suggestionTooltipTextAdd(clickData: CollectionSuggestionClickData) {
    let text = '';
    if (clickData.type === 'meetings') {
      if (clickData.interviewEmployee) {
        text += 'Inkluder denne samtalen';
      } else {
        text += 'Inkluder dette møtet';
      }
    } else {
      text += 'Inkluder denne oppgaven';
    }
    return text;
  }

  private setSuggestionTooltip(item: ListSectionItemInput, createEvent: boolean) {
    if (item.clickData && (item.accessible || !createEvent)) {
      const clickData: CollectionSuggestionClickData = JSON.parse(item.clickData);
      let classes: string;
      let text: string;
      if (createEvent) {
        classes = 'green';
        text = this.suggestionTooltipTextCreate(clickData);
      } else {
        classes = 'blue';
        text = this.suggestionTooltipTextAdd(clickData);
      }
      this.tooltipClass = classes;
      this.tooltipContent = text;
      this.tooltip.showPopover();
    }
  }

  private unsetSuggestionTooltip() {
    this.tooltipContent = '';
    this.tooltip.hidePopover();
  }

  private getCursorPosition(e) {
    this.tooltip.style.left = e.clientX + 'px';
    this.tooltip.style.top = e.clientY + 'px';
  }

  private editFields(): SectionField[] {
    return [
      {
        field: 'name',
        type: 'edit-text',
        placeholder: 'Dette feltet må fylles ut',
        autofocus: !this.entityView.isConfirmedEntity,
        markIfEmpty: true,
        class: 'fullWidth',
      },
      {
        field: 'description',
        type: 'edit-textarea',
        class: 'fullWidth',
      },
    ];
  }

  private saveCollectionsFilter() {
    let filterChanged = false;
    const newCollectionFilter: CollectionFilter = {
      uuid: this.entityView.uuid,
      startDate: this.entityView.filterStartDate ?? '',
      endDate: this.entityView.filterEndDate ?? '',
      people: this.entityView.filterPeople ?? [],
    };
    let newAllCollectionsFilter: CollectionFilter[] = [...this.entityView.allCollectionsFilter];
    const currentFilter = this.entityView.allCollectionsFilter.find((c) => c.uuid === this.entityView.uuid);
    if (currentFilter) {
      if (
        currentFilter.startDate !== this.entityView.filterStartDate ||
        currentFilter.endDate !== this.entityView.filterEndDate ||
        !_.isEqual(currentFilter.people, this.entityView.filterPeople)
      ) {
        filterChanged = true;
        newAllCollectionsFilter = this.entityView.allCollectionsFilter.map((c) => {
          if (c.uuid === this.entityView.uuid) {
            return newCollectionFilter;
          }
          return c;
        });
      }
    } else {
      filterChanged = true;
      newAllCollectionsFilter.push(newCollectionFilter);
    }
    if (filterChanged) {
      this.dispatchEvent(
        new CustomEvent('update-collections-filter', {
          composed: true,
          bubbles: true,
          detail: newAllCollectionsFilter,
        }),
      );
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-collection-view': DCollectionView;
  }
}
