/**
 * Temporary API methods not connected to the redux store. Replaces all iron-ajax use in dabih
 *
 *
 */

import {
  AccountsApi,
  AssetsApi,
  AttachmentViewModel,
  ComputersApi,
  Configuration,
  ConstitutionalDocumentsApi,
  ContractsApi,
  CreateUserRequest,
  CreateUserResponse,
  CriiptoTokenLoginRequest,
  CriiptoTokenLoginResponse,
  DocumentsApi,
  EmployeesApi,
  EventOccurrencesApi,
  FunctionsApi,
  GuidelinesApi,
  HelseIDTokenLoginRequest,
  IssuesApi,
  LinkAuthResult,
  LinkAuthUpdateMessage,
  MeetingOccurrencesApi,
  MeetingsApi,
  MessageViewModel,
  OrganizationApi,
  PartnersApi,
  RefreshTokenRequest,
  RefreshTokenResponse,
  ReportsApi,
  RevisionViewModel,
  RiskAssessmentsApi,
  SaveDraftCommand,
  SearchResultHit,
  SubstancesApi,
  TopicsApi,
} from './api';
import pDebounce from 'p-debounce';
import { BASE_PATH } from './config.js';
import { Buffer } from 'buffer/index.js';
import { runningUnderNativePlatform } from 'src/store/running-under-native-platform';
import { getValidAccessToken } from 'src/store/oauth-browser';
import { fetchQueue, createQueue } from 'fetch-queue';
export function decodeToken(token: string): string {
  return Buffer.from(token, 'base64').toString();
}

createQueue('apiClientQueue', {
  concurrent: 1,
});

const apiClientFetchQueue = (url, fetchOptions) => {
  try {
    return fetchQueue(url, fetchOptions, 'apiClientQueue');
  } catch (e) {
    console.error('Api Client Fetch Queue', e);
    throw e;
  }
};

export function getBasePath() {
  if (runningUnderNativePlatform()) {
    console.log('Native Platform backend');
    return 'https://app.trinnvis.no/api';
  }
  return '/api';
}

export class ApiClient {
  get accounts(): AccountsApi {
    return new AccountsApi(this.config);
  }
  get organization(): OrganizationApi {
    return new OrganizationApi(this.config);
  }
  get eventOccurrences(): EventOccurrencesApi {
    return new EventOccurrencesApi(this.config);
  }

  get meetingOccurrences(): MeetingOccurrencesApi {
    return new MeetingOccurrencesApi(this.config);
  }
  get computers(): ComputersApi {
    return new ComputersApi(this.config);
  }

  get functions(): FunctionsApi {
    return new FunctionsApi(this.config);
  }

  get topics(): TopicsApi {
    return new TopicsApi(this.config);
  }

  get documents(): DocumentsApi {
    return new DocumentsApi(this.config);
  }

  get meetings(): MeetingsApi {
    return new MeetingsApi(this.config);
  }

  get guidelines(): GuidelinesApi {
    return new GuidelinesApi(this.config);
  }
  get issues(): IssuesApi {
    return new IssuesApi(this.config);
  }

  get assets(): AssetsApi {
    return new AssetsApi(this.config);
  }
  get reports(): ReportsApi {
    return new ReportsApi(this.config);
  }
  get riskAssessments(): RiskAssessmentsApi {
    return new RiskAssessmentsApi(this.config);
  }

  get partners(): PartnersApi {
    return new PartnersApi(this.config);
  }

  get contracts(): ContractsApi {
    return new ContractsApi(this.config);
  }
  get constitutionalDocuments(): ConstitutionalDocumentsApi {
    return new ConstitutionalDocumentsApi(this.config);
  }
  get employees(): EmployeesApi {
    return new EmployeesApi(this.config);
  }
  get substances(): SubstancesApi {
    return new SubstancesApi(this.config);
  }

  get config(): Configuration {
    // Token auth used from admin.
    const token = localStorage.getItem('dabih-token');
    if (token) {
      return this.configWithToken;
    }

    return this.configWithAccessToken;
  }

  private get configWithAccessToken(): Configuration {
    return new Configuration({
      basePath: getBasePath(),
      accessToken: () => getValidAccessToken(),
      fetchApi: apiClientFetchQueue,
      headers: {
        'X-Requested-With': __APP_VERSION__,
      },
    });
  }

  private get configWithToken(): Configuration {
    const token = localStorage.getItem('dabih-token');
    let username: string | undefined = undefined;
    let password: string | undefined = undefined;
    if (token) {
      const strings = decodeToken(token).split(':');
      username = strings[0];
      password = strings[1];
    }

    return new Configuration({
      basePath: getBasePath(),
      username: username,
      password: password,
      fetchApi: apiClientFetchQueue,
      headers: {
        'X-Requested-With': __APP_VERSION__,
      },
    });
  }
}

export function createClient(): ApiClient {
  return new ApiClient();
}

// Cannot use apiclient since there is no token available
export async function createUser(requestParameters: CreateUserRequest): Promise<CreateUserResponse> {
  const config = new Configuration({ fetchApi: fetch });
  const api = new AccountsApi(config);

  return api.createUser(requestParameters);
}

export async function criiptoLogin(requestParameters: CriiptoTokenLoginRequest): Promise<CriiptoTokenLoginResponse> {
  const config = new Configuration({ fetchApi: fetch });
  const api = new AccountsApi(config);

  return api.criiptoTokenLogin(requestParameters);
}

export async function helseIDLogin(requestParameters: HelseIDTokenLoginRequest): Promise<CriiptoTokenLoginResponse> {
  const config = new Configuration({ fetchApi: fetch });
  const api = new AccountsApi(config);

  return api.helseIDTokenLogin(requestParameters);
}

export async function refreshToken(requestParameters: RefreshTokenRequest): Promise<RefreshTokenResponse> {
  const config = new Configuration({ fetchApi: fetch });
  const api = new AccountsApi(config);

  return api.refreshToken(requestParameters);
}

export function sendPassword(username: string): Promise<MessageViewModel> {
  const config = new Configuration({ fetchApi: fetch });
  const api = new AccountsApi(config);

  return api.requestPassword({ userEmail: username });
}

export function checkEmail(username: string): Promise<MessageViewModel> {
  console.log('backend');
  const config = new Configuration({ fetchApi: fetch });
  const api = new AccountsApi(config);

  return api.fetchUserStatus({ accountEmail: username });
}

export function linkAuth(token: string, message: LinkAuthUpdateMessage): Promise<LinkAuthResult> {
  const strings = decodeToken(token).split(':');
  const username = strings[0];
  const password = strings[1];
  const config = new Configuration({
    basePath: getBasePath(),
    username: username,
    password: password,
    fetchApi: fetch,
  });
  const api = new AccountsApi(config);

  return api.startLinkAuthentication({
    linkAuthUpdateMessage: message,
  });
}

function assetAttachment(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  return api.assets.fetchAttachmentForAsset({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function constitutionalDocumentAttachment(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  return api.constitutionalDocuments.fetchAttachmentForConstitutionalDocument({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function contractAttachment(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  return api.contracts.fetchAttachmentForContract({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}

function reportSigningAttachment(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  signingOrderId: string,
): Promise<AttachmentViewModel> {
  return api.reports.reportSigningAttachment({
    entityId: entityId,
    organizationId: organizationId.toString(),
    signingOrderId: signingOrderId,
  });
}

function contractSigningAttachment(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  signingOrderId: string,
): Promise<AttachmentViewModel> {
  return api.contracts.getSigningAttachment({
    entityId: entityId,
    organizationId: organizationId.toString(),
    signingOrderId: signingOrderId,
  });
}
function documentAttachment(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  return api.documents.fetchAttachmentForDocument({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function employeeAttachment(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  return api.employees.fetchAttachmentForEmployee({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}

function eventOccurrenceAttachment(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  return api.eventOccurrences.fetchAttachmentForEventOccurrence({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function meetingOccurrenceAttachment(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  return api.meetingOccurrences.fetchAttachmentForMeetingOccurrence({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function functionAttachment(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  return api.functions.fetchAttachmentForFunction({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function guidelineAttachment(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  return api.guidelines.fetchAttachmentForGuideline({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function issueAttachment(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  return api.issues.fetchAttachmentForIssue({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function meetingAttachment(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  return api.meetings.fetchAttachmentForMeeting({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function partnerAttachment(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  return api.partners.fetchAttachmentForPartner({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function contactAttachment(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  attachmentId: string,
  partnerId: string,
): Promise<AttachmentViewModel> {
  return api.partners.fetchAttachmentForPartnerContact({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
    partnerId: partnerId,
  });
}
function reportAttachment(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  return api.reports.fetchAttachmentForReport({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function riskAssessmentAttachment(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  return api.riskAssessments.fetchAttachmentForRiskAssessment({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function taskAttachment(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  attachmentId: string,
  functionId: string,
): Promise<AttachmentViewModel> {
  return api.functions.fetchAttachmentForFunctionTask({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
    functionId: functionId,
  });
}
function externalConnectionAttachment(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  return api.computers.fetchAttachmentForExternalConnection({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function computerAttachment(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  return api.computers.fetchAttachmentForComputer({
    entityId: entityId,
    organizationId: organizationId.toString(),
    fileNameOrAttachmentUuid: attachmentId,
  });
}

function substanceAttachment(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  return api.substances.fetchAttachmentForSubstance({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}

export function attachment(
  api: ApiClient,
  entityType: string,
  entityId: string,
  organizationId: number,
  attachmentId: string,
  parentId?: string,
): Promise<AttachmentViewModel> {
  if (entityId !== 'new') {
    switch (entityType) {
      case 'assets':
        return assetAttachment(api, entityId, organizationId, attachmentId);
      case 'constitutionalDocuments':
        return constitutionalDocumentAttachment(api, entityId, organizationId, attachmentId);
      case 'substances':
        return substanceAttachment(api, entityId, organizationId, attachmentId);
      case 'contracts':
        return contractAttachment(api, entityId, organizationId, attachmentId);
      case 'documents':
        return documentAttachment(api, entityId, organizationId, attachmentId);
      case 'employees':
        return employeeAttachment(api, entityId, organizationId, attachmentId);
      case 'eventOccurrences':
        return eventOccurrenceAttachment(api, entityId, organizationId, attachmentId);
      case 'meetingOccurrences':
        return meetingOccurrenceAttachment(api, entityId, organizationId, attachmentId);
      case 'functions':
        return functionAttachment(api, entityId, organizationId, attachmentId);
      case 'guidelines':
        return guidelineAttachment(api, entityId, organizationId, attachmentId);
      case 'issues':
        return issueAttachment(api, entityId, organizationId, attachmentId);
      case 'meetings':
        return meetingAttachment(api, entityId, organizationId, attachmentId);
      case 'partners':
        return partnerAttachment(api, entityId, organizationId, attachmentId);
      case 'contacts':
        return contactAttachment(api, entityId, organizationId, attachmentId, parentId ?? '');
      case 'reports':
        return reportAttachment(api, entityId, organizationId, attachmentId);
      case 'riskAssessments':
        return riskAssessmentAttachment(api, entityId, organizationId, attachmentId);
      case 'tasks':
        return taskAttachment(api, entityId, organizationId, attachmentId, parentId ?? '');
      case 'externalConnections':
        return externalConnectionAttachment(api, entityId, organizationId, attachmentId);
      case 'computers':
        return computerAttachment(api, entityId, organizationId, attachmentId);
    }
  }

  throw new Error('Illegal state (E541), unexpected attachment entity type ' + entityType);
}

export function signingAttachment(
  api: ApiClient,
  entityType: string,
  entityId: string,
  organizationId: number,
  signingOrderId: string,
): Promise<AttachmentViewModel> {
  if (entityId !== 'new' && entityType === 'contracts') {
    return contractSigningAttachment(api, entityId, organizationId, signingOrderId);
  }
  if (entityId !== 'new' && entityType === 'reports') {
    return reportSigningAttachment(api, entityId, organizationId, signingOrderId);
  }

  throw new Error('Illegal state (E547), unexpected signing attachment entity type ' + entityType);
}

function contractRevisions(api: ApiClient, entityId: string, organizationId: number): Promise<RevisionViewModel[]> {
  return api.contracts.listRevisionsForContract({
    contractId: entityId,
    organizationId: organizationId.toString(),
  });
}

function constitutionalDocumentRevisions(
  api: ApiClient,
  entityId: string,
  organizationId: number,
): Promise<RevisionViewModel[]> {
  return api.constitutionalDocuments.listRevisionsForConstitutionalDocument({
    constitutionalDocumentId: entityId,
    organizationId: organizationId.toString(),
  });
}

function documentRevisions(api: ApiClient, entityId: string, organizationId: number): Promise<RevisionViewModel[]> {
  return api.documents.listRevisionsForDocument({
    documentId: entityId,
    organizationId: organizationId.toString(),
  });
}

function functionRevisions(api: ApiClient, entityId: string, organizationId: number): Promise<RevisionViewModel[]> {
  return api.functions.listRevisionsForFunction({
    functionId: entityId,
    organizationId: organizationId.toString(),
  });
}

function guidelineRevisions(api: ApiClient, entityId: string, organizationId: number): Promise<RevisionViewModel[]> {
  return api.guidelines.listRevisionsForGuideline({
    guidelineId: entityId,
    organizationId: organizationId.toString(),
  });
}

function reportRevisions(api: ApiClient, entityId: string, organizationId: number): Promise<RevisionViewModel[]> {
  return api.reports.listRevisionsForReport({
    reportId: entityId,
    organizationId: organizationId.toString(),
  });
}

function meetingRevisions(api: ApiClient, entityId: string, organizationId: number): Promise<RevisionViewModel[]> {
  return api.meetings.listRevisionsForMeeting({
    meetingId: entityId,
    organizationId: organizationId.toString(),
  });
}

function riskAssessmentRevisions(
  api: ApiClient,
  entityId: string,
  organizationId: number,
): Promise<RevisionViewModel[]> {
  return api.riskAssessments.listRevisionsForRiskAssessment({
    riskAssessmentId: entityId,
    organizationId: organizationId.toString(),
  });
}

function taskRevisions(
  api: ApiClient,
  entityId: string,
  organizationId: number,
  parentEntityId: string,
): Promise<RevisionViewModel[]> {
  return api.functions.listRevisionsForFunctionTask({
    functionId: parentEntityId,
    taskId: entityId,
    organizationId: organizationId.toString(),
  });
}

const supportedEntityTypesForDraft = [
  'assets',
  'constitutionalDocuments',
  'contracts',
  'employees',
  'documents',
  'functions',
  'guidelines',
  'issues',
  'meetings',
  'partners',
  'contacts',
  'reports',
  'riskAssessments',
  'substances',
  'tasks',
  'eventOccurrences',
  'meetingOccurrences',
];

export function draft(
  api: ApiClient,

  entityType: string,
  entityId: string,
  organizationId: number,
): Promise<SaveDraftCommand> {
  if (entityId !== 'new' && supportedEntityTypesForDraft.includes(entityType)) {
    return api.organization.fetchDraft({
      entityType: entityType,
      entityId: entityId,
      organizationId: organizationId.toString(),
    });
  }

  return Promise.reject(new Error('Entity type ' + entityType + ' not supported'));
}

export function revisions(
  api: ApiClient,
  entityType: string,
  entityId: string,
  organizationId: number,
  parentEntityId?: string,
): Promise<RevisionViewModel[]> {
  if (entityId !== 'new') {
    switch (entityType) {
      case 'constitutionalDocuments':
        return constitutionalDocumentRevisions(api, entityId, organizationId);
      case 'contracts':
        return contractRevisions(api, entityId, organizationId);
      case 'documents':
        return documentRevisions(api, entityId, organizationId);
      case 'functions':
        return functionRevisions(api, entityId, organizationId);
      case 'guidelines':
        return guidelineRevisions(api, entityId, organizationId);
      case 'reports':
        return reportRevisions(api, entityId, organizationId);
      case 'meetings':
        return meetingRevisions(api, entityId, organizationId);
      case 'riskAssessments':
        return riskAssessmentRevisions(api, entityId, organizationId);
      case 'tasks':
        if (parentEntityId === undefined) {
          throw new Error('Illegal state (E601), arguments for task revisions, parent missing');
        }
        return taskRevisions(api, entityId, organizationId, parentEntityId);
    }
  }

  return Promise.resolve([]);
}

const debounceSearch = pDebounce((api: ApiClient, query: string, organizationId: string) => {
  return search(api, query, organizationId);
}, 300);
export function debouncedSearch(api: ApiClient, query?: string, organizationId?: string): Promise<SearchResultHit[]> {
  if (query === undefined || organizationId === undefined) {
    return Promise.resolve([]);
  }
  if (query.length < 3) {
    return Promise.resolve([]);
  }
  return debounceSearch(api, query, organizationId);
}

export async function search(api: ApiClient, query: string, organizationId: string): Promise<SearchResultHit[]> {
  return api.organization.searchOrganization({
    query: query,
    limit: 200,
    organizationId: organizationId,
  });
}

export function saveDraft(
  token: string,
  organizationId: string,
  entityType: string,
  entityId: string,
  properties: Record<string, unknown>,
) {
  fetch(BASE_PATH + '/organizations/' + organizationId + '/drafts/' + entityType + '/' + entityId, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + token,
    },
    method: 'PUT',
    body: JSON.stringify(properties),
  })
    .then(function () {
      console.log('Saved draft', organizationId, entityType, entityId);
    })
    .catch(function (res) {
      console.log(res);
    });
}
