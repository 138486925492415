import { css, html, LitElement, PropertyValues } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import 'src/library/components/d-calendar-year';
import { endYear } from 'src/store';
import { Occurrence } from 'src/library/lists/d-occurrences-list';

@customElement('d-calendar')
export class DCalendar extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      max-width: 724px;
      margin: 0 1px;
    }
    :host * {
      box-sizing: border-box;
      line-height: 120%;
    }
    .year {
      border-top: 1px solid var(--borderColor);
    }
    .year:not(.prev) {
      margin-top: -1px;
    }
    .header {
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid var(--borderColor);
      padding: 12px 0;
    }
    .prev-year {
      margin-right: 6px;
      font-size: 15px;
      color: var(--themeColor);
      cursor: pointer;
    }
    .prev-year:hover {
      color: black;
    }
    .label {
      margin-right: 2px;
      font-weight: bold;
    }
    .sublabel {
      font-weight: normal;
    }
    .year-name {
      padding-bottom: 0.8em;
      text-align: center;
      font-size: 1.4em;
    }
  `;
  @property({ type: Number })
  currentYear = 2024;
  @property({ type: Array })
  years: number[] = [];
  @property({ type: Array })
  prevYears: number[] = [];
  @property({ type: Array })
  occurrences: Occurrence[] = [];
  @property({ type: String })
  eventTerm = 'hendelse';
  @property({ type: String })
  addText = '';
  @property({ type: String })
  baseHref = '';
  @property({ type: String })
  meetingUuid = '';
  @property({ type: String })
  interviewedEmployeeUuid = '';
  @property({ type: Number })
  contentStickyTop = 0;

  setYears(year: number) {
    const years: number[] = [];
    for (let y = year; y <= endYear; y++) {
      years.push(y);
    }
    this.years = years;
    const prevYears: number[] = [];
    year = year - 1;
    for (let i = 0; i < 5; i++) {
      prevYears.push(year - i);
    }
    this.prevYears = prevYears;
  }

  yearOccurrences(year: string | number): Occurrence[] {
    return this.occurrences.filter((e) => {
      return e.dateTime.split('-')[0] === year + '';
    });
  }

  render() {
    return html`
      <div class="header">
        ${this.prevYears.map((year) => {
          return html` <div class="prev-year" @click=${() => this.setYears(year)}>
          <span class="label">${year}</span><span class="sublabel">${this.yearOccurrences(year).length}</span>
        </div>
        </div>`;
        })}
      </div>
      ${this.years.map((year) => {
        return html` <d-calendar-year
          .eventTerm=${this.eventTerm}
          .contentStickyTop=${this.contentStickyTop}
          .year=${year}
          .occurrences=${this.yearOccurrences(year)}
          .baseHref=${this.baseHref}
          .addText=${this.addText}
          .meetingUuid=${this.meetingUuid}
          .interviewedEmployeeUuid=${this.interviewedEmployeeUuid}
        ></d-calendar-year>`;
      })}
    `;
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    this.setYears(this.currentYear);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-calendar': DCalendar;
  }
}
