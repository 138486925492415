import { customElement } from 'lit/decorators.js';
import { css, html, LitElement } from 'lit';

@customElement('d-meet-footer')
export class DMeetFooter extends LitElement {
  static readonly styles = css`
    :host {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 4px;
      padding-bottom: 4px;
      position: relative;
      height: 40px;
      background-color: silver;
    }

    div {
      display: flex;
      align-items: center;
    }

    div.left {
      position: absolute;
      left: 10px;
      gap: 8px;
    }
    div.right {
      position: absolute;
      right: 10px;
      gap: 8px;
    }
    div.center {
      width: 100%;
      justify-content: center;
      gap: 8px;
    }
  `;

  render() {
    return html`<div class="left"><slot name="left"></slot></div>
      <div class="center"><slot name="center"></slot></div>
      <div class="right"><slot name="right"></slot></div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-meet-footer': DMeetFooter;
  }
}
