import { css, html, LitElement, nothing } from 'lit';
import './d-login.js';
import { customElement, property } from 'lit/decorators.js';

/**
 *
 * @fires request-login submit login request. (fra d-login)
 * @fires request-password submit password request (fra d-login)
 *
 * STATUS OK
 */
@customElement('d-signin')
export class DSignin extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      background: var(--outskirts-background-color);
      padding-top: var(--safe-area-inset-top);
    }
  `;
  /**
   * Vis feilmelding for innlogging feilet.
   */
  @property({ type: Boolean })
  loginFailed = false;
  @property({ type: Boolean })
  showExpired = false;
  /**
   * The mode. Use ??? for linking ssecure login methods
   */
  @property({ type: String })
  mode = '';

  /**
   * The location search. Set this to value of `window.location.search` when mode is `link`.
   */
  @property({ type: String })
  locationSearch = '';

  render() {
    return html`
      <d-login .loginFailed=${this.loginFailed} .mode=${this.mode} .locationSearch=${this.locationSearch}
        ><slot slot="usernameInput" name="usernameInput"></slot> <slot slot="passwordInput" name="passwordInput"></slot
      ></d-login>
      ${this.showExpired ? html`<div>Du er nå logget ut.</div>` : nothing}
    `;
  }

  protected willUpdate() {
    this.checkForShowExpired();
  }

  /**
   * Checks for show upload parameter in url and set the property and removes the parameter. Used to initialize upload from other pages
   *
   * @private
   */
  private checkForShowExpired() {
    const query = location.search.slice(1);
    const params = new URLSearchParams(query);

    if (params.has('showExpired')) {
      this.showExpired = true;
      history.replaceState({}, '', location.protocol + '//' + location.host + location.pathname);
      setTimeout(() => {
        this.showExpired = false;
      }, 30000);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-signin': DSignin;
  }
}
