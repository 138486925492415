import { html, nothing, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { TutorialParticipant } from 'src/library/lists/d-list-section-tutorial-participants.js';
import '../../library/editors/index.js';
import '../../library/editors/components/d-edit-classification.js';
import '../../library/elements/d-section.js';
import '../../library/fields/index.js';
import '../../library/fields/d-view-info.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../library/lists/d-list-section-tutorial-participants.js';
import { AbstractEntityView, EntityContent, ListDefinition } from '../entity-content.js';
import { UpdateSectionItem } from 'src/content/d-update-section.js';
import { SectionField } from 'src/library/components/d-field-section';

export interface TutorialView extends AbstractEntityView<TutorialViewEditItem> {
  type: 'tutorials';
  name: string;
  content: string;
  employees: TutorialParticipant[];
  isCommonDate: boolean;
  commonDate: string;
  comment: string;

  guide: boolean;
  currentUserAsParticipant?: TutorialParticipant;
}

export interface TutorialViewEditItem {
  name: string;
}

/**
 *
 */
@customElement('d-tutorial-view')
export class DTutorialView extends EntityContent<TutorialView, TutorialViewEditItem> {
  @property({ type: String })
  entityType = 'tutorials';
  @property({ type: Boolean })
  helpOpened = false;
  @property({ type: String })
  employeeId = '';

  renderViewMode() {
    return html`${this.renderFieldSection('tutorials', this.viewFields(), this.entityView)}`;
  }

  hideEdit(): boolean {
    return true;
  }

  hideDelete(): boolean {
    return true;
  }

  hidePdf(): boolean {
    return true;
  }

  async initializeEditItem() {
    this.editItem = {
      name: this.entityView.name,
    };
  }

  _updateTutorial() {
    this.dispatchEvent(
      new CustomEvent<{ value?: string }>('update-tutorial', {
        bubbles: true,
        composed: true,
        detail: { value: this.entityView.uuid },
      }),
    );
  }

  onParticipantsChanged(
    e: CustomEvent<{ participants: TutorialParticipant[]; isCommonDate: boolean; commonDate: string; comment: string }>,
  ) {
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent<{
        uuid: string;
        participants: TutorialParticipant[];
        isCommonDate: boolean;
        commonDate: string;
        comment: string;
      }>('tutorial-changed', {
        bubbles: true,
        composed: true,
        detail: { uuid: this.entityView.uuid, ...e.detail },
      }),
    );
  }

  renderPreContent(): TemplateResult<1> {
    const info = this.entityView.guide
      ? `
       <p>
        <strong>Innebygget veileder </strong>Ved gjennomføring vises veilederen i et panel til høyre i TrinnVis-vinduet.
      </p>  
    `
      : `
      <p>
        <strong>Innebygget kurs </strong>Ved gjennomføring vises kurset i et panel til høyre i TrinnVis-vinduet.
        Aktive kurs vises under <i>Kurs</i> i toppmenyen.
        Beståtte kurs blir dokumentert under <i>Personale</i>.
      </p> 
    `;
    return html`
      <d-section>
        <d-view-info .content=${info}></d-view-info>
      </d-section>
      ${this.entityView.guide
        ? html`
            <d-section>
              <div>
                <div class="button" @click=${() => this._updateTutorial()}>Start veileder</div>
              </div>
            </d-section>
          `
        : nothing}
    `;
  }

  renderParticipants(): TemplateResult<1> {
    return this.entityView.guide
      ? html``
      : html`<d-list-section-tutorial-participants
          .employeeId=${this.employeeId}
          .token=${this.token}
          .writeAccess=${this.entityView.currentUserHasWriteAccess}
          .employees=${this.entityView.employees}
          .isCommonDate=${this.entityView.isCommonDate}
          .commonDate=${this.entityView.commonDate}
          .comment=${this.entityView.comment}
          @participants-changed=${this.onParticipantsChanged}
        ></d-list-section-tutorial-participants>`;
  }

  protected viewFields(): SectionField[] {
    return [
      {
        field: 'content',
        fieldForLabel: 'description',
        type: 'view-html',
      },
    ];
  }

  protected lists(): ListDefinition[] {
    return [
      {
        field: '',
        type: 'custom',
        icon: '',
        items: '',
        render: () => this.renderParticipants(),
      },
    ];
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return undefined;
  }

  protected renderAttachments(): TemplateResult<1> {
    return html``;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-tutorial-view': DTutorialView;
  }
}
