import { html, nothing, PropertyValues, TemplateResult } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { AbstractEntityView, EntityContent, ListDefinition } from 'src/content/entity-content';
import type { ReminderType } from 'src/content/event-occurrences/d-edit-reminder';
import {
  MeetingMessageDialog,
  MeetingMessageDialogInput,
  MeetingMessageDialogResult,
  MessageForSend,
} from './meeting-message-dialog';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag';

import '../../library/components/d-help-section';
import '../../library/fields/d-view-occurrences';
import '../../library/fields/d-view-text';
import '../../content/d-edit-entity-name';
import '../../library/elements/d-smooth-resize';
import '../../library/elements/d-section';
import '../../library/editors/components/d-edit-event-timing';
import '../../library/editors/elements/d-select-dropdown';
import '../../library/editors/elements/d-select-tag';
import '../../content/event-occurrences/d-edit-assignee';
import '../../content/event-occurrences/d-edit-reminder';
import '../../library/editors/components/d-edit-classification';
import '../../library/editors/components/d-edit-html';
import '../../library/editors/components/d-list-dropdowns-or-add';
import '../../library/fields/d-view-html';
import '../../library/lists/d-list-section';
import 'src/library/elements/d-wrap';
import 'src/library/elements/d-action';

import { isEmptyHtmlContent, isSameHtmlContent, toDateTimeDuration } from 'src/utilities/text';
import { UpdateSectionItem } from 'src/content/d-update-section';
import {
  RecurrenceOptionsDialog,
  RecurrenceOptionsResult,
} from 'src/content/event-occurrences/recurrence-options-dialog';
import { LocalDate } from 'src/utilities/local-date';
import { DTemplateDialog, TemplateDialogResult } from 'src/layout/parts/d-template-dialog';
import {
  DEditOccurrencesDialog,
  OccurrencesDialogInput,
  OccurrencesDialogResult,
} from 'src/layout/parts/d-edit-occurrences-dialog';
import { EventDoneDetail, MessageRecipient, SaveType } from 'src/content/event-occurrences/d-event-occurrence-view';
import { EventTiming } from 'src/library/editors/components/d-edit-event-timing';
import '../event-occurrences/d-edit-recurrence';
import { Occurrence } from 'src/library/lists/d-occurrences-list';
import { FunctionViewModel, TopicViewModel } from 'src/store/api';
import {
  DeleteMeetingOccurrenceDialog,
  DeleteMeetingOccurrenceDialogResult,
} from 'src/content/meeting-occurrences/delete-meeting-occurrence-dialog';
import { dateTimeDescription } from 'src/store/utilities';
import {
  DNewEmployeeDialog,
  NewEmployeeDialogResult,
  CreateEmployeeData,
} from 'src/layout/parts/d-new-employee-dialog';
import {
  DNewContactAndPartnerDialog,
  NewContactAndPartnerDialogResult,
} from 'src/layout/parts/d-new-contact-and-partner-dialog';
import { SectionField } from 'src/library/components/d-field-section';

export interface MeetingOccurrenceViewEditItem {
  newItem: boolean;
  name: string;
  nameConfirmed: boolean;
  date: string;
  time: string;
  recurrence: string;
  assignedToEmployees: string[];
  assignedToContacts: string[];
  reminder: ReminderType;
  accessControl: string[];
  classification: string;
  meetingAgenda: string;
  meetingResponsibleFunctionUuid: string;
  meetingResponsibleEmployeeUuid: string;
  durationMinutes: number;
  saveType: SaveType;
  meetingUuid: string;
}

export interface ModelMeeting {
  name: string;
  meetingResponsible: string;
  meetingResponsibleEmployee: string;
  employees: string[];
  contacts: string[];
  notes: string;
  agenda: string;
  meetingUuid: string;
  time: string;
  durationMinutes: number;
  classification: string;
  accessControl: string[];
  futureOccurrences: Occurrence[];
}

export interface TemplateMeeting {
  name: string;
  uuid: string;
  helpContent: string;
  agenda: string;
}

export interface MeetingOccurrenceView extends AbstractEntityView<MeetingOccurrenceViewEditItem> {
  date: string;
  time: string;
  doneDate: string;
  doneText: string;
  isPast: boolean;
  durationMinutes: number;
  newItem: boolean;
  thisEventInstance: string;
  reminderText: string;
  assigneeDisplayText: string;
  notes: string;
  timeDescription: string;
  type: 'meetingOccurrences';
  name: string;
  functions: FunctionViewModel[];
  availableEmployees: SelectTagOption[];
  availableContacts: SelectTagOption[];
  availableFunctions: SelectDropdownOption[];
  defaultMeetingResponsibleFunctionUuid: string;
  defaultMeetingResponsibleEmployeeUuid: string;
  currentEmployeeUuid: string | undefined;
  currentEmployeeShortName: string | undefined;
  employeeFullNames: { uuid: string; name: string }[];
  messageRecipients: MessageRecipient[];
  occurrences: Occurrence[];
  canUpdateFutureOccurrences: boolean;
  modelMeetings: ModelMeeting[];
  templateMeetings: TemplateMeeting[];
  noticeSentText: string;
  noticeSent: boolean;
  meetingDone: boolean;
  reportShared: boolean;
  reportSharedText: string;
  meetingResponsibleFunctionUuid: string;
  meetingResponsibleEmployeeUuid: string;
  meetingResponsibleDisplayText: string;
  assignedToEmployees: string[];
  assignedToContacts: string[];
  reminder: ReminderType;
  accessControl: string[];
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  classificationText: string;
  meetingAgenda: string;
  relatedMeetingProcedures: string;
  accessControlOptions: SelectTagOption[];
  hasReport: boolean;
  agendaDescriptionLabel: string;
  meetingReportWrittenLabel: string;
  meetingReportWrittenUuid?: string;
  meetingReportContent?: string;
  meetingReportWrittenText: string;
  meetingReportIsClassified: boolean;
  lastModified: string;
  templateUpdated: string;
  organizationId: number;
  userEmails: string[];
  availablePartners: SelectDropdownOption[];
  relatedCollections: TopicViewModel[];
}

export interface CreateEmployeeInput {
  data: CreateEmployeeData;
  complete: () => void;
}

/**
 *
 *
 *
 *
 *
 */
@customElement('d-meeting-occurrence-view')
export class DMeetingOccurrenceView extends EntityContent<MeetingOccurrenceView, MeetingOccurrenceViewEditItem> {
  @property({ type: String })
  templateAgenda = '';
  // Temporary fix
  itemLocked = false;
  protected saveType?: SaveType;
  @state()
  private newFromMeeting = false;
  private noName = false;
  @state()
  private editDoneDate = '';

  protected get currentItemSecondaryLabel() {
    let item: MeetingOccurrenceView | MeetingOccurrenceViewEditItem = this.entityView;
    if (this.editItem) {
      item = this.editItem;
    }
    if (this.isEmployeeInterview(item.name)) {
      const assigneeDisplayText =
        this.entityView.employeeFullNames.find((e) => {
          return e.uuid === item.assignedToEmployees[0];
        })?.name || '';
      return 'med ' + assigneeDisplayText;
    }
    return '';
  }

  protected get currentItemSublabel(): string {
    if (this.editItem) {
      if (this.editItem.nameConfirmed) {
        return dateTimeDescription(this.editItem.date, this.editItem.time, this.editItem.durationMinutes);
      }
      return '';
    }
    return dateTimeDescription(this.entityView.date, this.entityView.time, this.entityView.durationMinutes);
  }

  private get isUpdateAvailable() {
    const e = this.entityView;
    if (e.date && e.lastModified && e.templateUpdated) {
      const eventDate = e.date;
      const lastModified = e.lastModified.split(' ')[0];
      const templateUpdated = e.templateUpdated.split('T')[0];
      return (
        LocalDate.fromString(eventDate).isSameOrAfter(LocalDate.now()) &&
        LocalDate.fromString(lastModified).isBefore(LocalDate.fromString(templateUpdated))
      );
    }
    return false;
  }

  async fieldActionAgenda() {
    const e = this.calculateEditItem();
    const action = await this.openTemplateDialog(e);
    if (action === 'done') {
      this.dispatchEvent(new CustomEvent('edit', { bubbles: true, composed: true }));
      this.mode = 'edit';
    }
  }

  async fieldActionNotice() {
    await this.sendMeetingMessage(this.entityView, 'notice');
  }

  async fieldActionCreateReport() {
    await this.createMeetingReport();
  }

  toggleMeetingDone() {
    let done = true;
    if (this.entityView.meetingDone) {
      done = false;
    }
    this.dispatchEvent(
      new CustomEvent<EventDoneDetail>('toggle-meeting-done', {
        bubbles: true,
        composed: true,
        detail: {
          done,
          uuid: this.entityView.uuid,
          eventUuid: this.entityView.uuid,
          eventInstance: this.entityView.uuid,
          doneBy: this.entityView.currentEmployeeUuid ?? '',
          doneDate: this.editDoneDate,
        },
      }),
    );
    if (!done) {
      this.editDoneDate = this.entityView.date;
    }
  }

  fieldActionOpenReport() {
    const destination = this.entityView.href + '/reports/' + this.entityView.meetingReportWrittenUuid;
    this.dispatchEvent(
      new CustomEvent('navigate', {
        bubbles: true,
        composed: true,
        detail: { href: destination },
      }),
    );
  }

  async fieldActionShowVideoMeet() {
    this.dispatchEvent(
      new CustomEvent('show-meet', {
        bubbles: true,
        composed: true,
        detail: {
          meetingOccurrenceId: this.entityView.uuid,
        },
      }),
    );
  }

  async fieldActionShareReport() {
    await this.sendMeetingMessage(this.entityView, 'report');
  }

  renderViewMode() {
    return html` ${this.renderFieldSection('meetings', this.viewFields(), this.entityView)} `;
  }

  editHeaderActions() {
    if (this.editItem && !this.editItem.nameConfirmed) {
      return [];
    }
    return super.editHeaderActions();
  }

  renderHelpSection() {
    if (this.editItem) {
      const meeting = this.getTemplateMeeting(this.editItem.name);
      if (meeting) {
        return html` <d-help-section content="${meeting.helpContent || ''}"></d-help-section> `;
      }
    }
    return super.renderHelpSection();
  }

  async onDeleteConfirmed() {
    let saveType: SaveType = 'single';
    if (!this.entityView.newItem && this.entityView.canUpdateFutureOccurrences) {
      const result: RecurrenceOptionsResult = await RecurrenceOptionsDialog.open({
        isMeeting: true,
        deleting: true,
      });
      if (result.action === 'cancel') {
        return;
      } else if (result.action === 'following') {
        saveType = 'followingRepeated';
      } else {
        saveType = 'singleRepeated';
      }
    }
    this.fireDeleteEvent(saveType);
  }

  async onDelete() {
    if (!this.entityView.newItem && (this.entityView.noticeSent || this.entityView.hasReport)) {
      const result: DeleteMeetingOccurrenceDialogResult = await DeleteMeetingOccurrenceDialog.open({
        noticeSent: this.entityView.noticeSent,
        reportWritten: this.entityView.hasReport,
      });
      if (result.action === 'cancel') {
        return;
      }
      if (result.action === 'delete') {
        return this.onDeleteConfirmed();
      }
    } else {
      return this.onDeleteConfirmed();
    }
  }

  renderPreContent() {
    return html` ${this.renderMetaSection()} `;
  }

  async initializeEditItem() {
    this.editItem = this.calculateEditItem();
  }

  calculateEditItem() {
    return this.calculateMeetingEditItem(this.entityView, this.templateAgenda);
  }

  calculateMeetingEditItem(view: MeetingOccurrenceView, templateAgenda: string): MeetingOccurrenceViewEditItem {
    if (view.newItem && view.name !== '') {
      this.newFromMeeting = true;
    }
    let meetingAgenda = view.meetingAgenda;
    if (templateAgenda) {
      meetingAgenda = templateAgenda;
    }
    let nameConfirmed = false;
    if (!this.entityView.newItem || this.newFromMeeting) {
      nameConfirmed = true;
    }
    return {
      newItem: view.newItem,
      accessControl: view.accessControl,
      classification: view.classification,
      reminder: view.reminder,
      date: view.date,
      time: view.time,
      durationMinutes: view.durationMinutes,
      assignedToEmployees: view.assignedToEmployees,
      assignedToContacts: view.assignedToContacts,
      meetingAgenda,
      meetingResponsibleFunctionUuid: view.meetingResponsibleFunctionUuid,
      meetingResponsibleEmployeeUuid: view.meetingResponsibleEmployeeUuid,
      name: view.name,
      recurrence: '',
      saveType: 'single',
      nameConfirmed,
      meetingUuid: '',
    };
  }

  renderEditItem(item: MeetingOccurrenceViewEditItem): TemplateResult<1> {
    return this.renderMeetingEditItem(item);
  }

  doneDisabled(): boolean {
    return this.noName;
  }

  async onDone() {
    if (this.editItem) {
      let name = this.editItem.name;
      if (this.isEmployeeInterview(this.editItem.name)) {
        name = 'Medarbeidersamtale';
      }
      this.editItem = {
        ...this.editItem,
        name,
      };
    }
    return super.onDone();
  }

  toArray(value: string): string[] {
    if (value) {
      return [value];
    }
    return [];
  }

  meetingResponsibleChanged(
    item: MeetingOccurrenceViewEditItem,
    e: CustomEvent<{
      assignedToEmployees: string[];
      assignedToFunction: string;
      assignedToContacts: string[];
    }>,
  ) {
    e.stopPropagation();
    this.editItem = {
      ...item,
      meetingResponsibleFunctionUuid: e.detail.assignedToFunction,
      meetingResponsibleEmployeeUuid: e.detail.assignedToEmployees[0],
    };
  }

  onContactSelected(e, item: MeetingOccurrenceViewEditItem) {
    e.stopPropagation();
    if (e.detail.value === 'NEW') {
      this.addNewContact(item);
    } else {
      const assignedToContacts = [...item.assignedToContacts, e.detail.value];
      this.editItem = {
        ...item,
        assignedToContacts,
      };
    }
  }

  onContactRemoved(e, item: MeetingOccurrenceViewEditItem) {
    e.stopPropagation();
    const assignedToContacts = item.assignedToContacts.filter((a) => {
      return a !== e.detail.value.value;
    });
    this.editItem = {
      ...item,
      assignedToContacts,
    };
  }

  protected viewFields(): SectionField[] {
    return [
      /**
       * Skal bare vises i pdf
       */
      {
        field: 'timeDescription',
        fieldForLabel: 'events_time',
        type: 'view-text',
        class: 'fullWidth',
        condition: false,
      },
      {
        field: 'meetingResponsibleDisplayText',
        fieldForLabel: 'responsible',
        type: 'view-text',
        class: 'fullWidth',
      },
      {
        field: 'assigneeDisplayText',
        fieldForLabel: 'participants',
        type: 'view-text',
        class: 'fullWidth',
      },
      {
        field: 'reminderText',
        fieldForLabel: 'events_reminder',
        type: 'view-text',
        class: 'fullWidth',
      },
      {
        field: 'classificationText',
        fieldForLabel: 'generalFields_access',
        type: 'view-text',
        class: 'fullWidth',
        condition: this.entityView.classificationText !== '',
      },
      {
        field: '',
        label: 'Denne møtetypen har et nytt',
        type: 'view-text',
        lightLabel: true,
        labelAction: { name: 'agendaforslag', action: 'fieldActionAgenda' },
        labelActionPlain: true,
        labelClass: 'template-updated',
        class: 'fullWidth',
        condition: this.isUpdateAvailable,
      },
      {
        field: 'meetingAgenda',
        sublabel: isEmptyHtmlContent(this.entityView.meetingAgenda) ? 'Ingen agenda' : '',
        type: 'view-html',
        expandable: !isEmptyHtmlContent(this.entityView.meetingAgenda),
        class: 'fullWidth',
      },
      {
        field: 'noticeSentText',
        type: 'view-text',
        labelClass: this.entityView.noticeSent ? 'check-blue' : '',
        action: {
          name: this.entityView.noticeSent ? 'Send ny innkalling' : 'Send innkalling',
          action: 'fieldActionNotice',
        },
        class: 'fullWidth',
      },
      {
        type: 'custom',
        render: () => {
          return this.renderEditDone();
        },
        condition: (!this.entityView.hasReport || this.entityView.doneDate !== '') && this.entityView.isPast,
      },
      {
        field: 'meetingReportWrittenText',
        type: 'view-text',
        labelClass: this.entityView.hasReport ? 'check-blue' : '',
        action: this.entityView.hasReport
          ? { name: 'Åpne referat', action: 'fieldActionOpenReport' }
          : { name: 'Skriv referat', action: 'fieldActionCreateReport' },
        class: 'fullWidth',
      },
      {
        field: 'reportSharedText',
        type: 'view-text',
        labelClass: this.entityView.reportShared ? 'check-blue' : '',
        action: this.entityView.reportShared
          ? { name: 'Del på nytt', action: 'fieldActionShareReport' }
          : { name: 'Del referat', action: 'fieldActionShareReport' },
        class: 'fullWidth',
        condition: this.entityView.hasReport,
      },
      {
        field: 'showVideoMeet',
        label: 'Videomøte',
        value: () => '',
        type: 'view-text',
        action: { name: 'Åpne', action: 'fieldActionShowVideoMeet' },
        class: 'fullWidth',
        condition: this.entityView.featureStates.video,
      },
    ];
  }

  protected lists(): ListDefinition[] {
    return [
      {
        field: 'generalFields_attachments',
        type: 'custom',
        icon: 'attachments',
        items: 'attachments',
        render: () => this.renderAttachments(),
        condition: this.entityView.featureStates.core,
      },
    ];
  }

  protected async saveEditItem(
    item: MeetingOccurrenceViewEditItem,
  ): Promise<MeetingOccurrenceViewEditItem | undefined> {
    let saveType: SaveType = 'single';
    if (this.entityView.canUpdateFutureOccurrences) {
      const result: RecurrenceOptionsResult = await RecurrenceOptionsDialog.open({
        isMeeting: false,
        deleting: false,
      });
      if (result.action === 'cancel') {
        return;
      } else if (result.action === 'following') {
        saveType = 'followingRepeated';
      } else {
        saveType = 'singleRepeated';
      }
    }

    return {
      ...item,
      saveType: saveType,
    };
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return undefined;
  }

  protected getIcon(): string {
    return 'meetings';
  }

  protected updated(_changedProperties: PropertyValues) {
    super.updated(_changedProperties);
    if (_changedProperties.has('entityView')) {
      const oldView = _changedProperties.get('entityView');
      if (!oldView || oldView.uuid !== this.entityView.uuid) {
        this.setEditDone();
      }
    }
  }

  private getTemplateMeeting(name: string): TemplateMeeting | undefined {
    return this.entityView.templateMeetings.find((t) => {
      return t.name === name;
    });
  }

  private renderMetaSection() {
    if (this.entityView.isConfirmedEntity) {
      const e = this.entityView;
      return html`
        <div class="meta-section">
          <d-view-occurrences
            .writeAccess=${this.entityView.currentUserHasWriteAccess}
            .occurrences=${e.occurrences}
            .eventType=${'meeting'}
            .parentHref=${e.parentHref}
            .currentUuid=${e.uuid}
            style="margin-bottom: -12px"
            @edit-occurrences=${() => this.openRecurrenceDialog()}
          ></d-view-occurrences>
        </div>
      `;
    }
    return nothing;
  }

  private renderEditDone() {
    const actionLabel = this.entityView.doneDate ? 'Ikke gjennomført' : 'Sett dato';
    return html` <d-wrap tight baseline split style="margin: 0">
      <d-view-text
        inline-label
        label="Møte gjennomført"
        .value=${this.entityView.doneText}
        .classes=${this.entityView.doneDate ? 'check-blue' : ''}
      ></d-view-text>
      <d-wrap baseline tight style="flex: none">
        ${this.entityView.doneDate
          ? nothing
          : html`
              <d-select-date
                style="margin: 0"
                controller
                .value=${this.editDoneDate}
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  e.stopPropagation();
                  this.editDoneDate = e.detail.value;
                }}
              ></d-select-date>
            `}
        <d-action style="margin: 0 -4px 0 0" @click=${() => this.toggleMeetingDone()}>${actionLabel}</d-action>
      </d-wrap>
    </d-wrap>`;
  }

  private async addNewEmployee(item: MeetingOccurrenceViewEditItem, type: 'interview' | 'participant' | 'responsible') {
    const result: NewEmployeeDialogResult = await DNewEmployeeDialog.open({
      userEmails: this.entityView.userEmails,
    });
    if (result.action === 'done') {
      if (type === 'interview') {
        this.editItem = {
          ...item,
          name: 'Medarbeidersamtale',
          nameConfirmed: true,
          assignedToEmployees: [result.employeeData.uuid],
        };
      }
      if (type === 'participant') {
        const assignedToEmployees = [...item.assignedToEmployees, result.employeeData.uuid];
        this.editItem = { ...item, assignedToEmployees };
      }
      if (type === 'responsible') {
        this.editItem = { ...item, meetingResponsibleEmployeeUuid: result.employeeData.uuid };
      }
    }
    return result.action;
  }

  private async addNewContact(item: MeetingOccurrenceViewEditItem) {
    const result: NewContactAndPartnerDialogResult = await DNewContactAndPartnerDialog.open({
      userEmails: this.entityView.userEmails,
      partners: this.entityView.availablePartners,
    });
    if (result.action === 'done') {
      const assignedToContacts = [...item.assignedToContacts, result.contactAndPartnerData.contactUuid];
      this.editItem = { ...item, assignedToContacts };
    }
    return result.action;
  }

  private async openRecurrenceDialog() {
    const parentUuid = this.getTemplateMeeting(this.entityView.name)?.uuid || '';
    let eventName = this.entityView.name;
    if (this.isEmployeeInterview(this.entityView.name)) {
      const employee = this.entityView.employeeFullNames.find((e) => {
        return e.uuid === this.entityView.assignedToEmployees[0];
      });
      if (employee) {
        eventName += ' med ' + employee.name;
      }
    }
    const input: OccurrencesDialogInput = {
      parentUuid,
      modelOccurrence: this.entityView.occurrences.find((o) => {
        return o.occurrenceUuid === this.entityView.uuid;
      }) ?? { occurrenceUuid: '', dateTime: '', durationMinutes: 0, classified: false, restricted: false },
      occurrences: this.entityView.occurrences,
      eventType: 'meeting',
      eventName,
      collectionNames: this.entityView.relatedCollections.map((c) => c.name),
    };
    const result: OccurrencesDialogResult = await DEditOccurrencesDialog.open(input);
    if (result.action === 'done') {
      this.fireRepeatMeetingOccurrenceEvent(result.occurrences, result.addToCollections);
    }
  }

  private async openTemplateDialog(item: MeetingOccurrenceViewEditItem) {
    let content = '';
    const templateMeeting = this.getTemplateMeeting(item.name);
    if (templateMeeting) {
      content = templateMeeting.agenda;
    }
    const result: TemplateDialogResult = await DTemplateDialog.open({
      title: 'Forslag til agenda for ' + this.getTemplateMeeting(item.name)?.name,
      content,
    });
    if (result.action === 'done') {
      this.editItem = { ...item, meetingAgenda: content };
    }
    return result.action;
  }

  private agendaAction(item: MeetingOccurrenceViewEditItem) {
    let templateMeetingAgenda = '';
    const templateMeeting: TemplateMeeting | undefined = this.getTemplateMeeting(item.name);
    if (templateMeeting) {
      templateMeetingAgenda = templateMeeting.agenda;
    }
    if (isEmptyHtmlContent(templateMeetingAgenda)) {
      return '';
    }
    if (!isSameHtmlContent(templateMeetingAgenda, item.meetingAgenda || '')) {
      return 'Se forslag';
    }
    return '';
  }

  private fireDeleteEvent(saveType: SaveType) {
    this.dispatchEvent(new CustomEvent('edit-off', { bubbles: true, composed: true }));
    this.dispatchEvent(
      new CustomEvent<{ saveType: SaveType }>('delete-meeting-occurrence', {
        bubbles: true,
        composed: true,
        detail: { saveType: saveType },
      }),
    );
  }

  private responsibleField(isEmployeeInterview: boolean): string {
    if (isEmployeeInterview) {
      return 'meetings_employeeInterviewLeader';
    }
    return 'meetings_responsible';
  }

  private participantsField(isEmployeeInterview: boolean): string {
    if (isEmployeeInterview) {
      return 'meetings_employeeInterviewEmployee';
    }
    return 'meetings_participants';
  }

  private async createMeetingReport() {
    this.dispatchEvent(
      new CustomEvent<{ uuid: string; href: string }>('create-meeting-report', {
        bubbles: true,
        composed: true,
        detail: { uuid: this.entityView.uuid, href: this.entityView.href },
      }),
    );
  }

  private async sendMeetingMessage(e: MeetingOccurrenceView, type: 'notice' | 'report') {
    const input: MeetingMessageDialogInput = {
      type: type,
      name: this.entityView.name,
      preventIncludeContent: this.entityView.meetingReportIsClassified && type === 'report',
      isEmployeeInterview: this.isEmployeeInterview(this.entityView.name),
      recipients: this.entityView.messageRecipients,
    };
    const result: MeetingMessageDialogResult = await MeetingMessageDialog.open(input);
    this.handleMeetingResult(type, result);
  }

  private handleMeetingResult(type: string, result: MeetingMessageDialogResult) {
    if (result.action === 'send') {
      this.fireSendMessageEvent(result, type);
    }
  }

  private fireSendMessageEvent(result: { action: 'send'; message: string; includeContent: boolean }, type: string) {
    const recipientsWithAccess = this.entityView.messageRecipients.filter((p) => {
      return p.accessLevel !== 'NONE';
    });
    if (recipientsWithAccess.length > 0) {
      const messageData = {
        occurrenceUuid: this.entityView.uuid,
        message: result.message,
        includeContent: result.includeContent,
      };
      this.fireEvent(type, messageData);
    }
  }

  private fireEvent(type: string, messageData: MessageForSend) {
    if (type === 'notice') {
      this.dispatchEvent(
        new CustomEvent<MessageForSend>('send-meeting-notice', {
          bubbles: true,
          composed: true,
          detail: messageData,
        }),
      );
    }
    if (type === 'report') {
      this.dispatchEvent(
        new CustomEvent<MessageForSend>('send-meeting-report', {
          bubbles: true,
          composed: true,
          detail: messageData,
        }),
      );
    }
  }

  private isEmployeeInterview(name: string): boolean {
    return name.toLowerCase().includes('medarbeidersamtale');
  }

  private onNameFocused(item: MeetingOccurrenceViewEditItem) {
    this.editItem = { ...item, nameConfirmed: false };
  }

  private onNameCancel(e, item: MeetingOccurrenceViewEditItem) {
    e.stopPropagation();
    if (this.entityView.newItem) {
      return this.onDelete();
    } else {
      this.editItem = { ...item, name: this.entityView.name, nameConfirmed: true };
    }
  }

  private onNameDone(e, item: MeetingOccurrenceViewEditItem) {
    e.stopPropagation();
    const name = e.detail.name;
    const meetingUuid = e.detail.masterEntityUuid;
    const editItem = {
      ...item,
      name,
      meetingUuid,
      nameConfirmed: true,
    };
    const modelMeeting: ModelMeeting | undefined = this.entityView.modelMeetings.find((m) => {
      return m.name === e.detail.name;
    });
    if (modelMeeting) {
      editItem.time = modelMeeting.time;
      editItem.durationMinutes = modelMeeting.durationMinutes;
      editItem.meetingAgenda = modelMeeting.agenda;
      editItem.classification = modelMeeting.classification;
      editItem.accessControl = [...modelMeeting.accessControl];
      editItem.meetingResponsibleFunctionUuid = modelMeeting.meetingResponsible;
      editItem.meetingResponsibleEmployeeUuid = modelMeeting.meetingResponsibleEmployee;
      editItem.assignedToEmployees = [...modelMeeting.employees];
      editItem.assignedToContacts = [...modelMeeting.contacts];
      if (this.isEmployeeInterview(name)) {
        editItem.name = 'Medarbeidersamtale';
        editItem.classification = 'CONFIDENTIAL';
        const responsibleFunction = this.entityView.functions.find((f) => {
          return f.uuid === modelMeeting.meetingResponsible;
        });
        const accessControl: string[] = [];
        if (responsibleFunction) {
          accessControl.push(responsibleFunction.employees[0]);
          if (!accessControl.includes(modelMeeting.meetingUuid)) {
            accessControl.push(modelMeeting.meetingUuid);
          }
          if (this.entityView.currentEmployeeUuid && !accessControl.includes(this.entityView.currentEmployeeUuid)) {
            accessControl.push(this.entityView.currentEmployeeUuid);
          }
          editItem.accessControl = accessControl;
        }
      }
    }
    this.editItem = editItem;
  }

  private name(entityView: MeetingOccurrenceView, item: MeetingOccurrenceViewEditItem) {
    let result = item.name;
    if (this.isEmployeeInterview(item.name)) {
      let employeeName = '';
      const employee = entityView.employeeFullNames.find((e) => {
        return e.uuid === item.assignedToEmployees[0];
      });
      if (employee) {
        employeeName = employee.name;
      }
      result = 'Medarbeidersamtale med ' + employeeName;
    }
    return result;
  }

  private renderEditName(item: MeetingOccurrenceViewEditItem) {
    return html`
      <d-edit-entity-name
        .autofocus=${!item.nameConfirmed && !this.newFromMeeting}
        .newItem=${this.entityView.newItem}
        .entityType=${'meeting'}
        .placeholder=${'Gi møtet et navn'}
        .parentHref=${this.parentHref}
        .name=${this.name(this.entityView, item)}
        .originalName=${this.name(this.entityView, item)}
        .currentEntityUuid=${this.entityView.uuid}
        .masterEntityUuid=${this.getTemplateMeeting(item.name)?.uuid || ''}
        .models=${this.entityView.modelMeetings.map((m) => {
          return {
            name: m.name,
            type: 'meetings',
            masterEntityUuid: m.meetingUuid,
            futureOccurrences: m.futureOccurrences,
          };
        })}
        .bypassConfirmations=${item.nameConfirmed}
        .organizationId=${this.entityView.organizationId}
        @name-focused=${() => this.onNameFocused(item)}
        @add-new-employee-for-interview=${() => this.addNewEmployee(item, 'interview')}
        @cancel=${(e) => this.onNameCancel(e, item)}
        @done=${(e) => this.onNameDone(e, item)}
      ></d-edit-entity-name>
    `;
  }

  private renderEditTiming(item: MeetingOccurrenceViewEditItem) {
    return html`
      <d-section>
        <d-edit-event-timing
          .date=${item.date}
          .time=${item.time === '' ? 'NONE' : item.time}
          .durationMinutes=${item.durationMinutes}
          @value-changed=${(e: CustomEvent<EventTiming>) => {
            e.stopPropagation();
            if (e.detail.eventType === 'TODO') {
              throw new Error('Illegal state. Meetings must be scheduled.');
            } else {
              this.editItem = {
                ...item,
                date: e.detail.date,
                time: e.detail.time,
                durationMinutes: e.detail.durationMinutes,
              };
            }
          }}
          is-meeting
        >
        </d-edit-event-timing>
      </d-section>
    `;
  }

  private renderEmployeeInterviewParticipant(item: MeetingOccurrenceViewEditItem) {
    return html`
      <d-section>
        <d-view-text
          inline-label
          label="Medarbeider"
          .value=${this.entityView.employeeFullNames.find((e) => e.uuid === item.assignedToEmployees[0])?.name ?? ''}
        ></d-view-text>
      </d-section>
    `;
  }

  private renderEditParticipants(item: MeetingOccurrenceViewEditItem) {
    return html`
      <d-section>
        <d-select-tag
          class="fullWidth"
          field="meetings_internalParticipants"
          .value=${item.assignedToEmployees}
          .options=${this.entityView.availableEmployees}
          add-new-text="+ Ny person"
          @value-changed=${(e) => {
            e.stopPropagation();
            this.editItem = {
              ...item,
              assignedToEmployees: e.detail.value,
            };
          }}
          @add-new=${() => this.addNewEmployee(item, 'participant')}
        >
        </d-select-tag>
        ${this.entityView.availableContacts.length < 9
          ? html`
              <d-select-tag
                class="fullWidth"
                field="meetings_externalParticipants"
                .value=${item.assignedToContacts}
                .options=${this.entityView.availableContacts}
                add-new-text="+ Ny kontaktperson"
                @value-changed=${(e) => {
                  e.stopPropagation();
                  this.editItem = {
                    ...item,
                    assignedToContacts: e.detail.value,
                  };
                }}
                @add-new=${() => this.addNewContact(item)}
              >
              </d-select-tag>
            `
          : html`
              <d-list-dropdowns-or-add
                class="fullWidth"
                field="meetings_externalParticipants"
                always-add-select
                disabled-as-view-text
                bordered
                placeholder="Velg kontaktperson"
                first-item-text="Ny kontaktperson"
                additional-item-text="Ny kontaktperson"
                .value=${item.assignedToContacts.map((a) => {
                  const contact = this.entityView.availableContacts.find((c) => {
                    return c.value === a;
                  });
                  return { value: contact?.value ?? '', text: contact?.text ?? '' };
                })}
                .options=${this.entityView.availableContacts}
                @item-selected=${(e) => this.onContactSelected(e, item)}
                @item-removed=${(e) => this.onContactRemoved(e, item)}
              ></d-list-dropdowns-or-add>
            `}
      </d-section>
    `;
  }

  private renderEditResponsibleAndParticipants(item: MeetingOccurrenceViewEditItem) {
    return html`
      <d-section>
        <d-edit-assignee
          single
          field="${this.responsibleField(this.isEmployeeInterview(item.name))}"
          .assignedToEmployees=${this.toArray(item.meetingResponsibleEmployeeUuid)}
          .assignedToFunction=${item.meetingResponsibleFunctionUuid}
          .defaultEmployee=${this.entityView.defaultMeetingResponsibleEmployeeUuid}
          .defaultFunction=${this.entityView.defaultMeetingResponsibleFunctionUuid}
          .functions=${this.entityView.availableFunctions}
          .employees=${this.entityView.availableEmployees}
          add-new-employee-text="+ Ny person"
          @add-new-employee=${() => this.addNewEmployee(item, 'responsible')}
          @assignment-changed=${(e) => this.meetingResponsibleChanged(item, e)}
        >
        </d-edit-assignee>
      </d-section>
      ${this.isEmployeeInterview(item.name)
        ? html`${this.renderEmployeeInterviewParticipant(item)}`
        : html`${this.renderEditParticipants(item)}`}
    `;
  }

  private renderEditReminder(item: MeetingOccurrenceViewEditItem) {
    return html`
      <d-section>
        <d-edit-reminder
          ?disabled=${item.time === 'NONE'}
          .value=${item.reminder}
          @value-changed=${(e: CustomEvent<{ value: ReminderType }>) => {
            e.stopPropagation();
            this.editItem = { ...item, reminder: e.detail.value };
          }}
        >
        </d-edit-reminder>
      </d-section>
    `;
  }

  private renderEditAgenda(item: MeetingOccurrenceViewEditItem) {
    return html`
      <d-edit-html
        field="meetings_meetingAgenda"
        label-action-plain
        .labelAction=${this.agendaAction(item)}
        .contentStickyTop=${this.contentStickyTop}
        @label-action=${() => this.openTemplateDialog(item)}
        .value=${item.meetingAgenda}
        @value-changed=${(e: CustomEvent<{ value: string }>) => {
          e.stopPropagation();
          this.editItem = { ...item, meetingAgenda: e.detail.value };
        }}
        .docsForLinking=${this.entityView.docsForLinking}
      ></d-edit-html>
    `;
  }

  private renderMeetingEditItem(item: MeetingOccurrenceViewEditItem) {
    return html`
      ${this.renderEditName(item)}
      <d-smooth-resize>
        ${item.nameConfirmed
          ? html`
              ${this.renderEditTiming(item)} ${this.renderEditResponsibleAndParticipants(item)}
              ${this.renderEditReminder(item)} ${this.renderEditClassification(item)} ${this.renderEditAgenda(item)}
            `
          : nothing}
      </d-smooth-resize>
    `;
  }

  private fireRepeatMeetingOccurrenceEvent(occurrences: Occurrence[], applyToCollections: boolean) {
    const existingDateTimes = this.entityView.occurrences.map((e) => toDateTimeDuration(e));
    const existingUuids = this.entityView.occurrences.map((e) => e.occurrenceUuid);
    const updatedOccurrenceUuids = occurrences.map((e) => e.occurrenceUuid);
    this.dispatchEvent(
      new CustomEvent<{
        uuid: string;
        dateTimesToAdd: string[];
        occurrencesToRemove: string[];
        applyToCollections: boolean;
      }>('repeat-meeting-occurrence', {
        bubbles: true,
        composed: true,
        detail: {
          uuid: this.entityView.uuid,
          dateTimesToAdd: occurrences.map((e) => toDateTimeDuration(e)).filter((e) => !existingDateTimes.includes(e)),
          occurrencesToRemove: existingUuids.filter((e) => !updatedOccurrenceUuids.includes(e)),
          applyToCollections: applyToCollections,
        },
      }),
    );
  }

  private setEditDone() {
    let editDoneDate = this.entityView.date;
    if (this.entityView.doneDate) {
      editDoneDate = this.entityView.doneDate;
    }
    this.editDoneDate = editDoneDate;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-meeting-occurrence-view': DMeetingOccurrenceView;
  }
}
