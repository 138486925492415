import { html, nothing, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { AbstractEntityViewWithRevisions, EntityContent, ListDefinition } from 'src/content/entity-content';
import '../../library/components/d-calendar';
import { UpdateSectionItem } from 'src/content/d-update-section';
import { Occurrence } from 'src/library/lists/d-occurrences-list';
export interface MeetingView extends AbstractEntityViewWithRevisions<Record<string, never>> {
  occurrences: Occurrence[];
  type: 'meetings';
  name: string;
  interviewedEmployeeUuid: string;
  interviewedEmployeeName: string;
}

/**
 *
 */
@customElement('d-meeting-view')
export class DMeetingView extends EntityContent<MeetingView, Record<string, never>> {
  protected get currentItemSublabel() {
    return 'Møtekalender';
  }

  protected get preventDelete(): boolean {
    return true;
  }

  async initializeEditItem() {
    throw new Error('Illegal state. Meeting view is not editable');
  }

  hideEdit() {
    return true;
  }

  hidePdf() {
    return true;
  }

  renderMeetingCalendar(): TemplateResult<1> {
    return html`<d-calendar
      style="margin-top: -1px"
      .currentYear=${this.entityView.today.year()}
      .occurrences=${this.entityView.occurrences}
      .eventTerm=${'møte'}
      .baseHref=${this.entityView.href}
      .addText=${'Legg til møte'}
      .meetingUuid=${this.entityView.uuid}
      .interviewedEmployeeUuid=${this.entityView.interviewedEmployeeUuid}
      .contentStickyTop=${this.contentStickyTop}
    ></d-calendar> `;
  }

  renderViewMode() {
    return html` ${this.renderFieldSection('reports', this.viewFields(), this.entityView)} `;
  }

  renderHelpSection() {
    return html``;
  }

  renderLists() {
    return this.renderMeetingCalendar();
  }

  renderEditItem(): TemplateResult<1> {
    return html``;
  }

  protected lists(): ListDefinition[] {
    return [
      {
        field: '',
        icon: '',
        items: '',
        type: 'custom',
        render: () => this.renderMeetingCalendar(),
      },
    ];
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return undefined;
  }

  protected renderAttachments(): TemplateResult<1> {
    return html``;
  }

  protected renderRevisions(): TemplateResult<1> | typeof nothing {
    return nothing;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-meeting-view': DMeetingView;
  }
}
