import { html } from 'lit';
import '../../library/elements/d-section';
import '../../library/fields/d-view-html';
import './d-promo-section';
import { customElement, property } from 'lit/decorators.js';
import { BaseDialog } from 'src/library/components/BaseDialog';
import type { Feature, Promo } from 'src/store/selectors/features';
import { OrganizationViewModelSpecialTermsEnum } from 'src/store/api';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';

export interface PromoDialogInput {
  promoData: Promo;
  features: Feature[];
  employeesCount: number;
  specialTerms: OrganizationViewModelSpecialTermsEnum | undefined;
  healthcareSignatoryOptions: SelectDropdownOption[];
  healthcareSignatory: string | undefined;
}
export interface PromoDialogResult {
  action: 'cancel' | 'upgrade';
  signatoryUuid?: string;
}

/**
 *
 * STATUS OK
 */
@customElement('d-promo-dialog')
export class DPromoDialog extends BaseDialog<PromoDialogInput, PromoDialogResult> {
  @property({ type: Number })
  width = 600;
  @property({ type: Boolean })
  noHeader = true;
  @property({ type: Boolean })
  noFooter = true;
  @property({ type: Boolean })
  cancelOnOutsideClick = true;
  @property({ type: Object })
  promoData!: Promo;
  @property({ type: Array })
  features: Feature[] = [];
  @property({ type: Number })
  employeesCount = 1;
  @property({ type: String })
  specialTerms: OrganizationViewModelSpecialTermsEnum | undefined = undefined;
  @property({ type: String })
  selectedFeature = '';
  @property({ type: Array })
  healthcareSignatoryOptions: SelectDropdownOption[] = [];
  @property({ type: String })
  healthcareSignatory: string | undefined = undefined;
  protected get hideCloser() {
    return true;
  }
  renderBody() {
    return html`<d-promo-section
      dialog
      .employeesCount=${this.employeesCount}
      .specialTerms=${this.specialTerms}
      .promoData=${this.promoData}
      .features=${this.features}
      .healthcareSignatoryOptions=${this.healthcareSignatoryOptions}
      .healthcareSignatory=${this.healthcareSignatory}
      @add-features=${() => this.onUpgrade()}
      @cancel=${(e) => this.onClose(e)}
    ></d-promo-section>`;
  }

  protected renderFooter() {
    return html``;
  }

  protected onUpgrade() {
    this.onDispatchAction(false, 'upgrade');
  }

  protected fetchResult(detail: 'cancel' | 'upgrade'): PromoDialogResult {
    return {
      action: detail,
    };
  }

  protected initializeDialog(input: PromoDialogInput) {
    this.promoData = input.promoData;
    this.features = input.features;
    this.employeesCount = input.employeesCount;
    this.specialTerms = input.specialTerms;
    this.healthcareSignatoryOptions = input.healthcareSignatoryOptions;
    this.healthcareSignatory = input.healthcareSignatory;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-promo-dialog': DPromoDialog;
  }
}
