import { applications, purposeAndLegalBasisDefaults } from 'src/pages/computers-page/infosec-procedure/defaults.js';
import type { OrganizationState, State } from 'src/store/types.js';
import {
  CreateOrUpdateComputerRequest,
  CreateOrUpdatePartnerRequest,
  CreateOrUpdatePersonalDataItemRequest,
  PersonalDataItemUpdateMessage,
  PersonalDataItemUpdateMessageBackupOperatorTypeEnum,
  PersonalDataItemViewModel,
} from 'src/store/api';
import { ApiClient, createAsset, createPartner } from 'src/store';
import { uuid } from 'src/utilities/text.js';
import { mappedCloudServices, mappedRemoteServers } from 'src/models/pages/computers-page-view.js';
import type { DataItemRemoteServerChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-remote-server.js';
import type { DataItemLocationChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-location.js';
import type { DataItemComputerChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-computer.js';
import type { DataItemAssetChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-asset.js';
import type {
  DataItemApplicationChange,
  DataItemApplicationSupplierChange,
} from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-application.js';
import type { DataItemEmailAccountChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-items-email-account.js';
import type {
  DataItemAccountingChange,
  DataItemExistingApplicationAccountingChange,
  DataItemExistingComputerAccountingChange,
  DataItemExistingPartnerAccountingChange,
  DataItemNewComputerAccountingChange,
} from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-accounting.js';
import { computersNotDeleted, partnersNotDeleted } from 'src/store/selectors';
import { assertIsDefined } from 'src/lib';

const defaultNewPersonalDataItem: PersonalDataItemUpdateMessage = {
  establishedDate: '',
  name: '',
  type: '',
  storageMedium: 'digital',
  storageUnit: '',
  storageLocation: '',
  backupOperatorType: PersonalDataItemUpdateMessageBackupOperatorTypeEnum.Undefined,
  backupOperator: '',
  journalSupplier: '',
  journalSize: '',
  sharedMedicalRecords: false,
  sharedMedicalRecordsPartners: [],
  sharedMedicalRecordsContracts: [],
  treatments: [],
  purpose: '',
  legalBasis: '',
  consentContracts: [],
  journalHasElectronicMessaging: false,
  messagingType: '',
  messagingTypeOther: '',
  messagingSupplier: '',
  riskAssessments: [],
  otherRiskAssessments: '',
  riskAssessmentConcludesOk: false,
  patientDialogueRequirements: [],
  notes: '',
  application: '',
  isConfirmedEntity: true,
};

async function patientDataItemChanged(
  api: ApiClient,
  o: OrganizationState,
  e: CustomEvent,
  state: State,
  showNotification: (s: string) => void,
  organizationId: string,
) {
  switch (e.detail.category) {
    case 'medicalRecordsSystems':
      await applicationItemChanged(api, o, e, state, 'medicalRecordsSystems', showNotification);
      return;
    case 'patientDialogSystems':
      await applicationItemChanged(api, o, e, state, 'patientDialogSystems', showNotification);
      return;
    case 'assetData':
      await patientDataAssetDataItemChanged(api, o, e, organizationId);
      return;
    case 'localDocuments':
      await localDocumentsItemChanged(api, o, e, organizationId);
      return;
    case 'remoteDocuments':
      await remoteDocumentsItemChanged(api, o, e, state, showNotification, organizationId);
      return;
    case 'paperDocuments':
      await paperDocumentsItemChanged(api, o, e, organizationId);
      return;
    default:
      alert(e.detail.category);
  }
}

async function managementDataItemChanged(
  api: ApiClient,
  o: OrganizationState,
  e: CustomEvent,
  state: State,
  showNotification: (s: string) => void,
  organizationId: string,
) {
  switch (e.detail.category) {
    case 'managementSystems':
      await applicationItemChanged(api, o, e, state, 'managementSystems', showNotification);
      return;
    case 'localDocuments':
      await localDocumentsItemChanged(api, o, e, organizationId);
      return;
    case 'remoteDocuments':
      await remoteDocumentsItemChanged(api, o, e, state, showNotification, organizationId);
      return;
    case 'paperDocuments':
      await paperDocumentsItemChanged(api, o, e, organizationId);
      return;
    case 'accounting':
      await accountingItemChanged(api, o, e, state, showNotification, organizationId);
      return;
    default:
      alert(e.detail.category);
  }
}

async function publicInformationAndCommunicationItemChanged(
  api: ApiClient,
  o: OrganizationState,
  e: CustomEvent,
  state: State,
  showNotification: (s: string) => void,
  organizationId: string,
) {
  switch (e.detail.category) {
    case 'emailServices':
      await applicationItemChanged(api, o, e, state, 'emailServices', showNotification);
      return;
    case 'webServices':
      await applicationItemChanged(api, o, e, state, 'webServices', showNotification);
      return;
    case 'socialMedia':
      await applicationItemChanged(api, o, e, state, 'socialMedia', showNotification);
      return;
    case 'emailAccounts':
      await emailAccountsItemChanged(api, o, e, organizationId);
      return;
    default:
      alert(e.detail.category);
  }
}

function defaultUpdateMessage(element: PersonalDataItemViewModel) {
  return {
    establishedDate: element.establishedDate,
    name: element.name,
    type: element.type,
    storageMedium: element.storageMedium,
    storageUnit: element.storageUnit,
    storageLocation: element.storageLocation,
    backupOperatorType: element.backupOperatorType as PersonalDataItemUpdateMessageBackupOperatorTypeEnum,
    backupOperator: element.backupOperator,
    journalSupplier: element.journalSupplier,
    journalSize: element.journalSize,
    sharedMedicalRecords: element.sharedMedicalRecords,
    sharedMedicalRecordsPartners: element.sharedMedicalRecordsPartners,
    sharedMedicalRecordsContracts: element.sharedMedicalRecordsContracts,
    treatments: element.treatments,
    purpose: element.purpose,
    legalBasis: element.legalBasis,
    consentContracts: element.consentContracts,
    journalHasElectronicMessaging: element.journalHasElectronicMessaging,
    messagingType: element.messagingType,
    messagingTypeOther: element.messagingTypeOther,
    messagingSupplier: element.messagingSupplier,
    riskAssessments: element.riskAssessments,
    otherRiskAssessments: element.otherRiskAssessments,
    riskAssessmentConcludesOk: element.riskAssessmentConcludesOk,
    patientDialogueRequirements: element.patientDialogueRequirements,
    notes: element.notes,
    application: element.application,
  };
}

async function applicationCreated(
  api: ApiClient,
  e: CustomEvent<DataItemApplicationChange>,
  state: State,
  systems: string,
  showNotification: (s: string) => void,
  organizationId: string,
) {
  switch (e.detail.storageUnitType) {
    case 'cloudServices':
      return await applicationCloudServiceCreated(api, e.detail, state, systems, showNotification, organizationId);
    case 'remoteServers':
      return await applicationRemoteServerCreated(api, e.detail, state, showNotification, organizationId);
    case 'computers':
      return await applicationComputerCreated(api, e.detail, organizationId);
  }
}

async function applicationComputerCreated(api: ApiClient, detail: DataItemApplicationChange, organizationId: string) {
  if (detail.storageUnitType !== 'computers') {
    throw new Error('Illegal state (E01), wrong storage unit type ' + detail.storageUnitType);
  }
  let storageUnit: string;
  if (!detail.newComputer) {
    storageUnit = detail.computerUuid;
  } else {
    storageUnit = uuid();
    const createComputerRequest: CreateOrUpdateComputerRequest = {
      organizationId: organizationId,
      computerId: storageUnit,
      computerUpdateMessage: {
        location: '',
        name: detail.newComputerName,
        type: detail.newComputerType,
        networkUuid: undefined,
        unitType: 'computer',
        serviceProvider: '',
        connectionType: 'OTHER',
        serviceProviderContract: undefined,
        systemUpdateOperatorType: 'UNDEFINED',
        systemUpdateOperator: undefined,
        antiVirusOperatorType: 'UNDEFINED',
        antiVirusOperator: undefined,
        antiVirusRequirements: [],
        locked: false,
        restrictedPhysicalAccess: false,
        displayPositionSecure: false,
        elevated: false,
        mobileMedicalDataRequirements: [],
        notes: undefined,
        isConfirmedEntity: true,
      },
    };
    await api.computers.createOrUpdateComputer(createComputerRequest);
  }

  const p: CreateOrUpdatePersonalDataItemRequest = {
    organizationId: organizationId,
    personalDataItemsId: detail.uuid ?? uuid(),
    personalDataItemUpdateMessage: {
      ...defaultNewPersonalDataItem,
      type: detail.dataType + '.' + detail.category,
      storageUnit: storageUnit,
      application: detail.application,
      personalData: false,
      dataProcessor: '',
      purpose: purposeAndLegalBasisDefaults[detail.category]?.purpose ?? '',
      legalBasis: purposeAndLegalBasisDefaults[detail.category]?.legalBasis ?? '',
    },
  };
  await api.computers.createOrUpdatePersonalDataItem(p);
}

async function applicationRemoteServerCreated(
  api: ApiClient,
  detail: DataItemApplicationChange,
  state: State,
  showNotification: (s: string) => void,
  organizationId: string,
) {
  if (detail.storageUnitType !== 'remoteServers') {
    throw new Error('Illegal state (E02), wrong storage unit type ' + detail.storageUnitType);
  }
  let partnerUuid = '';
  if (detail.newSupplier) {
    partnerUuid = uuid();
    await createPartner(api, partnerUuid, detail.newSupplierName, organizationId);
    showNotification((detail.newSupplierName ?? '') + ' ble lagt til som samarbeidspartner');
  } else {
    partnerUuid = detail.supplierUuid;
  }

  const remoteServer = mappedRemoteServers(computersNotDeleted(state)).find((c) => c.partnerUuid === partnerUuid);
  const storageUnit = remoteServer?.uuid ?? uuid();
  if (remoteServer === undefined) {
    const p: CreateOrUpdateComputerRequest = {
      organizationId: organizationId,
      computerId: storageUnit,
      computerUpdateMessage: {
        location: '',
        name: '',
        type: 'UNDEFINED',
        networkUuid: undefined,
        unitType: 'remoteServer',
        serviceProvider: partnerUuid,
        connectionType: 'OTHER',
        serviceProviderContract: '',
        systemUpdateOperatorType: 'UNDEFINED',
        systemUpdateOperator: undefined,
        antiVirusOperatorType: 'UNDEFINED',
        antiVirusOperator: undefined,
        antiVirusRequirements: [],
        locked: false,
        restrictedPhysicalAccess: false,
        displayPositionSecure: false,
        elevated: false,
        mobileMedicalDataRequirements: [],
        notes: undefined,
        isConfirmedEntity: true,
      },
    };
    await api.computers.createOrUpdateComputer(p);
  }

  const p: CreateOrUpdatePersonalDataItemRequest = {
    organizationId: organizationId,
    personalDataItemsId: detail.uuid ?? uuid(),
    personalDataItemUpdateMessage: {
      ...defaultNewPersonalDataItem,
      type: detail.dataType + '.' + detail.category,
      storageUnit: storageUnit,
      application: detail.application,
      personalData: false,
      dataProcessor: '',
      purpose: purposeAndLegalBasisDefaults[detail.category]?.purpose ?? '',
      legalBasis: purposeAndLegalBasisDefaults[detail.category]?.legalBasis ?? '',
    },
  };
  await api.computers.createOrUpdatePersonalDataItem(p);
}

type CreateApplicationCloudServiceType = { uuid?: string; application: string; dataType: string; category: string } & {
  storageUnitType: 'cloudServices' | 'remoteServers';
} & DataItemApplicationSupplierChange;

async function createApplicationCloudService(
  api: ApiClient,
  systems: string,
  detail: DataItemApplicationChange,
  state: State,
  showNotification: (s: string) => void,
  organizationId: string,
) {
  if (detail.storageUnitType !== 'cloudServices') {
    throw new Error('Illegal state (E03), wrong storage unit type ' + detail.storageUnitType);
  }
  const type = applications[systems].find((t) => t.value === detail.application);
  let partnerUuid = '';
  if (type === undefined) {
    if (detail.newSupplier) {
      partnerUuid = uuid();
      await createPartner(api, partnerUuid, detail.newSupplierName, organizationId);
      showNotification((detail.newSupplierName ?? '') + ' ble lagt til som samarbeidspartner');
    } else {
      partnerUuid = detail.supplierUuid;
    }
  } else {
    const partner = partnersNotDeleted(state).find((p) => p.name === type.provider);
    if (partner === undefined) {
      partnerUuid = uuid();
      const p: CreateOrUpdatePartnerRequest = {
        organizationId: organizationId,
        partnerId: partnerUuid,
        partnerUpdateMessage: {
          name: type.provider,
          remoteAccess: true,
          physicalAccess: false,
          pages: [65],
          isConfirmedEntity: true,
        },
      };

      await api.partners.createOrUpdatePartner(p);

      showNotification(type.provider + ' ble lagt til som samarbeidspartner');
    } else {
      partnerUuid = partner.uuid;
    }
  }

  const cloudService = mappedCloudServices(computersNotDeleted(state)).find((c) => c.partnerUuid === partnerUuid);
  const storageUnit = cloudService?.uuid ?? uuid();
  if (cloudService === undefined) {
    const p: CreateOrUpdateComputerRequest = {
      organizationId: organizationId,
      computerId: storageUnit,
      computerUpdateMessage: {
        location: '',
        name: '',
        type: 'UNDEFINED',
        networkUuid: undefined,
        unitType: 'cloudService',
        serviceProvider: partnerUuid,
        connectionType: 'OTHER',
        serviceProviderContract: '',
        systemUpdateOperatorType: 'UNDEFINED',
        systemUpdateOperator: undefined,
        antiVirusOperatorType: 'UNDEFINED',
        antiVirusOperator: undefined,
        antiVirusRequirements: [],
        locked: false,
        restrictedPhysicalAccess: false,
        displayPositionSecure: false,
        elevated: false,
        mobileMedicalDataRequirements: [],
        notes: undefined,
        isConfirmedEntity: true,
      },
    };
    await api.computers.createOrUpdateComputer(p);
  }

  const p: CreateOrUpdatePersonalDataItemRequest = {
    organizationId: organizationId,
    personalDataItemsId: detail.uuid ?? uuid(),
    personalDataItemUpdateMessage: {
      ...defaultNewPersonalDataItem,
      type: detail.dataType + '.' + detail.category,
      storageUnit: storageUnit,
      application: detail.application,
      personalData: false,
      dataProcessor: '',
      purpose: purposeAndLegalBasisDefaults[detail.category]?.purpose ?? '',
      legalBasis: purposeAndLegalBasisDefaults[detail.category]?.legalBasis ?? '',
    },
  };
  await api.computers.createOrUpdatePersonalDataItem(p);
}

async function applicationCloudServiceCreated(
  api: ApiClient,
  detail: DataItemApplicationChange,
  state: State,
  systems: string,
  showNotification: (s: string) => void,
  organizationId: string,
) {
  if (detail.storageUnitType !== 'cloudServices') {
    throw new Error('Illegal state (E04), wrong storage unit type ' + detail.storageUnitType);
  }
  await createApplicationCloudService(api, systems, detail, state, showNotification, organizationId);
}

async function assetDataCreated(api: ApiClient, e: CustomEvent<DataItemAssetChange>, organizationId: string) {
  if (e.detail.newAsset) {
    await createAsset(
      api,
      e.detail.newAssetUuid ?? '',
      e.detail.newAssetName ?? '',
      e.detail.newAssetNumber ?? '',
      organizationId,
    );
  }

  const p: CreateOrUpdatePersonalDataItemRequest = {
    organizationId: organizationId,
    personalDataItemsId: e.detail.uuid ?? uuid(),
    personalDataItemUpdateMessage: {
      ...defaultNewPersonalDataItem,
      type: e.detail.dataType + '.' + e.detail.category,
      relatedAssets: e.detail.assets,
      purpose: purposeAndLegalBasisDefaults[e.detail.category]?.purpose ?? '',
      legalBasis: purposeAndLegalBasisDefaults[e.detail.category]?.legalBasis ?? '',
    },
  };
  await api.computers.createOrUpdatePersonalDataItem(p);
}

async function assetDataUpdated(
  api: ApiClient,
  e: CustomEvent<DataItemAssetChange>,
  element: PersonalDataItemViewModel,
  organizationId: string,
) {
  if (e.detail.newAsset) {
    await createAsset(
      api,
      e.detail.newAssetUuid ?? '',
      e.detail.newAssetName ?? '',
      e.detail.newAssetNumber ?? '',
      organizationId,
    );
  }

  if (e.detail.newComputer) {
    assertIsDefined(e.detail.computerUuid);

    const p: CreateOrUpdateComputerRequest = {
      organizationId: organizationId,
      computerId: e.detail.computerUuid,
      computerUpdateMessage: {
        location: '',
        name: e.detail.newComputerName ?? '',
        type: e.detail.newComputerType ?? 'UNDEFINED',
        networkUuid: undefined,
        unitType: 'computer',
        serviceProvider: '',
        connectionType: 'OTHER',
        serviceProviderContract: '',
        systemUpdateOperatorType: 'UNDEFINED',
        systemUpdateOperator: undefined,
        antiVirusOperatorType: 'UNDEFINED',
        antiVirusOperator: undefined,
        antiVirusRequirements: [],
        locked: false,
        restrictedPhysicalAccess: false,
        displayPositionSecure: false,
        elevated: false,
        mobileMedicalDataRequirements: [],
        notes: undefined,
        isConfirmedEntity: true,
      },
    };
    console.log('d1', e.detail);
    await api.computers.createOrUpdateComputer(p);
    console.log('d2', e.detail);
  }

  const p: CreateOrUpdatePersonalDataItemRequest = {
    organizationId: organizationId,
    personalDataItemsId: e.detail.uuid ?? uuid(),
    personalDataItemUpdateMessage: {
      ...defaultUpdateMessage(element),
      relatedAssets: e.detail.assets,
      name: e.detail.name,
      storageUnit: e.detail.computerUuid,
      isConfirmedEntity: true,
    },
  };

  console.log('e1', e.detail);
  await api.computers.createOrUpdatePersonalDataItem(p);
  console.log('e2', e.detail);
}

async function localDocumentsCreated(api: ApiClient, e: CustomEvent<DataItemComputerChange>, organizationId: string) {
  if (e.detail.newComputer) {
    const p: CreateOrUpdateComputerRequest = {
      organizationId: organizationId,
      computerId: e.detail.computerUuid ?? uuid(),
      computerUpdateMessage: {
        location: '',
        name: e.detail.newComputerName ?? '',
        type: e.detail.newComputerType ?? 'UNDEFINED',
        networkUuid: undefined,
        unitType: 'computer',
        serviceProvider: '',
        connectionType: 'OTHER',
        serviceProviderContract: '',
        systemUpdateOperatorType: 'UNDEFINED',
        systemUpdateOperator: undefined,
        antiVirusOperatorType: 'UNDEFINED',
        antiVirusOperator: undefined,
        antiVirusRequirements: [],
        locked: false,
        restrictedPhysicalAccess: false,
        displayPositionSecure: false,
        elevated: false,
        mobileMedicalDataRequirements: [],
        notes: undefined,
        isConfirmedEntity: true,
      },
    };
    await api.computers.createOrUpdateComputer(p);
  }

  const p: CreateOrUpdatePersonalDataItemRequest = {
    organizationId: organizationId,
    personalDataItemsId: e.detail.uuid ?? uuid(),
    personalDataItemUpdateMessage: {
      ...defaultNewPersonalDataItem,
      type: e.detail.dataType + '.' + e.detail.category,
      storageUnit: e.detail.computerUuid,
      purpose: purposeAndLegalBasisDefaults[e.detail.category]?.purpose ?? '',
      legalBasis: purposeAndLegalBasisDefaults[e.detail.category]?.legalBasis ?? '',
    },
  };
  await api.computers.createOrUpdatePersonalDataItem(p);
}

function createApplicationCloudserviveTypeMessage(
  detail: DataItemExistingApplicationAccountingChange,
): CreateApplicationCloudServiceType {
  if (detail.application.startsWith('CUSTOM-')) {
    return {
      storageUnitType: 'cloudServices',
      dataType: detail.dataType,
      category: detail.category,
      application: detail.application,
      newSupplier: true,
      supplierUuid: '',
      newSupplierName: detail.application.substring(7),
    };
  } else {
    return {
      storageUnitType: 'cloudServices',
      dataType: detail.dataType,
      category: detail.category,
      application: detail.application,
      newSupplier: false,
      supplierUuid: '',
    };
  }
}

async function cloudServiceAccountingCreated(
  api: ApiClient,
  detail: DataItemExistingApplicationAccountingChange,
  state: State,
  showNotification: (s: string) => void,
  organizationId: string,
) {
  const m = createApplicationCloudserviveTypeMessage(detail);
  await createApplicationCloudService(api, 'accountingServices', m, state, showNotification, organizationId);
}

async function computerAccountingCreated(
  api: ApiClient,
  detail: DataItemExistingComputerAccountingChange | DataItemNewComputerAccountingChange,
  organizationId: string,
) {
  if (detail.newComputer) {
    const p: CreateOrUpdateComputerRequest = {
      organizationId: organizationId,
      computerId: detail.computerUuid ?? uuid(),
      computerUpdateMessage: {
        location: '',
        name: detail.newComputerName ?? '',
        type: detail.newComputerType ?? 'UNDEFINED',
        networkUuid: undefined,
        unitType: 'computer',
        serviceProvider: '',
        connectionType: 'OTHER',
        serviceProviderContract: '',
        systemUpdateOperatorType: 'UNDEFINED',
        systemUpdateOperator: undefined,
        antiVirusOperatorType: 'UNDEFINED',
        antiVirusOperator: undefined,
        antiVirusRequirements: [],
        locked: false,
        restrictedPhysicalAccess: false,
        displayPositionSecure: false,
        elevated: false,
        mobileMedicalDataRequirements: [],
        notes: undefined,
        isConfirmedEntity: true,
      },
    };
    await api.computers.createOrUpdateComputer(p);
  }
  const p: CreateOrUpdatePersonalDataItemRequest = {
    organizationId: organizationId,
    personalDataItemsId: uuid(),
    personalDataItemUpdateMessage: {
      ...defaultNewPersonalDataItem,
      type: detail.dataType + '.' + detail.category,
      storageUnit: detail.computerUuid,
      purpose: purposeAndLegalBasisDefaults[detail.category]?.purpose ?? '',
      legalBasis: purposeAndLegalBasisDefaults[detail.category]?.legalBasis ?? '',
    },
  };
  await api.computers.createOrUpdatePersonalDataItem(p);
}

async function partnerAccountingCreated(
  api: ApiClient,
  detail: DataItemExistingPartnerAccountingChange,
  organizationId: string,
) {
  const p: CreateOrUpdatePersonalDataItemRequest = {
    organizationId: organizationId,
    personalDataItemsId: uuid(),
    personalDataItemUpdateMessage: {
      ...defaultNewPersonalDataItem,
      type: detail.dataType + '.' + detail.category,
      dataProcessor: detail.dataProcessor,
      purpose: purposeAndLegalBasisDefaults[detail.category]?.purpose ?? '',
      legalBasis: purposeAndLegalBasisDefaults[detail.category]?.legalBasis ?? '',
    },
  };
  await api.computers.createOrUpdatePersonalDataItem(p);
}

async function accountingCreated(
  api: ApiClient,
  e: CustomEvent<DataItemAccountingChange>,
  state: State,
  showNotification: (s: string) => void,
  organizationId: string,
) {
  switch (e.detail.type) {
    case 'cloudServices':
      await cloudServiceAccountingCreated(api, e.detail, state, showNotification, organizationId);
      break;
    case 'computers':
      await computerAccountingCreated(api, e.detail, organizationId);
      break;
    case 'partners':
      await partnerAccountingCreated(api, e.detail, organizationId);
      break;
  }
}

async function remoteDocumentsCreated(
  api: ApiClient,
  e: CustomEvent<DataItemRemoteServerChange>,
  state: State,
  showNotification: (s: string) => void,
  organizationId: string,
) {
  if (e.detail.newSupplier) {
    await createPartner(api, e.detail.supplierUuid, e.detail.newSupplierName ?? '', organizationId);
    showNotification((e.detail.newSupplierName ?? '') + ' ble lagt til som samarbeidspartner');
  }

  const remoteServers = computersNotDeleted(state).filter((c) => c.unitType === 'remoteServer');
  const remoteServer = remoteServers.find((r) => r.serviceProvider === e.detail.supplierUuid);
  const storageUnit = remoteServer?.uuid ?? uuid();
  if (remoteServer === undefined) {
    const p: CreateOrUpdateComputerRequest = {
      organizationId: organizationId,
      computerId: storageUnit,
      computerUpdateMessage: {
        location: '',
        name: '',
        type: 'UNDEFINED',
        networkUuid: undefined,
        unitType: 'remoteServer',
        serviceProvider: e.detail.supplierUuid,
        connectionType: 'OTHER',
        serviceProviderContract: '',
        systemUpdateOperatorType: 'UNDEFINED',
        systemUpdateOperator: undefined,
        antiVirusOperatorType: 'UNDEFINED',
        antiVirusOperator: undefined,
        antiVirusRequirements: [],
        locked: false,
        restrictedPhysicalAccess: false,
        displayPositionSecure: false,
        elevated: false,
        mobileMedicalDataRequirements: [],
        notes: undefined,
        isConfirmedEntity: true,
      },
    };
    await api.computers.createOrUpdateComputer(p);
  }

  const p: CreateOrUpdatePersonalDataItemRequest = {
    organizationId: organizationId,
    personalDataItemsId: e.detail.uuid ?? uuid(),
    personalDataItemUpdateMessage: {
      ...defaultNewPersonalDataItem,
      type: e.detail.dataType + '.' + e.detail.category,
      storageUnit: storageUnit,
      purpose: purposeAndLegalBasisDefaults[e.detail.category]?.purpose ?? '',
      legalBasis: purposeAndLegalBasisDefaults[e.detail.category]?.legalBasis ?? '',
    },
  };
  await api.computers.createOrUpdatePersonalDataItem(p);
}

async function paperDocumentsCreated(api: ApiClient, e: CustomEvent<DataItemLocationChange>, organizationId: string) {
  const p: CreateOrUpdatePersonalDataItemRequest = {
    organizationId: organizationId,
    personalDataItemsId: e.detail.uuid ?? uuid(),
    personalDataItemUpdateMessage: {
      ...defaultNewPersonalDataItem,
      type: e.detail.dataType + '.' + e.detail.category,
      storageMedium: 'physical',
      storageUnit: '',
      storageLocation: e.detail.newLocationName,
      purpose: purposeAndLegalBasisDefaults[e.detail.category]?.purpose ?? '',
      legalBasis: purposeAndLegalBasisDefaults[e.detail.category]?.legalBasis ?? '',
    },
  };
  await api.computers.createOrUpdatePersonalDataItem(p);
}

async function emailAccountsCreated(
  api: ApiClient,
  e: CustomEvent<DataItemEmailAccountChange>,
  organizationId: string,
) {
  const p: CreateOrUpdatePersonalDataItemRequest = {
    organizationId: organizationId,
    personalDataItemsId: e.detail.uuid ?? uuid(),
    personalDataItemUpdateMessage: {
      ...defaultNewPersonalDataItem,
      type: e.detail.dataType + '.' + e.detail.category,
      storageUnit: '',
      name: e.detail.email,
      purpose: purposeAndLegalBasisDefaults[e.detail.category]?.purpose ?? '',
      legalBasis: purposeAndLegalBasisDefaults[e.detail.category]?.legalBasis ?? '',
    },
  };
  await api.computers.createOrUpdatePersonalDataItem(p);
}

function throwUnexpectedChange(e: CustomEvent) {
  throw new Error('Illegal state (E22), unexpected change ' + JSON.stringify(e.detail, null, 2));
}

async function applicationItemChanged(
  api: ApiClient,
  o: OrganizationState,
  e: CustomEvent<DataItemApplicationChange>,
  state: State,
  system: string,
  showNotification: (s: string) => void,
) {
  const element = e.detail.uuid !== undefined ? o.personalDataItemsById[e.detail.uuid] : undefined;
  if (element !== undefined) {
    throwUnexpectedChange(e);
  } else {
    await applicationCreated(api, e, state, system, showNotification, o.organizationId.toString());
  }
}

async function patientDataAssetDataItemChanged(
  api: ApiClient,
  o: OrganizationState,
  e: CustomEvent,
  organizationId: string,
) {
  const element = o.personalDataItemsById[e.detail.uuid];
  if (element !== undefined) {
    await assetDataUpdated(api, e, element, organizationId);
  } else {
    await assetDataCreated(api, e, organizationId);
  }
}

async function localDocumentsItemChanged(api: ApiClient, o: OrganizationState, e: CustomEvent, organizationId: string) {
  const element = o.personalDataItemsById[e.detail.uuid];
  if (element !== undefined) {
    throwUnexpectedChange(e);
  } else {
    await localDocumentsCreated(api, e, organizationId);
  }
}

async function accountingItemChanged(
  api: ApiClient,
  o: OrganizationState,
  e: CustomEvent,
  state: State,
  showNotification: (s: string) => void,
  organizationId: string,
) {
  const element = o.personalDataItemsById[e.detail.uuid];
  if (element !== undefined) {
    throwUnexpectedChange(e);
  } else {
    await accountingCreated(api, e, state, showNotification, organizationId);
  }
}

async function remoteDocumentsItemChanged(
  api: ApiClient,
  o: OrganizationState,
  e: CustomEvent,
  state: State,
  showNotification: (s: string) => void,
  organizationId: string,
) {
  const element = o.personalDataItemsById[e.detail.uuid];
  if (element !== undefined) {
    throwUnexpectedChange(e);
  } else {
    await remoteDocumentsCreated(api, e, state, showNotification, organizationId);
  }
}

async function paperDocumentsItemChanged(api: ApiClient, o: OrganizationState, e: CustomEvent, organizationId: string) {
  const element = o.personalDataItemsById[e.detail.uuid];
  if (element !== undefined) {
    throwUnexpectedChange(e);
  } else {
    await paperDocumentsCreated(api, e, organizationId);
  }
}

async function emailAccountsItemChanged(api: ApiClient, o: OrganizationState, e: CustomEvent, organizationId: string) {
  const element = o.personalDataItemsById[e.detail.uuid];
  if (element !== undefined) {
    throwUnexpectedChange(e);
  } else {
    await emailAccountsCreated(api, e, organizationId);
  }
}

export async function dataItemChanged(
  api: ApiClient,
  o: OrganizationState,
  e: CustomEvent,
  state: State,
  showNotification: (s: string) => void,
  organizationId: string,
) {
  switch (e.detail.dataType) {
    case 'patientData':
      await patientDataItemChanged(api, o, e, state, showNotification, organizationId);
      return;
    case 'managementData':
      await managementDataItemChanged(api, o, e, state, showNotification, organizationId);
      return;
    case 'publicInformationAndCommunication':
      await publicInformationAndCommunicationItemChanged(api, o, e, state, showNotification, organizationId);
      return;
    default:
      throw new Error('Illegal state (E05), unexpected datatype ' + e.detail.dataType);
  }
}
