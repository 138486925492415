import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { DataMapElement, DataMapItem } from './data-map-element.js';
import {
  applications,
  backupFrequencyOptions,
  dataTypes,
  InfosecDataItem,
  InfosecStorageUnit,
  InfosecStorageUnitTypes,
  messagingTypes,
  noBackupReasonOptions,
  purposeAndLegalBasisDefaults,
} from 'src/pages/computers-page/infosec-procedure/defaults.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import 'src/content/entity-content';
import '../../library/editors/index.js';
import '../../library/editors/elements/d-select-checkbox.js';
import '../../library/fields/index.js';
import '../../library/fields/d-expansion.js';
import '../../library/fields/d-view-html.js';
import '../../library/fields/d-view-text.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../pages/computers-page/d-edit-single-related-item.js';
import './d-edit-related-items.js';
import './d-view-checklist.js';
import './d-view-checkpoint.js';
import './d-view-related-items.js';
import '../computers-page/infosec-procedure/editors/d-edit-data-item-application.js';
import '../computers-page/infosec-procedure/editors/d-edit-data-item-asset.js';
import './infosec-procedure/editors/d-edit-data-item-accounting.js';
import '../computers-page/infosec-procedure/editors/d-edit-data-item-computer.js';
import '../computers-page/infosec-procedure/editors/d-edit-data-item-remote-server.js';
import '../computers-page/infosec-procedure/editors/d-edit-data-item-location.js';
import '../computers-page/infosec-procedure/editors/d-edit-backup.js';
import { isEmailValid } from 'src/store';
import { namesListFromUuids, uuid } from 'src/utilities/text.js';
import { commonFieldsByCode } from 'src/store/fields.js';
import type { SelectCheckboxOption } from 'src/library/editors/elements/d-select-checkbox.js';
import type { RelatedItem } from 'src/pages/computers-page/d-view-related-items.js';
import type { AddDataItemApplication } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-application.js';
import { sortedByNumberAndName } from 'src/store/utilities.js';
import { NewNameDialog, NewNameResult } from 'src/library/editors/components/new-name-dialog';

export interface DataItem extends DataMapItem {
  uuid: string;
  name: string;
  unitType: 'personalDataItem';

  dataType: string;
  category: string;
  personalData: boolean;
  dataProcessor: string;
  application: string;
  accessingEmployees: string[];
  accessingPartners: string[];
  relatedAssets: string[];
  storageUnitType: InfosecStorageUnitTypes;
  storageUnit: string;
  storageLocation: string;
  backupFrequency: string;
  backupResponsible: string;
  noBackupReason: string;
  purpose: string;
  legalBasis: string;
  consentContracts: string[];
  riskAssessmentConcludesOk: boolean;
  notes: string;

  sharedMedicalRecords: boolean;
  sharedMedicalRecordsPartners: string[];
  sharedMedicalRecordsContracts: string;

  journalHasElectronicMessaging: boolean;
  messagingType: string;
  messagingTypeOther: string;
  messagingSupplier: string;
  messageMonitoring: boolean;
  autoLookupPatients: boolean;
  autoLookupAddress: boolean;
  autoLookupAddressNhn: boolean;
  autoUpdateAddressNhn: boolean;

  patientDialogueRequirements: string[];

  availableCloudServices: InfosecStorageUnit[];
  availableRemoteServers: InfosecStorageUnit[];
  availableComputers: InfosecStorageUnit[];
  availableLocations: InfosecStorageUnit[];
  availableAssets: SelectDropdownOption[];
  availableSuppliers: SelectDropdownOption[];
  availableEmployees: SelectDropdownOption[];
  availableContracts: SelectDropdownOption[];
}

export interface DataEditItem {
  uuid: string;
  name: string;
  dataType: string;
  category: string;
  personalData: boolean;
  dataProcessor: string;
  application: string;
  accessingEmployees: string[];
  accessingPartners: string[];
  relatedAssets: string[];
  storageUnitType: InfosecStorageUnitTypes;
  storageUnit: string;
  storageLocation: string;
  backupFrequency: string;
  backupResponsible: string;
  noBackupReason: string;
  purpose: string;
  legalBasis: string;
  consentContracts: string[];
  riskAssessmentConcludesOk: boolean;
  notes: string;

  sharedMedicalRecords: boolean;
  sharedMedicalRecordsPartners: string[];
  sharedMedicalRecordsContracts: string;

  journalHasElectronicMessaging: boolean;
  messagingType: string;
  messagingTypeOther: string;
  messagingSupplier: string;
  messageMonitoring: boolean;
  autoLookupPatients: boolean;
  autoLookupAddress: boolean;
  autoLookupAddressNhn: boolean;
  autoUpdateAddressNhn: boolean;

  patientDialogueRequirements: string[];
}

/**
 *
 */
@customElement('d-data-item-view')
export class DDataItemView extends DataMapElement<DataItem, DataEditItem> {
  static readonly styles = [
    ...DataMapElement.styles,
    css`
      .mapElement {
        border-radius: 4px;
      }
    `,
  ];
  @property({ type: String })
  storageUnitType = '';
  @property({ type: Boolean })
  simplifiedPrivacy = false;

  @property({ type: String })
  tooltipName = 'undefined';
  @property({ type: String })
  tooltipTreatments = 'undefined';
  @property({ type: String })
  organizationName = '';
  @property({ type: Array })
  computers: SelectDropdownOption[] = [];
  @property({ type: Array })
  partners: SelectDropdownOption[] = [];
  @property({ type: Array })
  assets: { uuid: string; name: string; number: string }[] = [];
  /**
   * The available remote servers
   */
  @property({ type: Array })
  remoteServers: InfosecStorageUnit[] = [];

  /**
   * The available cloud services
   */
  @property({ type: Array })
  cloudServices: InfosecStorageUnit[] = [];
  @state()
  private pendingAddDataItem: AddDataItemApplication | undefined;

  private get newEditItem(): DataEditItem {
    return {
      uuid: '',
      name: this.item.name,
      dataType: this.item.dataType,
      category: this.item.category,
      personalData: this.item.personalData,
      dataProcessor: this.item.dataProcessor,
      application: this.item.application,
      accessingEmployees: this.item.accessingEmployees,
      accessingPartners: this.item.accessingPartners,
      relatedAssets: this.item.relatedAssets,
      storageUnitType: this.item.storageUnitType,
      storageUnit: this.item.storageUnit,
      storageLocation: this.item.storageLocation,
      backupFrequency: this.item.backupFrequency,
      backupResponsible: this.item.backupResponsible,
      noBackupReason: this.item.noBackupReason,
      purpose: this.item.purpose,
      legalBasis: this.item.legalBasis,
      consentContracts: this.item.consentContracts,
      riskAssessmentConcludesOk: this.item.riskAssessmentConcludesOk,
      notes: this.item.notes,

      sharedMedicalRecords: this.item.sharedMedicalRecords,
      sharedMedicalRecordsPartners: this.item.sharedMedicalRecordsPartners,
      sharedMedicalRecordsContracts: this.item.sharedMedicalRecordsContracts,

      journalHasElectronicMessaging: this.item.journalHasElectronicMessaging,
      messagingType: this.item.messagingType,
      messagingTypeOther: this.item.messagingTypeOther,
      messagingSupplier: this.item.messagingSupplier,
      messageMonitoring: this.item.messageMonitoring,
      autoLookupPatients: this.item.autoLookupPatients,
      autoLookupAddress: this.item.autoLookupAddress,
      autoLookupAddressNhn: this.item.autoLookupAddressNhn,
      autoUpdateAddressNhn: this.item.autoUpdateAddressNhn,

      patientDialogueRequirements: this.item.patientDialogueRequirements,
    };
  }

  private get dataTypeOptions() {
    return dataTypes.map((type) => {
      return {
        value: type.type,
        text: this._capitalize(type.name),
      };
    });
  }

  private get itemTypeIsDefined() {
    return this._typeIsDefined(this.item);
  }

  private get editItemTypeIsDefined() {
    return this._typeIsDefined(this.editItem) || this.pendingAddDataItem !== undefined;
  }

  private get backupFrequencyText() {
    const f = backupFrequencyOptions.filter((item) => {
      return item.value === this.item.backupFrequency;
    });
    if (f.length) {
      return f[0].text;
    }
    return '';
  }

  private get backupResponsibleText() {
    if (this.item.backupResponsible === 'internal') {
      return this.organizationName;
    }
    return namesListFromUuids(this.item.availableSuppliers, [this.item.backupResponsible]);
  }

  private get noBackupReasonText() {
    const f = noBackupReasonOptions.filter((item) => {
      return item.value === this.item.noBackupReason;
    });
    if (f.length) {
      return f[0].text;
    }
    return '';
  }

  private get electronicMessagingRequirementsOptions() {
    const fields = [
      'messageMonitoring',
      'autoLookupPatients',
      'autoLookupAddress',
      'autoLookupAddressNhn',
      'autoUpdateAddressNhn',
    ];
    return fields.map((field) => {
      return {
        value: field,
        text: commonFieldsByCode()['personalDataItems_' + field].label,
      };
    });
  }

  private get checkedElectronicMessagingOptions() {
    const fields = [
      'messageMonitoring',
      'autoLookupPatients',
      'autoLookupAddress',
      'autoLookupAddressNhn',
      'autoUpdateAddressNhn',
    ];
    return fields
      .filter((field) => {
        return this.item[field];
      })
      .map((field) => {
        return field;
      });
  }

  private get patientDialogueRequirementsOptions(): SelectCheckboxOption[] {
    return [
      {
        value: 'securityLevel4',
        text: commonFieldsByCode()['personalDataItems_securityLevel4'].label,
      },
    ];
  }

  private get messagingTypeText() {
    if (this.item.messagingTypeOther) {
      return this.item.messagingTypeOther;
    }
    const t = messagingTypes.filter((type) => {
      return type.value === this.item.messagingType;
    });
    if (t.length) {
      return t[0].text;
    }
  }

  private get messagingSupplierText() {
    const suppliers = this.item.availableSuppliers.filter((partner) => {
      return partner.value === this.item.messagingSupplier;
    });
    if (suppliers.length) {
      return suppliers[0].text;
    }
    return '';
  }

  private get defaultAccessingPartnerUuid() {
    if (this.item.dataProcessor) {
      return this.item.dataProcessor;
    }
    const storageUnits = this.item.availableCloudServices
      .concat(this.item.availableRemoteServers, this.item.availableComputers)
      .filter((unit) => {
        return unit.uuid === this.item.storageUnit;
      });
    if (storageUnits.length) {
      return storageUnits[0].partnerUuid;
    }
    return undefined;
  }

  private get accessingPartnersOptions() {
    return this.item.availableSuppliers.map((partner) => {
      const disabled = this.defaultAccessingPartnerUuid === partner.value;
      return {
        value: partner.value,
        text: partner.text,
        disabled: disabled,
      };
    });
  }

  _accessingPartnersValueIn(accessingPartners: string[]): string[] {
    if (this.defaultAccessingPartnerUuid && !accessingPartners.includes(this.defaultAccessingPartnerUuid)) {
      return [...accessingPartners, this.defaultAccessingPartnerUuid];
    }
    return accessingPartners;
  }

  _accessingPartnersValueOut(accessingPartners: string[]): string[] {
    return accessingPartners.filter((item) => {
      return item !== this.defaultAccessingPartnerUuid;
    });
  }

  _relatedItems(relatedItems: string[], items: SelectDropdownOption[]): RelatedItem[] {
    const result = relatedItems.map((uuid) => {
      const matchingItems = items.filter((item) => {
        return item.value === uuid;
      });
      if (matchingItems.length) {
        return {
          name: matchingItems[0].text,
          href: '#',
        };
      }
      return {
        name: 'ukjent',
        href: '#',
      };
    });
    if (result) {
      console.log('_relatedItems result', result);
      return result;
    }
    return [];
  }

  _relatedItem(relatedItem: string, items: SelectDropdownOption[]): RelatedItem[] {
    const matchingItems = items.filter((item) => {
      return item.value === relatedItem;
    });
    if (matchingItems.length) {
      const result = [
        {
          name: matchingItems[0].text,
          href: '',
        },
      ];
      console.log('_relatedItem result', result);
      return result;
    }
    return [];
  }

  _typeIsDefined(item) {
    if (!item) {
      return false;
    }
    if (item.category !== '') {
      if (this._isApplication(this._selectedCategory(item.dataType, item.category)) && item.storageUnitType !== '') {
        return true;
      }
      if (
        item.category === 'assetData' &&
        item.relatedAssets.filter((asset) => {
          return asset !== '';
        }).length > 0
      ) {
        return true;
      }
      if (
        item.category === 'accounting' &&
        (item.application !== '' || item.dataProcessor !== '' || item.storageUnit !== '')
      ) {
        return true;
      }
      if ((item.category === 'localDocuments' || item.category === 'remoteDocuments') && item.storageUnit !== '') {
        return true;
      }
      if (item.category === 'paperDocuments') {
        return true;
      }
      if (item.category === 'emailAccounts' && isEmailValid(item.name)) {
        return true;
      }
    }
    return false;
  }

  initializeEditItem(): DataEditItem {
    return this.newEditItem;
  }

  _dataCategoryOptions(dataType) {
    const selectedDataTypes = dataTypes.filter((type) => {
      return type.type === dataType;
    });
    if (selectedDataTypes.length > 0) {
      return selectedDataTypes[0].categories.map((category) => {
        return {
          value: category.category,
          text: this._capitalize(category.labelSingular || category.label),
        };
      });
    }
    return [];
  }

  _capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  _isValid() {
    return this.editItemTypeIsDefined;
  }

  _displayTypeName() {
    let dataType = this.item.dataType;
    let category = this.item.category;
    if (this.editItem) {
      dataType = this.editItem.dataType;
      category = this.editItem.category;
    }
    let type = '';
    if (dataType === 'patientData') {
      type = 'pasientdata';
      if (category === 'medicalRecordsSystems') {
        type = 'pasientjournal';
      }
      if (category === 'patientDialogSystems') {
        type = 'pasientdialogsystem';
      }
      if (category === 'assetData') {
        type = 'utstyrsdata';
      }
    }
    if (dataType === 'managementData') {
      type = 'virksomhetsdata';
      if (category === 'accounting') {
        type = 'regnskapsdata';
      }
    }
    if (category === 'paperDocuments') {
      type += ' på papir';
    }
    if (category === 'emailAccounts') {
      type = 'upersonlig epostkonto';
    }
    if (category === 'emailServices') {
      type = 'eposttjeneste';
    }
    if (category === 'webServices') {
      type = 'webtjeneste';
    }
    if (category === 'socialMedia') {
      type = 'sosialt medium';
    }
    return this._capitalize(type) || 'Data';
  }

  _displayName() {
    let category = this.item.category;
    let application = this.item.application;
    let name = this.item.name;
    let dataProcessor = this.item.dataProcessor;
    let relatedAssets = this.item.relatedAssets;
    let storageLocation = this.item.storageLocation;
    if (this.editItem) {
      category = this.editItem.category;
      application = this.editItem.application;
      name = this.editItem.name;
      dataProcessor = this.editItem.dataProcessor;
      relatedAssets = this.editItem.relatedAssets;
      storageLocation = this.editItem.storageLocation;
    }
    let applicationName = '';
    if (application && applications[category]) {
      const defaultApplications = applications[category].filter((item) => {
        return item.value === application;
      });
      if (defaultApplications.length) {
        applicationName = defaultApplications[0].text;
      } else if (application.includes('CUSTOM-')) {
        applicationName = application.replace('CUSTOM-', '');
      } else {
        applicationName = application;
      }
    }
    let partnerName = '';
    if (category === 'accounting' && dataProcessor) {
      const partners = this.item.availableSuppliers.filter((item) => {
        return item.value === dataProcessor;
      });
      if (partners.length) {
        partnerName = partners[0].text;
      }
    }
    // asset names
    let assetNames = '';
    if (category === 'assetData') {
      const a = this.assets.filter((item) => {
        return relatedAssets.includes(item.uuid);
      });
      if (a.length) {
        sortedByNumberAndName(a).forEach((item) => {
          assetNames += item.name + ' ' + item.number + ', ';
        });
        assetNames = assetNames.slice(0, -2);
      }
    }
    let locationName = '';
    if (category === 'paperDocuments') {
      locationName = storageLocation;
    }
    return name || applicationName || partnerName || assetNames || locationName;
  }

  _getName(type, uuid) {
    for (const item1 of type) {
      if (item1.uuid === uuid) {
        return item1.name;
      }
    }
  }

  _showBackup() {
    return this.editItem?.category !== 'paperDocuments' && this.editItem?.category !== 'emailAccounts';
  }

  _selectedDataType(type) {
    const types = dataTypes.filter((t) => {
      return t.type === type;
    });
    if (types.length) {
      return types[0];
    }
    return null;
  }

  _selectedCategory(type, category) {
    const dataType = this._selectedDataType(type);
    if (dataType?.categories) {
      const categories = dataType.categories.filter((c) => {
        return c.category === category;
      });
      if (categories.length) {
        return categories[0];
      }
    }
    return undefined;
  }

  _isApplication(category) {
    if (category) {
      if (category.category === 'accounting') {
        return false;
      }
      if (category.applicationOptions) {
        return true;
      }
    }
    return false;
  }

  _isPersonalData(editItem) {
    return editItem.dataType === 'patientData' || editItem.personalData;
  }
  _remoteServersChanged(detail) {
    this.item.availableRemoteServers = detail;
  }

  _computersChanged(detail) {
    this.item.availableComputers = detail;
  }

  _locationsChanged(detail) {
    this.item.availableLocations = detail;
  }

  _partnersChanged(detail) {
    this.item.availableSuppliers = detail;
  }

  _dataItemChanged(dataItem: DataEditItem) {
    this.item = {
      ...this.item,
      uuid: this.item.uuid || uuid(),
    };
    if (this.editItem) {
      this.editItem = {
        ...this.editItem,
        name: dataItem.name,
        dataType: dataItem.dataType,
        category: dataItem.category,
        personalData: dataItem.personalData,
        dataProcessor: dataItem.dataProcessor,
        application: dataItem.application,
        storageUnit: dataItem.storageUnit,
        storageUnitType: dataItem.storageUnitType,
        storageLocation: dataItem.storageLocation,
        accessingEmployees: dataItem.accessingEmployees,
        accessingPartners: dataItem.accessingPartners,
        backupFrequency: dataItem.backupFrequency,
        backupResponsible: dataItem.backupResponsible,
        noBackupReason: dataItem.noBackupReason,
      };
    }
    this.dispatchEvent(
      new CustomEvent('data-item-changed', {
        composed: true,
        bubbles: true,
        detail: { ...dataItem, uuid: this.item.uuid },
      }),
    );
  }

  renderUnitView() {
    return html`
      <section>
        <d-section map-element borderless vertical label="Tilgang til informasjonen">
          <d-view-text
            label="Personale"
            inline-label
            .value=${namesListFromUuids(this.item.availableEmployees, this.item.accessingEmployees) || 'Ingen'}
          ></d-view-text>
          ${this.item.category !== 'paperDocuments'
            ? html`<d-view-text
                label="Samarbeidspartnere"
                inline-label
                .value=${namesListFromUuids(
                  this.item.availableSuppliers,
                  this._accessingPartnersValueIn(this.item.accessingPartners),
                ) || 'Ingen'}
              ></d-view-text>`
            : nothing}
        </d-section>
        ${this.item.category === 'assetData'
          ? html`
              <d-section map-element borderless vertical field="personalDataItems_relatedAssets">
                <d-view-related-items .items=${this._relatedItems(this.item.relatedAssets, this.item.availableAssets)}>
                </d-view-related-items>
              </d-section>
            `
          : nothing}
        ${this.item.category !== 'paperDocuments'
          ? html`
              <d-section map-element borderless vertical label="Sikkerhetskopiering">
                <d-view-text
                  inline-label
                  label="Hvor ofte"
                  .value=${this.backupFrequencyText || 'Ikke oppgitt'}
                ></d-view-text>
                ${this.item.backupFrequency !== '' && this.item.backupFrequency !== 'never'
                  ? html`
                      <d-view-text
                        inline-label
                        label="Av hvem"
                        .value=${this.backupResponsibleText || 'Ikke oppgitt'}
                      ></d-view-text>
                    `
                  : nothing}
                ${this.item.backupFrequency === 'never'
                  ? html`
                      <d-view-text
                        inline-label
                        label="Hvorfor ikke"
                        .value=${this.noBackupReasonText || 'Ikke oppgitt'}
                      ></d-view-text>
                    `
                  : nothing}
              </d-section>
            `
          : nothing}
        ${this.item.category === 'patientDialogSystems'
          ? html`
              <d-section map-element borderless label="Pasientdialogsystem">
                <d-view-checklist
                  .checked=${this.item.patientDialogueRequirements}
                  entity="personalDataItems"
                  field="personalDataItems_patientDialogueRequirements"
                  .options=${this.patientDialogueRequirementsOptions}
                >
                </d-view-checklist>
              </d-section>
            `
          : nothing}
        ${this.item.category === 'paperDocuments'
          ? html`
              <d-section>
                <d-view-text
                  field="personalDataItems_storageLocation"
                  value="${this.item.storageLocation}"
                ></d-view-text>
              </d-section>
            `
          : nothing}
        ${this.item.journalHasElectronicMessaging
          ? html`
              <d-section map-element borderless vertical field="personalDataItems_messaging">
                <d-view-text
                  inline-label
                  field="personalDataItems_messagingType"
                  .value=${this.messagingTypeText || 'Ikke opgitt'}
                >
                </d-view-text>
                <d-view-text
                  inline-label
                  field="personalDataItems_messagingSupplier"
                  .value=${this.messagingSupplierText || 'Ikke oppgitt'}
                >
                </d-view-text>
                <d-view-checklist
                  wrap-label
                  label="Krav til meldingskommunikasjon"
                  .checked=${this.checkedElectronicMessagingOptions}
                  entity="personalDataItems"
                  .options=${this.electronicMessagingRequirementsOptions}
                ></d-view-checklist>
              </d-section>
            `
          : nothing}
        <d-section map-element borderless vertical label="Personopplysninger">
          ${this._isPersonalData(this.item)
            ? html`
                <d-view-text
                  inline-label
                  field="personalDataItems_purpose"
                  .value=${this.item.purpose || 'Ikke oppgitt'}
                >
                </d-view-text>
                <d-view-text
                  inline-label
                  field="personalDataItems_legalBasis"
                  .value=${this.item.legalBasis || 'Ikke oppgitt'}
                >
                </d-view-text>
                ${this.item.consentContracts.length > 0
                  ? html`
                      <d-view-related-items
                        field="personalDataItems_consentContracts"
                        .items="${this._relatedItems(this.item.consentContracts, this.item.availableContracts)}"
                      >
                      </d-view-related-items>
                    `
                  : nothing}
                <d-view-checkpoint
                  ?checked="${this.item.riskAssessmentConcludesOk}"
                  field="personalDataItems_riskAssessmentConcludesOk"
                >
                </d-view-checkpoint>
              `
            : html` <d-view-text inline-label .value=${'Inneholder ikke personopplysninger'}></d-view-text> `}
        </d-section>
        ${this.item.category === 'medicalRecordsSystems' && this.item.sharedMedicalRecords
          ? html`
              <d-section map-element borderless vertical label="Felles pasientjournal">
                <d-view-text
                  inline-label
                  field="personalDataItems_sharedMedicalRecordsPartners"
                  .value=${namesListFromUuids(this.item.availableSuppliers, this.item.sharedMedicalRecordsPartners) ||
                  'Ikke oppgitt'}
                >
                </d-view-text>
                <d-view-related-items
                  field="personalDataItems_sharedMedicalRecordsContracts"
                  .items=${this._relatedItem(this.item.sharedMedicalRecordsContracts, this.item.availableContracts)}
                >
                </d-view-related-items>
              </d-section>
            `
          : nothing}
        ${this.item.notes
          ? html`
              <d-section map-element borderless vertical field="personalDataItems_notes">
                <d-view-html map-element value="${this.item.notes}"></d-view-html>
              </d-section>
            `
          : nothing}
      </section>
    `;
  }

  renderUnitEditType(editItem: DataEditItem, dataItem: InfosecDataItem) {
    return html`
      ${editItem.dataType === '' || editItem.category === ''
        ? this.renderDataTypeAndCategorySelector(editItem)
        : this.renderDataItemHeader(editItem, dataItem)}
    `;
  }

  renderUnitEditAccess(editItem: DataEditItem) {
    return html`
      <d-section map-element label="Tilgang til informasjonen">
        <d-select-checkbox
          theme-page
          label="Personale"
          .options=${this.item.availableEmployees}
          .value=${editItem.accessingEmployees}
          @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              accessingEmployees: e.detail.value,
            };
          }}
        ></d-select-checkbox>
        ${this.item.category !== 'paperDocuments'
          ? html`<d-select-checkbox
              theme-page
              label="Samarbeidspartnere"
              .options=${this.accessingPartnersOptions}
              .value=${this._accessingPartnersValueIn(editItem.accessingPartners)}
              @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
                e.stopPropagation();
                this.editItem = {
                  ...editItem,
                  accessingPartners: this._accessingPartnersValueOut(e.detail.value),
                };
              }}
            ></d-select-checkbox>`
          : nothing}
      </d-section>
    `;
  }

  renderUnitEditBackup(editItem: DataEditItem, dataItem: InfosecDataItem) {
    return html`
      <d-expansion ?opened="${this._showBackup()}">
        <d-section map-element label="Sikkerhetskopiering">
          <d-edit-backup
            .dataItem=${dataItem}
            .partners=${this.item.availableSuppliers}
            .organizationName=${this.organizationName}
            @backup-change=${(e) => {
              e.stopPropagation();
            }}
            @data-item-changed=${(e) => {
              e.stopPropagation();
              this.editItem = {
                ...editItem,
                backupFrequency: e.detail.backupFrequency,
                backupResponsible: e.detail.backupResponsible,
                noBackupReason: e.detail.noBackupReason,
              };
            }}
          ></d-edit-backup>
        </d-section>
      </d-expansion>
    `;
  }

  renderUnitEditPatientDialog(editItem: DataEditItem) {
    return html`
      <d-expansion ?opened="${editItem.category === 'patientDialogSystems'}">
        <d-section map-element label="Pasientdialogsystem">
          <d-select-checkbox
            theme-page
            deselectable
            field="personalDataItems_patientDialogueRequirements"
            option-direction="vertical"
            .options="${this.patientDialogueRequirementsOptions}"
            type="check"
            .value="${editItem.patientDialogueRequirements}"
            @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
              e.stopPropagation();
              this.editItem = {
                ...editItem,
                patientDialogueRequirements: e.detail.value,
              };
            }}
          >
          </d-select-checkbox>
        </d-section>
      </d-expansion>
    `;
  }

  renderUnitEditElectronicMessaging(editItem: DataEditItem) {
    if (editItem.category === 'medicalRecordsSystems') {
      return html`
        <d-section map-element field="personalDataItems_messaging">
          <d-checkbox
            theme-page
            ?checked="${editItem.journalHasElectronicMessaging}"
            option-field="personalDataItems_journalHasElectronicMessaging"
            @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
              e.stopPropagation();
              this.editItem = {
                ...editItem,
                journalHasElectronicMessaging: e.detail.checked,
              };
            }}
          >
          </d-checkbox>
          <d-expansion in-section ?opened="${editItem.journalHasElectronicMessaging}">
            <d-select-dropdown
              theme-page
              field="personalDataItems_messagingType"
              .options="${messagingTypes}"
              placeholder="Velg"
              value="${editItem.messagingType}"
              @value-changed=${(e: CustomEvent<{ value: string }>) => {
                e.stopPropagation();
                this.editItem = {
                  ...editItem,
                  messagingType: e.detail.value,
                };
              }}
            >
            </d-select-dropdown>
            ${editItem.messagingType === 'OTHER'
              ? html`
                  <d-edit-text
                    theme-page
                    style="margin-top: 8px"
                    value="${editItem.messagingTypeOther}"
                    @value-changed=${(e: CustomEvent<{ value: string }>) => {
                      e.stopPropagation();
                      this.editItem = {
                        ...editItem,
                        messagingTypeOther: e.detail.value,
                      };
                    }}
                  >
                  </d-edit-text>
                `
              : nothing}
            <d-edit-single-related-item
              field="personalDataItems_messagingSupplier"
              .items=${this.item.availableSuppliers}
              @new-item=${this.onNewPartner}
              placeholder="Velg samarbeidspartner"
              .relatedItem=${editItem.messagingSupplier}
              @related-item-changed=${(e: CustomEvent<{ relatedItem: string }>) => {
                e.stopPropagation();
                this.editItem = {
                  ...editItem,
                  messagingSupplier: e.detail.relatedItem,
                };
              }}
            >
            </d-edit-single-related-item>
            <d-checkbox
              wrap-label
              theme-page
              ?checked="${editItem.messageMonitoring}"
              option-field="personalDataItems_messageMonitoring"
              @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                e.stopPropagation();
                this.editItem = {
                  ...editItem,
                  messageMonitoring: e.detail.checked,
                };
              }}
            >
            </d-checkbox>
            <d-checkbox
              wrap-label
              theme-page
              ?checked="${editItem.autoLookupPatients}"
              option-field="personalDataItems_autoLookupPatients"
              @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                e.stopPropagation();
                this.editItem = {
                  ...editItem,
                  autoLookupPatients: e.detail.checked,
                };
              }}
            >
            </d-checkbox>
            <d-checkbox
              wrap-label
              theme-page
              ?checked="${editItem.autoLookupAddress}"
              option-field="personalDataItems_autoLookupAddress"
              @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                e.stopPropagation();
                this.editItem = {
                  ...editItem,
                  autoLookupAddress: e.detail.checked,
                };
              }}
            >
            </d-checkbox>
            ${this.simplifiedPrivacy
              ? nothing
              : html`
                  <d-checkbox
                    ?checked="${editItem.autoLookupAddressNhn}"
                    wrap-label
                    theme-page
                    option-field="personalDataItems_autoLookupAddressNhn"
                    @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                      e.stopPropagation();
                      this.editItem = {
                        ...editItem,
                        autoLookupAddressNhn: e.detail.checked,
                      };
                    }}
                  ></d-checkbox>
                `}
            ${this.simplifiedPrivacy
              ? nothing
              : html` <d-checkbox
                  theme-page
                  wrap-label
                  ?checked="${editItem.autoUpdateAddressNhn}"
                  option-field="personalDataItems_autoUpdateAddressNhn"
                  @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                    e.stopPropagation();
                    this.editItem = {
                      ...editItem,
                      autoUpdateAddressNhn: e.detail.checked,
                    };
                  }}
                ></d-checkbox>`}
          </d-expansion>
        </d-section>
      `;
    }
    return nothing;
  }

  renderUnitEditPersonalData(editItem: DataEditItem) {
    return html`
      <d-section map-element vertical label="Personopplysninger">
        ${editItem.dataType !== 'patientData'
          ? html`
              <d-checkbox
                theme-page
                option-label="Inneholder personopplysninger"
                .checked=${editItem.personalData}
                @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                  e.stopPropagation();
                  this.editItem = {
                    ...editItem,
                    personalData: e.detail.checked,
                  };
                }}
              >
              </d-checkbox>
            `
          : nothing}
        <d-expansion in-section ?opened="${this._isPersonalData(editItem)}">
          <d-edit-textarea
            theme-page
            field="personalDataItems_purpose"
            value="${editItem.purpose}"
            @value-changed=${(e: CustomEvent<{ value: string }>) => {
              e.stopPropagation();
              this.editItem = {
                ...editItem,
                purpose: e.detail.value,
              };
            }}
          >
          </d-edit-textarea>
          <d-edit-textarea
            theme-page
            field="personalDataItems_legalBasis"
            value="${editItem.legalBasis}"
            @value-changed=${(e: CustomEvent<{ value: string }>) => {
              e.stopPropagation();
              this.editItem = {
                ...editItem,
                legalBasis: e.detail.value,
              };
            }}
          >
          </d-edit-textarea>
        </d-expansion>
        <d-expansion in-section ?opened="${this._isPersonalData(editItem) && editItem.dataType !== 'patientData'}">
          <div>åpen</div>
          <d-edit-related-items
            field="personalDataItems_consentContracts"
            .items=${this.item.availableContracts}
            @new-item=${this.onNewContract}
            placeholder="Velg avtale"
            .relatedItems=${editItem.consentContracts}
            @related-items-changed=${(e: CustomEvent<{ relatedItems: string[] }>) => {
              e.stopPropagation();
              this.editItem = {
                ...editItem,
                consentContracts: e.detail.relatedItems,
              };
            }}
          >
          </d-edit-related-items>
        </d-expansion>
        <d-expansion in-section ?opened="${this._isPersonalData(editItem)}">
          <d-checkbox
            theme-page
            label="Risikovurdering"
            ?checked="${editItem.riskAssessmentConcludesOk}"
            option-field="personalDataItems_riskAssessmentConcludesOk"
            @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
              e.stopPropagation();
              this.editItem = {
                ...editItem,
                riskAssessmentConcludesOk: e.detail.checked,
              };
            }}
          >
          </d-checkbox>
        </d-expansion>
      </d-section>
    `;
  }

  renderUnitEditSharedMedicalRecord(editItem: DataEditItem) {
    return html`
      <d-section map-element vertical label="Felles pasientjournal">
        <d-checkbox
          theme-page
          ?checked="${editItem.sharedMedicalRecords}"
          option-field="personalDataItems_sharedMedicalRecords"
          @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              sharedMedicalRecords: e.detail.checked,
            };
          }}
        >
        </d-checkbox>
        <d-expansion in-section ?opened="${editItem.sharedMedicalRecords}">
          <d-edit-related-items
            field="personalDataItems_sharedMedicalRecordsPartners"
            .items="${this.item.availableSuppliers}"
            @new-item=${this.onNewPartner}
            placeholder="Legg til samarbeidspartner"
            .relatedItems="${editItem.sharedMedicalRecordsPartners}"
            @related-items-changed=${(e: CustomEvent<{ relatedItems: string[] }>) => {
              e.stopPropagation();
              this.editItem = {
                ...editItem,
                sharedMedicalRecordsPartners: e.detail.relatedItems,
              };
            }}
          >
          </d-edit-related-items>
          <d-edit-single-related-item
            field="personalDataItems_sharedMedicalRecordsContracts"
            .items=${this.item.availableContracts}
            @new-item=${this.onNewContract}
            placeholder="Velg avtale"
            .relatedItem=${editItem.sharedMedicalRecordsContracts}
            @related-item-changed=${(e: CustomEvent<{ relatedItem: string }>) => {
              e.stopPropagation();
              this.editItem = {
                ...editItem,
                sharedMedicalRecordsContracts: e.detail.relatedItem,
              };
            }}
          >
          </d-edit-single-related-item>
        </d-expansion>
      </d-section>
    `;
  }

  renderUnitEditGeneralContent(editItem: DataEditItem) {
    return html`
      <d-edit-html
        theme-page
        map-element
        .contentStickyTop=${this.contentStickyTop}
        field="personalDataItems_notes"
        value="${editItem.notes}"
        @value-changed=${(e: CustomEvent<{ value: string }>) => {
          e.stopPropagation();
          this.editItem = {
            ...editItem,
            notes: e.detail.value,
          };
        }}
      >
      </d-edit-html>
    `;
  }

  renderUnitEdit(editItem: DataEditItem) {
    const dataItem: InfosecDataItem = {
      uuid: editItem.uuid,
      name: editItem.name,
      dataType: editItem.dataType,
      category: editItem.category,
      personalData: editItem.personalData,
      dataProcessor: editItem.dataProcessor,
      application: editItem.application,
      storageUnit: editItem.storageUnit,
      storageUnitType: editItem.storageUnitType,
      storageLocation: editItem.storageLocation,
      accessingEmployees: editItem.accessingEmployees,
      accessingPartners: editItem.accessingPartners,
      assets: editItem.relatedAssets,
      backupFrequency: editItem.backupFrequency,
      backupResponsible: editItem.backupResponsible,
      noBackupReason: editItem.noBackupReason,
      createdDateTime: '',
    };
    return html`
      ${this.itemTypeIsDefined ? nothing : html` ${this.renderUnitEditType(editItem, dataItem)} `}
      <d-expansion ?opened="${this.editItemTypeIsDefined}">
        ${editItem.category === 'paperDocuments' ? this.renderUnitEditType(editItem, dataItem) : nothing}
        ${this.renderUnitEditAssetData(editItem, dataItem)} ${this.renderUnitEditAccess(editItem)}
        ${this.renderUnitEditBackup(editItem, dataItem)} ${this.renderUnitEditPatientDialog(editItem)}
        ${this.renderUnitEditElectronicMessaging(editItem)} ${this.renderUnitEditPersonalData(editItem)}
        ${editItem.category === 'medicalRecordsSystems'
          ? html` ${this.renderUnitEditSharedMedicalRecord(editItem)} `
          : nothing}
        ${this.renderUnitEditGeneralContent(editItem)}
      </d-expansion>
    `;
  }

  protected getExtraInformation() {
    const pendingAddDataItem = this.pendingAddDataItem;
    this.pendingAddDataItem = undefined;
    return pendingAddDataItem;
  }

  private renderDataItemHeader(editItem: DataEditItem, dataItem: InfosecDataItem) {
    switch (editItem.category) {
      case 'accounting':
        return html` <d-section topless>
          <d-edit-data-item-accounting
            .dataType=${this._selectedDataType(editItem.dataType) || dataTypes[0]}
            .category=${this._selectedCategory(editItem.dataType, editItem.category) || dataTypes[0].categories[0]}
            .dataItems=${[dataItem]}
            .cloudServices=${this.item.availableCloudServices}
            .computers=${this.item.availableComputers}
            .partners=${this.item.availableSuppliers}
            @computers-changed=${(e) => {
              this._computersChanged(e.detail);
            }}
            @partners-changed=${(e) => {
              this._partnersChanged(e.detail);
            }}
            @data-item-changed=${(e) => {
              this._dataItemChanged(e.detail);
            }}
          ></d-edit-data-item-accounting>
        </d-section>`;
      case 'assetData':
        return html` <d-edit-data-item-asset
          .dataItem=${dataItem}
          @data-item-changed=${(e) => {
            this._dataItemChanged(e.detail);
          }}
        ></d-edit-data-item-asset>`;
      case 'localDocuments':
        return html` <d-section topless>
          <d-edit-data-item-computer
            .dataItem=${dataItem}
            .computers=${this.item.availableComputers}
            @computers-changed=${(e) => {
              this._computersChanged(e.detail);
            }}
            @data-item-changed=${(e) => {
              this._dataItemChanged(e.detail);
            }}
          ></d-edit-data-item-computer>
        </d-section>`;
      case 'remoteDocuments':
        return html` <d-section topless>
          <d-edit-data-item-remote-server
            label="Leverandør av fjernserver"
            .dataType=${this._selectedDataType(editItem.dataType) || dataTypes[0]}
            .category=${this._selectedCategory(editItem.dataType, editItem.category) || dataTypes[0].categories[0]}
            .remoteServers=${this.item.availableRemoteServers}
            .partners=${this.item.availableSuppliers}
            .dataItem=${dataItem}
            @remote-servers-changed=${(e) => {
              this._remoteServersChanged(e.detail);
            }}
            @partners-changed=${(e) => {
              this._partnersChanged(e.detail);
            }}
            @data-item-changed=${(e) => {
              this._dataItemChanged(e.detail);
            }}
          ></d-edit-data-item-remote-server>
        </d-section>`;
      case 'paperDocuments': {
        const options = this.item.availableLocations.map((location) => {
          return {
            value: location.uuid,
            text: location.name,
          };
        });
        if (options.find((x) => x.value === editItem.storageLocation) === undefined) {
          options.push({ value: editItem.storageLocation, text: editItem.storageLocation });
        }
        return html`
          <d-section topless>
            <d-select-dropdown-or-add
              theme-page
              label="Oppbevaringssted"
              placeholder="Velg oppbevaringssted"
              unselectablePlaceholder
              firstItemText="Registrer oppbevaringssted"
              additionalItemText="Annet oppbevaringssted"
              .options=${options}
              .value=${editItem.storageLocation}
              @value-changed=${async (e) => {
                e.stopPropagation();
                if (e.detail.value === 'NEW') {
                  const result: NewNameResult = await NewNameDialog.open({ title: 'Nytt oppbevaringsssted' });
                  if (result.action === 'done') {
                    this.editItem = {
                      ...editItem,
                      storageLocation: result.name,
                    };
                  }
                } else {
                  this.editItem = {
                    ...editItem,
                    storageLocation: e.detail.value,
                  };
                  // this._dataItemChanged(e.detail);
                }
              }}
            ></d-select-dropdown-or-add>
          </d-section>
        `;
      }
      case 'emailAccounts':
        return html` <d-section topless>
          <d-edit-text
            theme-page
            label="Epostadresse"
            .value=${editItem.name}
            @value-changed=${(e: CustomEvent<{ value: string }>) => {
              e.stopPropagation();
              this.editItem = {
                ...editItem,
                name: e.detail.value,
              };
            }}
          ></d-edit-text>
        </d-section>`;
      default:
        return this.renderApplicationHeader(editItem);
    }
  }
  private renderApplicationHeader(editItem: DataEditItem) {
    const selectedCategory = this._selectedCategory(editItem.dataType, editItem.category);
    if (selectedCategory === undefined) {
      throw new Error('Illegal state (E421), category not found ' + JSON.stringify(editItem));
    }
    if (this.pendingAddDataItem !== undefined) {
      return nothing;
    }

    return html` <d-section topless>
      <d-edit-data-item-application
        hide-application-label
        light-label
        small-label
        .computers=${this.computers}
        .partners=${this.partners}
        .applicationPlaceholder=${selectedCategory.applicationPlaceholder ?? ''}
        .applicationAddText=${selectedCategory.applicationAddText ?? ''}
        .applicationLabel=${selectedCategory.label ?? ''}
        .availableApplicationOptions=${selectedCategory.applicationOptions ?? []}
        @add-data-item=${(e) => {
          e.stopPropagation();
          this.pendingAddDataItem = e.detail;
        }}
        .dataType=${this._selectedDataType(editItem.dataType) || dataTypes[0]}
        .category=${selectedCategory}
        @data-item-changed=${(e) => {
          e.stopPropagation();
          // this._dataItemChanged(e.detail);
        }}
      ></d-edit-data-item-application>
    </d-section>`;
  }

  private renderDataTypeAndCategorySelector(editItem: DataEditItem) {
    return html` <d-section map-element label="Datatype">
        <d-select-radio
          theme-page
          .options=${this.dataTypeOptions}
          .value=${editItem.dataType}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...this.newEditItem,
              dataType: e.detail.value,
            };
          }}
        >
        </d-select-radio>
      </d-section>
      <d-expansion ?opened="${editItem.dataType !== ''}">
        <d-section topless>
          <d-select-dropdown
            theme-page
            placeholder="Velg type"
            unselectablePlaceholder
            .options=${this._dataCategoryOptions(editItem.dataType)}
            .value=${editItem.category}
            @value-changed=${(e: CustomEvent<{ value: string }>) => {
              e.stopPropagation();
              this.editItem = {
                ...this.newEditItem,
                dataType: this.editItem?.dataType ?? '',
                category: e.detail.value,
              };
              this.updateDefaultsForType(e.detail.value);
            }}
          >
          </d-select-dropdown>
        </d-section>
      </d-expansion>`;
  }

  private updateDefaultsForType(newType: string) {
    const defaultValues = purposeAndLegalBasisDefaults[newType];

    if (defaultValues && this.editItem) {
      this.editItem = {
        ...this.editItem,
        purpose: defaultValues.purpose,
        legalBasis: defaultValues.legalBasis,
      };
    }
  }

  private renderUnitEditAssetData(editItem: DataEditItem, dataItem: InfosecDataItem) {
    if (editItem.dataType === 'patientData' && editItem.category === 'assetData') {
      return html` <d-edit-data-item-asset
        .assets=${this.assets}
        .computers=${this.item.availableComputers}
        .dataItem=${dataItem}
        @data-item-updated=${(e) => {
          e.stopPropagation();
          this.editItem = {
            ...editItem,
            relatedAssets: e.detail.assets,
            name: e.detail.name,
            storageUnit: e.detail.storageUnit,
          };
        }}
        @data-item-changed=${(e) => {
          e.stopPropagation();
          console.error(JSON.stringify(e.detail));
        }}
      ></d-edit-data-item-asset>`;
    }
    return nothing;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-data-item-view': DDataItemView;
  }
}
