import { css, html, LitElement, nothing } from 'lit';
import type { PdfLoadErrorEventArgs, PdfViewerDocument } from './pdf-viewer-document.js';
import './plain-text.js';
import { customElement, eventOptions, property, query, state } from 'lit/decorators.js';
import '../../fields/d-spinner-robot.js';
import type { Direction } from 'src/layout/parts/file-viewer-dialog.js';
import { createUuid } from 'src/store';

const styles = css`
  :host {
    display: flex;
    position: relative;
    --paper-spinner-stroke-width: 12px;
  }
  pdf-viewer-document {
    flex: 1;
  }
  #actions {
    position: absolute;
    bottom: 0;
    right: 24px;
    width: min-content;
    height: min-content;
  }
  button {
    --iron-icon-height: 20px;
    --iron-icon-width: 20px;
    background-color: rgb(242, 242, 242);
    border-radius: 50%;
    color: var(--paper-grey-700);
    box-shadow:
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
    display: block;
    margin: calc(var(--pdf-page-margin, 12px) * 2);
    width: 36px;
    height: 36px;
  }
  paper-icon-button:hover {
    box-shadow:
      0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12),
      0 2px 4px -1px rgba(0, 0, 0, 0.4);
  }
  paper-spinner {
    width: 100px;
    height: 100px;
  }
  .center-overlay {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
  }
  h4 {
    color: var(--pdf-paper, #fff);
  }
  .fallback {
    padding: var(--pdf-page-margin, 12px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
`;

/** Render a PDF with basic UI.
 *  This makes assumptions about how the UI should look, use <pdf-viewer-document> directly for custom look and feel.
 *  UI elements can be overridden in 3 slots:
 *      actions: action buttons for zoom, fit and expand. Default is floating action buttons like Chrome's PDF viewer.
 *      error: message displayed when a document can't be loaded. Default displays the error message.
 *      loader: content displayed while a document is being retrieved. Default is a 100px paper-spinner. */
@customElement('pdf-viewer')
export class PdfViewer extends LitElement {
  /** Terms to highlight */
  @property()
  highlight?: string | RegExp | (string | RegExp)[];
  /** Text content to render if the PDF fails to load. */
  @property()
  fallback?: string;
  @property({ type: Boolean, attribute: 'no-animation' })
  noAnimation = false;
  @state()
  private fitMode: 'height' | 'width' | 'custom' = 'width';
  @state()
  private loaded?: boolean;
  @state()
  private loadError?: PdfLoadErrorEventArgs;
  @query('#d')
  private _pdfViewerDocumentEl?: PdfViewerDocument;

  private instanceId = createUuid();

  static get styles() {
    return [styles];
  }

  private _src = '';

  /** URL of the PDF file to display. */
  @property()
  get src(): string {
    return this._src;
  }

  set src(s: string) {
    console.log('Setting src ', s, 'OLD:', this._src, 'this', this.instanceId);
    if (this._src === s) {
      console.log('Sources are equal ', s);
      return;
    }

    const oldVal = this._src;
    this._src = s;
    this.loaded = undefined;
    this.loadError = undefined;
    this.requestUpdate('src', oldVal);
    console.log('Requested update ', s, oldVal);
  }

  /** Document control used to render the PDF */
  private get pdfDocument(): PdfViewerDocument {
    const d = this._pdfViewerDocumentEl;

    if (d === null) {
      throw new Error('Illegal state (E081), PdfViewerDocument is null');
    }

    return d as PdfViewerDocument;
  }

  render() {
    console.log('Rendering pdf ', this.src);
    return html` <pdf-viewer-document
        id="d"
        .src=${this.src}
        .highlight=${this.highlight}
        @pdf-document-loading=${() => this.pdfLoading()}
        @pdf-document-loaded=${() => this.pdfLoaded()}
        @pdf-document-error=${(e: CustomEvent<PdfLoadErrorEventArgs>) => this.pdfLoadError(e)}
      ></pdf-viewer-document>
      ${this.loaded ? nothing : this.loadError ? this.renderError(this.loadError) : this.renderSpinner()}`;
  }

  jumpToPage(direction: Direction) {
    this.pdfDocument?.jumpToPage(direction);
  }

  /** Open the document in a new tab/window using the browser's native render. */
  @eventOptions({ capture: false, passive: true })
  expandFull() {
    const target = `${this.src}#toolbar=1`;
    window.open(target, '_blank');
  }

  /** Toggle fit mode: width of one page or height of one page.
   *  Calling this will reset zoom to fit by height of one page. */
  @eventOptions({ capture: false, passive: true })
  toggleFit() {
    const fit = this.fitMode === 'height' ? 'width' : 'height';
    this.fitMode = fit;
    this.pdfDocument.updateFit(fit);
  }

  /** Zoom in 1 step */
  @eventOptions({ capture: false, passive: true })
  zoomin() {
    this.fitMode = 'custom';
    this.pdfDocument.zoomin();
  }

  /** Zoom out 1 step */
  @eventOptions({ capture: false, passive: true })
  zoomout() {
    this.fitMode = 'custom';
    this.pdfDocument.zoomout();
  }

  private renderActions(fitMode: 'height' | 'width' | 'custom') {
    const fitIcon = fitMode === 'width' ? 'fullscreen-exit' : 'fullscreen';

    return html` <slot name="actions">
      <div id="actions">
        <button @click=${() => this.expandFull()}>
          <svg x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
            <g id="launch">
              <path
                d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"
              ></path>
            </g>
          </svg>
        </button>
        <button icon=${fitIcon} id="actionFit" @click=${() => this.toggleFit()}>
          ${fitIcon === 'fullscreen-exit'
            ? html`
                <svg x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
                  <g id="fullscreen-exit">
                    <path d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z"></path>
                  </g>
                </svg>
              `
            : html`
                <svg x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
                  <g id="fullscreen">
                    <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"></path>
                  </g>
                </svg>
              `}
        </button>
        <button icon="zoom-in" id="actionZoomIn" @click=${() => this.zoomin()}>
          <svg x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
            <g id="zoom-in">
              <path
                d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zm2.5-4h-2v2H9v-2H7V9h2V7h1v2h2v1z"
              ></path>
            </g>
          </svg>
        </button>
        <button icon="zoom-out" id="actionZoomOut" @click=${() => this.zoomout()}>
          <svg x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
            <g id="zoom-out">
              <path
                d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zM7 9h5v1H7z"
              ></path>
            </g>
          </svg>
        </button>
      </div>
    </slot>`;
  }

  private renderError(error: PdfLoadErrorEventArgs) {
    console.error(error.message);
    return html` <div class="center-overlay">
      <slot name="error">
        <d-spinner-robot ?no-animation=${this.noAnimation}></d-spinner-robot>
        <div>
          <h2>${error.name || 'Venter'}</h2>
        </div>
      </slot>
    </div>`;
  }

  private renderSpinner() {
    return html`
      <slot name="loader">
        <d-spinner-robot ?no-animation=${this.noAnimation}></d-spinner-robot>
      </slot>
    `;
  }

  @eventOptions({ capture: false, passive: true })
  private pdfLoading() {
    console.log('pdf loading', this.pdfDocument);
    this.loadError = undefined;
    this.loaded = false;
  }

  @eventOptions({ capture: false, passive: true })
  private async pdfLoaded() {
    console.log('pdf loaded', this.pdfDocument);
    this.loaded = true;
    await this.updateComplete;
    console.log('pdf loaded updateComplete', this.pdfDocument);

    if (this.pdfDocument !== undefined) {
      await this.pdfDocument.updateFit('width');
    }
  }

  @eventOptions({ capture: false, passive: true })
  private pdfLoadError(e: CustomEvent<PdfLoadErrorEventArgs>) {
    console.log('pdf error', this.pdfDocument);
    this.loadError = e.detail;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    'pdf-viewer': PdfViewer;
  }
}
